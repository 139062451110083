import { Injectable } from '@angular/core';
import {
	CanActivate,
	UrlTree,
	Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../services';

@Injectable({
	providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
	constructor(
		private router: Router,
		private authService: AuthenticationService
	) { }

	canActivate():
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		let roleId = this.authService.currentUserValue()?.user.role.id;

		if (roleId <= 2) {
			this.router.parseUrl('/admin/dashboard/admins');
			return true;
		}

		this.router.parseUrl('/admin/dashboard');
		return true;
	}
}
