import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'auctionCurrency',
})
export class AuctionCurrencyPipe implements PipeTransform {
  transform(amount: number, currency: string): string {
    let currencyCode: string;
    let amountString: string;
    if (amount !== null || amount !== undefined) {
      currencyCode = currency ? currency.split('(')[1].split(')')[0].trim() : null;
      amountString = new CurrencyPipe('en-US').transform(amount, currencyCode, 'symbol', '1.2-2');
    } else {
      amountString = '';
    }
    return amountString;
  }
}
