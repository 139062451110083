import { Directive, Input, HostListener } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ImageViewerComponent } from '../component/image-viewer/image-viewer.component';

export interface imageModalObject {
  images: { id: number; name: string }[] | null,
  thumbnails: { id: number; name: string }[] | null,
  currentImage: { id: number; name: string } | null,
  heading: string | null
  lotData: { lotNumber: string, lotDesc: string } | null
}

@Directive({
  selector: '[imagePreview]'
})
export class ImagePreviewDirective {
  @Input() thumbnailImages: Array<{ id: number; name: string }> = null;
  @Input() actualImages: Array<{ id: number; name: string }> = null;
  @Input() currentImage: { id: number; name: string } = null;
  @Input() header: string = null;
  @Input() lotData: { lotNumber: string, lotDesc: string } = null;

  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  async openModalWithComponent() {
    let orientation = ""
    const getMeta = async (url) => {
      const img = new Image();
      img.src = url;
      await img.decode();  
      return img
    };
    await getMeta(this.currentImage.name).then(img => {
      console.dir(img.naturalHeight +' '+ img.naturalWidth);
      if (img.naturalWidth > img.naturalHeight) {
        orientation = "landscape"
      } else {
        orientation = "portrait"
      }
    });
    let config: ModalOptions<imageModalObject> = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: false,
      keyboard: true,
      class: `custom-popup lot-images-slider modal-dialog modal-dialog-centered ${orientation}`,
      initialState: {
        images: this.actualImages,
        thumbnails: this.thumbnailImages,
        currentImage: this.currentImage,
        heading: this.header,
        lotData: this.lotData
      }
    };
    this.bsModalRef = this.modalService.show(ImageViewerComponent, config);
  }

  @HostListener('click') onClick() {
    if (!this.actualImages || !this.thumbnailImages)
      return;
    this.openModalWithComponent();
  }
}
