import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../../utility/utils';
import { formatDate } from '@angular/common';

@Pipe({ name: 'toLocalDateTime' })
export class ToLocalDateTime implements PipeTransform {
  transform(
    value: string | Date,
    changeToLocal: boolean = true,
    timeZone: string = null,
    dateFormat: string = 'M/d/yyyy h:mm:ss a'
  ) {
    if (value) {
      if (value instanceof Date) {
        value = value.toString();
      }
      let date: string = value;
      let timeZoneString: string = timeZone != null ? ` (${timeZone})` : '';
      if (changeToLocal) {
        date = Utils.toLocalDateTime(value);
      }
      return formatDate(date, dateFormat, 'en-US') + timeZoneString;
    } else return 'NA';
  }
}
