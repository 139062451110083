import { AddAuctionStep3 } from './../models/auction-stepIII';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import {
  ApiBaseResponse,
  PageData,
  Auction,
  AuctionStep4,
  SaleType,
  CommonDropdown,
  GetAuctionStep2,
  GetAuctionStep1,
  RegisteredUsers,
  AuctionCurrentState,
  LotFromBlob,
} from '../models';
import { Observable, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { AddUpdateAuctionStep1 } from '../models/auctionStep1';
import { AuctionDetail } from '../models/auctionDetail';
import { CurrentAuctionList } from '../models/auctionList';
import { auctionLots } from '../models/auctionLots';
import { AdminAuctionList } from '../models/adminAuctionList';
@Injectable({
  providedIn: 'root',
})
export class AuctionService {
  constructor(private http: HttpClient) {}

  getAuctions(
    paging: AuctionPagination
  ): Observable<PageData<AdminAuctionList[]>> {
    let param = new HttpParams();
    if (paging.searchTerm != '')
      param = param.append('searchTerm', paging.searchTerm);

    if (paging.isPaginated) {
      param = param.append('pageSize', '' + paging.pageSize);
      param = param.append('pageNumber', '' + paging.pageNumber);
      param = param.append('isPaginated', '' + paging.isPaginated);
    }

    if (paging.auctionStatusId > 0) {
      param = param.append('auctionStatusId', '' + paging.auctionStatusId);
    }

    if (paging.saleTypeId > 0) {
      param = param.append('saleTypeId', '' + paging.saleTypeId);
    }

    if (paging.status > 0) {
      param = param.append('status', '' + paging.status);
    }

    return this.http
      .get<ApiBaseResponse<AdminAuctionList[]>>(
        `${environment.apiUrl}/auction/getAll`,
        {
          params: param,
        }
      )
      .pipe(
        map((it) => {
          if (paging.isPaginated) {
            return {
              data: it.data,
              totalCount: it.pagingInfo.totalCount,
            };
          }
          return {
            data: it.data,
            totalCount: null,
          };
        })
      );
  }

  getAuctionStatusType() {
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/auction/getAuctionStatus`
      )
      .pipe(map((it) => it.data));
  }

  auctionChangeStatus(model: any): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/ChangeStatus`,
        model
      )
      .pipe(map((it) => it.data));
  }
  auctionDelete(model: any): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/DeleteAuction`,
        model
      )
      .pipe(map((it) => it.data));
  }
  auctionApprove(model: any): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/Approve`,
        model
      )
      .pipe(map((it) => it.data));
  }
  auctionStopRegistration(model: any): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/StopRegistration`,
        model
      )
      .pipe(map((it) => it.data));
  }
  auctionStartRegistration(model: any): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/StartRegistration`,
        model
      )
      .pipe(map((it) => it.data));
  }
  markItFeatured(id): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/MarkItFeatured`,
        { id: id }
      )
      .pipe(map((it) => it.data));
  }
  stopSyncRegistrationWithOtherAuction(model: any): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/StopSyncRegistrationWithOtherAuction`,
        model
      )
      .pipe(map((it) => it.data));
  }
  syncRegistrationWithOtherAuction(model: any): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/SyncRegistrationWithOtherAuction`,
        model
      )
      .pipe(map((it) => it.data));
  }
  markAsSold(model: any): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/MarkAsSold`,
        model
      )
      .pipe(map((it) => it.data));
  }
  getSaleManagers(): Observable<CommonDropdown[]> {
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/auction/GetActiveSaleManagers`
      )
      .pipe(map((it) => it.data));
  }
  isTitleUniqueCheck(id: number, titleCheck: string) {
    return timer(700).pipe(
      switchMap(() => {
        return this.http.post<ApiBaseResponse<boolean>>(
          `${environment.apiUrl}/auction/IsTitleUnique`,
          {
            title: titleCheck,
            auctionId: id,
          }
        );
      })
    );
  }
  isAuctionCodeUniqueCheck(id: number, auctionCodeCheck: string) {
    return timer(700).pipe(
      switchMap(() => {
        return this.http.post<ApiBaseResponse<boolean>>(
          `${environment.apiUrl}/auction/IsAuctionCodeUnique`,
          {
            auctionCode: auctionCodeCheck,
            auctionId: id,
          }
        );
      })
    );
  }

  // step-1
  addAuctionStep1(model: AddUpdateAuctionStep1): Observable<Auction> {
    return this.http
      .post<ApiBaseResponse<Auction>>(
        `${environment.apiUrl}/auction/AddStepI`,
        model
      )
      .pipe(map((it) => it.data));
  }
  getAuctionStep1(auctionId: number): Observable<GetAuctionStep1> {
    return this.http
      .get<ApiBaseResponse<GetAuctionStep1>>(
        `${environment.apiUrl}/auction/GetResponseStepI/${auctionId}`
      )
      .pipe(map((it) => it.data));
  }

  // Step-2
  imageAddRemove(formData: FormData): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/UploadRemoveFile`,
        formData
      )
      .pipe(map((it) => it.data));
  }

  uploadCoverLogoImages(formData: FormData): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/UploadCoverLogoImages`,
        formData
      )
      .pipe(map((it) => it.data));
  }

  getAuctionStepIi(auctionId: number): Observable<GetAuctionStep2> {
    return this.http
      .get<ApiBaseResponse<GetAuctionStep2>>(
        `${environment.apiUrl}/auction/GetResponseStepIi/${auctionId}`
      )
      .pipe(map((it) => it.data));
  }

  // step-3
  addAuctionStep3(model: AddAuctionStep3): Observable<AddAuctionStep3> {
    return this.http
      .post<ApiBaseResponse<AddAuctionStep3>>(
        `${environment.apiUrl}/Auction/AddUpdateStepIii`,
        model
      )
      .pipe(map((it) => it.data));
  }
  getAuctionStep3(auctionId: number): Observable<AddAuctionStep3> {
    return this.http
      .get<ApiBaseResponse<AddAuctionStep3>>(
        `${environment.apiUrl}/auction/GetResponseStepIii/${auctionId}`
      )
      .pipe(map((it) => it.data));
  }
  getAuctionSaleType(auctionId: number): Observable<SaleType> {
    return this.http
      .get<ApiBaseResponse<SaleType>>(
        `${environment.apiUrl}/auction/GetAuctionSaleType/${auctionId}`
      )
      .pipe(map((it) => it.data));
  }
  getPaymentInstruction(): Observable<string> {
    return this.http
      .get<ApiBaseResponse<string>>(
        `${environment.apiUrl}/auction/GetPaymentInstructions`
      )
      .pipe(map((it) => it.data));
  }
  getUpcomingAuctions(
    currentAuctionId: number,
    IsApprovalPage?
  ): Observable<CommonDropdown[]> {
    let params = {
      Id: '' + currentAuctionId,
    };

    if (IsApprovalPage) {
      params['IsApprovalPage'] = IsApprovalPage;
    }

    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/auction/GetUpcomingAuctions`,
        {
          params: params,
        }
      )
      .pipe(map((it) => it.data));
  }
  getCustomButtons(): Observable<Array<string>> {
    return this.http
      .get<ApiBaseResponse<Array<string>>>(
        `${environment.apiUrl}/auction/GetButtonTypes`
      )
      .pipe(map((it) => it.data));
  }

  // step-4
  addEditDeleteAuctionStep4(model: AuctionStep4): Observable<AuctionStep4> {
    return this.http
      .post<ApiBaseResponse<AuctionStep4>>(
        `${environment.apiUrl}/auction/AddUpdateStepIv`,
        model
      )
      .pipe(map((it) => it.data));
  }
  getAuctionStep4(auctionId: number): Observable<AuctionStep4> {
    return this.http
      .get<ApiBaseResponse<AuctionStep4>>(
        `${environment.apiUrl}/auction/GetResponseStepIv/${auctionId}`
      )
      .pipe(map((it) => it.data));
  }

  // detail view
  getAuctionDetail(auctionId: number): Observable<AuctionDetail> {
    return this.http
      .get<ApiBaseResponse<AuctionDetail>>(
        `${environment.apiUrl}/auction/GetById/${auctionId}`
      )
      .pipe(map((it) => it.data));
  }

  getAuctionDetailByCode(code: string): Observable<AuctionDetail> {
    return this.http
      .get<ApiBaseResponse<AuctionDetail>>(
        `${environment.apiUrl}/PublicLink/${code}`
      )
      .pipe(map((it) => it.data));
  }

  // AuctionLot view
  getAuctionLot(paging: any): Observable<PageData<auctionLots[]>> {
    let param = new HttpParams();
    if (paging.isPaginated) {
      param = param.append('pageSize', '' + paging.pageSize);
      param = param.append('pageNumber', '' + paging.pageNumber);
      param = param.append('isPaginated', '' + paging.isPaginated);
    }
    param = param.append('auctionId', '' + paging.auctionId);
    return this.http
      .get<ApiBaseResponse<auctionLots[]>>(
        `${environment.apiUrl}/Lot/GetLotsByAuction`,
        { params: param }
      )
      .pipe(
        map((it) => {
          if (paging.isPaginated) {
            return {
              data: it.data,
              totalCount: it.pagingInfo.totalCount,
            };
          }
          return {
            data: it.data,
            totalCount: null,
          };
        })
      );
  }

  // detail view
  getAuctionDetailPublic(auctionId: number): Observable<AuctionDetail> {
    return this.http
      .post<ApiBaseResponse<AuctionDetail>>(
        `${environment.apiUrl}/Auction/AuctionDetailPublic`,
        {
          id: auctionId,
        }
      )
      .pipe(map((it) => it.data));
  }

  // register a user
  registerUserToAuction(
    id: number,
    spend: number | null,
    termCheck: boolean
  ): Observable<any> {
    let spending = spend && spend > 0 ? spend : null;

    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/auction/RegisterForAuction`,
        {
          auctionId: id,
          spending: spending,
          isAgree: termCheck,
        }
      )
      .pipe(map((it) => it.data));
  }

  // get registered users against auctionId
  getRegisteredUserTypesDropdown(): Observable<CommonDropdown[]> {
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/auction/GetAuctionRegisteredUserTypes`
      )
      .pipe(map((it) => it.data));
  }

  // get registered users against userID
  getRegisteredUserDetails(): Observable<RegisteredUsers[]> {
    return this.http
      .get<ApiBaseResponse<RegisteredUsers[]>>(
        `${environment.apiUrl}/auction/GetRegisteredUsers`
      )
      .pipe(map((it) => it.data));
  }

  getAuctionListDropdown(): Observable<CommonDropdown[]> {
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/auction/GetAllLiveUpcomingPastAuctionsDropdownList`
      )
      .pipe(map((it) => it.data));
  }

  getRegisteredUsers(paging: any): Observable<PageData<RegisteredUsers[]>> {
    let param = new HttpParams();
    if (paging.auctionId > 0) {
      param = param.append('auctionId', '' + paging.auctionId);
    }

    if (paging.userType > 0) {
      param = param.append('userType', '' + paging.userType);
    }

    if (paging.searchTerm != '' && paging.searchTerm != null)
      param = param.append('searchTerm', paging.searchTerm);

    if (paging.isPaginated) {
      param = param.append('isPaginated', '' + paging.isPaginated);
      param = param.append('pageSize', '' + paging.pageSize);
      param = param.append('pageNumber', '' + paging.pageNumber);
    }

    if (paging.sortBy) param = param.append('sortBy', '' + paging.sortBy);

    if (paging.sortOrder != '')
      param = param.append('sortOrder', '' + paging.sortOrder);

    return this.http
      .get<ApiBaseResponse<RegisteredUsers[]>>(
        `${environment.apiUrl}/auction/GetRegisteredUsers`,
        {
          params: param,
        }
      )
      .pipe(
        map((it) => {
          return {
            data: it.data,
            totalCount: it.pagingInfo.totalCount,
          };
        })
      );
  }

  markAsContacted(id: number, contactedIds: string): Observable<boolean> {
    return this.http
      .post<ApiBaseResponse<boolean>>(
        `${environment.apiUrl}/auction/MarkRegisteredUsersAsContacted`,
        {
          auctionId: id,
          userIds: contactedIds,
        }
      )
      .pipe(map((it) => it.data));
  }

  markAsApproved(id: number, approvedIds: string): Observable<boolean> {
    return this.http
      .post<ApiBaseResponse<boolean>>(
        `${environment.apiUrl}/auction/MarkRegisteredUsersAsApproved`,
        {
          auctionId: id,
          userIds: approvedIds,
        }
      )
      .pipe(map((it) => it.data));
  }

  markAsUnApproved(
    id: number,
    userId: number,
    approveStatus: boolean
  ): Observable<boolean> {
    return this.http
      .post<ApiBaseResponse<boolean>>(
        `${environment.apiUrl}/auction/ApproveUnapproveRegisteredUser`,
        {
          auctionId: id,
          userId: userId,
          isApproved: approveStatus,
        }
      )
      .pipe(map((it) => it.data));
  }

  markAsReserveBidder(id: number, userId: number): Observable<boolean> {
    return this.http
      .post<ApiBaseResponse<boolean>>(
        `${environment.apiUrl}/auction/MarkRegisteredUserAsReserved`,
        {
          auctionId: id,
          userId: userId,
        }
      )
      .pipe(map((it) => it.data));
  }

  // front-end list view
  getAllCurrentAuctions(model): Observable<PageData<CurrentAuctionList[]>> {
    return this.http
      .post<ApiBaseResponse<CurrentAuctionList[]>>(
        `${environment.apiUrl}/auction/GetAllAuctionsPublic`,
        model
      )
      .pipe(
        map((it) => {
          if (model.isPaginated) {
            return {
              data: it.data,
              totalCount: it.pagingInfo.totalCount,
            };
          }
          return {
            data: it.data,
            totalCount: null,
          };
        })
      );
  }

  ////// deposit rules
  requestDeposit(
    userId: number,
    auctionId: number,
    amount: number
  ): Observable<boolean> {
    let model = { userId: userId, amount: amount, auctionId: auctionId };
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/AuctionDeposit/RequestDeposit`,
        model
      )
      .pipe(map((it) => it.data));
  }

  cleanPhotos(
    auctionId: number,
    isPhotoGallery: boolean = false
  ): Observable<boolean> {
    let url = isPhotoGallery ? 'CleanPhotoGalleryImages' : 'CleanSliderImages';
    return this.http
      .post<ApiBaseResponse<boolean>>(`${environment.apiUrl}/Auction/${url}`, {
        auctionId: auctionId,
      })
      .pipe(map((it) => it.data));
  }

  getAuctionCurrentStatus(
    auctionCode: string
  ): Observable<AuctionCurrentState> {
    return this.http
      .get<ApiBaseResponse<AuctionCurrentState>>(
        `${environment.apiUrl}/Auction/GetAuctionData`,
        {
          params: { auctionCode: auctionCode },
        }
      )
      .pipe(map((it) => it.data));
  }

  getPublicAuctionData(
    reqType: number,
    auctionCode: string,
    assetType?: number
  ): Observable<Array<any>> {
    let request: string;
    let param = new HttpParams();

    switch (reqType) {
      case AuctionDataReqType.relatedAuctions:
        request = 'getRelatedAuctions';
        break;
      case AuctionDataReqType.contacts:
        request = 'getContacts';
        break;
      case AuctionDataReqType.documents:
        request = 'getDocuments';
        break;
      case AuctionDataReqType.images:
        request = 'getImages';
        break;
      case AuctionDataReqType.locations:
        request = 'getLocations';
        break;
    }

    param = param.append('auctionCode', auctionCode);
    if (assetType) param = param.append('assetType', '' + assetType);

    return this.http
      .get<ApiBaseResponse<Array<any>>>(`${environment.apiUrl}/${request}`, {
        params: param,
      })
      .pipe(map((it) => it.data));
  }

  getPublicDocumentsData(
    reqType: number,
    assetType?: number
  ): Observable<Array<any>> {
    let param = new HttpParams();
    let request: string;
    switch (reqType) {
      case AuctionDataReqType.relatedAuctions:
        request = 'getRelatedAuctions';
        break;
      case AuctionDataReqType.contacts:
        request = 'getContacts';
        break;
      case AuctionDataReqType.documents:
        request = 'getDocuments';
        break;
      case AuctionDataReqType.images:
        request = 'getImages';
        break;
      case AuctionDataReqType.locations:
        request = 'getLocations';
        break;
    }
    if (assetType) param = param.append('assetType', '' + assetType);

    return this.http
      .get<ApiBaseResponse<Array<any>>>(
        `${environment.apiUrl}/ContactUs/${request}`,
        {
          params: param,
        }
      )
      .pipe(map((it) => it.data));
  }

  getSaleLeadSheet(): Observable<any> {
    return this.http
      .get<ApiBaseResponse<any>>(`${environment.apiUrl}/GetSaleLeadSheet`)
      .pipe(map((it) => it.data));
  }

  getAuctionsByUserInDD(userId: number): Observable<CommonDropdown[]> {
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/AuctionUsers`,
        {
          params: {
            userId: '' + userId,
          },
        }
      )
      .pipe(map((it) => it.data));
  }

  getAuctionsByStatusInDD(auctionStatus: number): Observable<CommonDropdown[]> {
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/auction/AuctionByStatusDropdownList`,
        {
          params: {
            status: '' + auctionStatus,
          },
        }
      )
      .pipe(map((it) => it.data));
  }

  getAuctionsByBulkEmailStatusInDD(
    auctionStatus: number
  ): Observable<CommonDropdown[]> {
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/auction/AuctionBulkEmailListingByStatus`,
        {
          params: {
            status: '' + auctionStatus,
          },
        }
      )
      .pipe(map((it) => it.data));
  }

  addAuctionStepForRedirect(model: FormData): Observable<Auction> {
    return this.http
      .post<ApiBaseResponse<Auction>>(
        `${environment.apiUrl}/auction/AddInSingleStep`,
        model
      )
      .pipe(map((it) => it.data));
  }

  getCustomButtonsPublic(auctionCode: string): Observable<any> {
    return this.http
      .get<ApiBaseResponse<any>>(`${environment.apiUrl}/getButtons`, {
        params: {
          auctionCode: auctionCode,
        },
      })
      .pipe(map((it) => it.data));
  }

  unarchiveAuction(auctionId: number): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Auction/ChangeArchiveStatus`,
        {
          id: auctionId,
        }
      )
      .pipe(map((it) => it.data));
  }

  changeFeaturedPast(auctionId: number): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Auction/ChangeFeaturedPast`,
        {
          id: auctionId,
        }
      )
      .pipe(map((it) => it.data));
  }


  syncSliderImages(auctionId: number): Observable<LotFromBlob> {
    return this.http
      .post<ApiBaseResponse<LotFromBlob>>(
        `${environment.apiUrl}/Auction/SyncSliderImages`,
        {
          auctionId: auctionId,
        }
      )
      .pipe(map((it) => it.data));
  }
  syncGalleryImages(auctionId: number): Observable<LotFromBlob> {
    return this.http
      .post<ApiBaseResponse<LotFromBlob>>(
        `${environment.apiUrl}/Auction/SyncGalleryImages`,
        {
          auctionId: auctionId,
        }
      )
      .pipe(map((it) => it.data));
  }
  SyncPartnerLogoImages(auctionId: number): Observable<LotFromBlob> {
    return this.http
      .post<ApiBaseResponse<LotFromBlob>>(
        `${environment.apiUrl}/Auction/SyncPartnerLogoImages`,
        {
          auctionId: auctionId,
        }
      )
      .pipe(map((it) => it.data));
  }

  getLotStatusesAtConfigureLot(
    auctionId: number
  ): Observable<ConfigureLotStatuses> {
    return this.http
      .get<ApiBaseResponse<ConfigureLotStatuses>>(
        `${environment.apiUrl}/Auction/isAuctionSetupComplete`,
        {
          params: {
            auctionId: '' + auctionId,
          },
        }
      )
      .pipe(map((it) => it.data));
  }
}

interface AuctionPagination {
  saleTypeId?: number;
  status?: number;
  auctionStatusId: number;
  searchTerm: string;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  sortOrder?: string;
  isPaginated?: boolean;
  onlyActive?: boolean;
}

export interface PublicAuctionListPagination {
  saleType: number;
  category: number;
  searchTerm: string;
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
  isPaginated: boolean;
  onlyActive: boolean;
  displaySection: number;
  auctionStatus: number;
}

export enum AuctionDataReqType {
  relatedAuctions,
  images,
  documents,
  locations,
  contacts,
}

export interface ConfigureLotStatuses {
  isBulkLotAvailable: boolean;
  isLotAvailable: boolean;
  isLotImagesAvailable: boolean;
}
