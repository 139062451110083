import {Directive, Input, ElementRef, HostListener } from '@angular/core'

@Directive({
    selector: 'img[imgFallback]'
})

export class ImgFallbackDirective {
    @Input() imgFallback: string;
    attempted = false;
    constructor(private eRef: ElementRef) {}

    @HostListener('error')
    loadFallbackOnError() {
        const element: HTMLImageElement =<HTMLImageElement>this.eRef.nativeElement;
        if (!this.attempted) {
            element.src = this.imgFallback || '/assets/images/thumbnail-square.jpg';
            this.attempted = true;
        } else {
            element.src = '/assets/images/thumbnail-square.jpg';
        }
        //@ts-ignore
        element.style = "object-fit: cover"
    }
}