import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { LotService } from '../../../services';
import { AuctionTimeService } from '../../../services/auction-time.service';

@Component({
  selector: 'timer-countdown',
  template: `
    <ng-container *ngIf="timeDifference && timeDifference > 0">
      <ng-container *ngIf="daysToDday">
        {{ daysToDday ? daysToDday + 'D : ' : '' }}
      </ng-container>
      <ng-container *ngIf="daysToDday || hoursToDday">
        {{ hoursToDday ? hoursToDday + 'H : ' : '' }}
      </ng-container>
      <ng-container *ngIf="daysToDday || hoursToDday || minutesToDday">
        {{ minutesToDday ? minutesToDday + 'M : ' : '' }}
      </ng-container>
      <ng-container *ngIf="secondsToDday != undefined">
        {{ secondsToDday + 'S ' }}
      </ng-container>
    </ng-container>
  `,
})
export class TimerCountdownComponent implements OnInit {
  @Input() lotId: number;
  @Input() endTime: string;
  @Input() startTime: string;
  @Input() isLowConnectionMode: boolean;
  @Output('timeReached') timeLimit = new EventEmitter<boolean>();

  private _timeLeft: number;
  @Input() set timeLeft(value) {
    this._timeLeft = +value;
    this.timeDifference = +value;
  }
  get timeLeft(): number {
    return this._timeLeft;
  }
  private subscriptions: Array<Subscription> = [];
  private subscription: Subscription;

  private milliSecondsInASecond = 1000;
  private secondsInAMinute = 60;
  private minutesInAnHour = 60;
  private hoursInADay = 24;

  timeDifference: number;
  public secondsToDday: number;
  public minutesToDday: number;
  public hoursToDday: number;
  public daysToDday: number;

  public error: string;

  constructor(
    private lotService: LotService,
    private auctionTimer: AuctionTimeService
  ) {}

  ngOnInit() {
    if (this.lotId) this.getTimeLeft();
    else {
      this.timeDifference = this.timeLeft;
      this.startTimer();
    }
  }

  private getTimeLeft() {
    this.lotService
      .timeLeft(this.lotId)
      .toPromise()
      .then((res) => {
        this.timeDifference = +res;
        this.startTimer();
      });
  }

  serverTime: number;

  private startTimer() {
    const serverDateTime = this.auctionTimer.serverDateTime().subscribe((res) => {
      this.serverTime = res;
    });
    this.subscriptions.push(serverDateTime)
    this.subscription = interval(10000).subscribe((_) => {
      this.getTimeDifference();
    });
  }

  private getTimeDifference() {
    if (this.isLowConnectionMode) {
      this.timeDifference = this.timeDifference - 1000;
    } else {
      this.timeDifference =
        this.auctionTimer.convertToUtc(this.endTime) -
        this.auctionTimer.convertToUtc(this.startTime);
    }
    if (this.timeDifference <= 0) {
      this.timeLimit.emit(true);
      this.subscription.unsubscribe();
    } else {
      this.timeLimit.emit(false);
      this.allocateTimeUnits(this.timeDifference);
    }
  }

  startTimeChange: number;

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor(
      (timeDifference / this.milliSecondsInASecond) % this.secondsInAMinute
    );
    this.minutesToDday = Math.floor(
      (timeDifference / (this.milliSecondsInASecond * this.minutesInAnHour)) %
        this.secondsInAMinute
    );
    this.hoursToDday = Math.floor(
      (timeDifference /
        (this.milliSecondsInASecond *
          this.minutesInAnHour *
          this.secondsInAMinute)) %
        this.hoursInADay
    );
    this.daysToDday = Math.floor(
      timeDifference /
        (this.milliSecondsInASecond *
          this.minutesInAnHour *
          this.secondsInAMinute *
          this.hoursInADay)
    );
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
