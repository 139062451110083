import { Injectable } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(private readonly meta: MetaService) {}

  setPageTitle(value: string | null, isDelete: boolean = false) {
    let metaName = 'title';
    if (isDelete) {
      this.meta.setTitle(`${environment.websiteTitle}`);
    }
    if (value) {
      this.meta.setTitle(`${value}`);
    }
  }

  setMetaTagDescription(value: string | null, isDelete: boolean = false) {
    let metaName = 'description';
    if (isDelete) {
      this.meta.removeTag(`name="${metaName}"`);
      this.meta.removeTag(`property="og:${metaName}"`);
    }
    if (value) this.meta.setTag(`${metaName}`, value);
  }

  setMetaTagKeywords(value: string | null, isDelete: boolean = false) {
    let metaName = 'keywords';
    if (isDelete) {
      this.meta.removeTag(`name="${metaName}"`);
      this.meta.removeTag(`property="og:${metaName}"`);
    }
    if (value) this.meta.setTag(`${metaName}`, value);
  }

  removeAllMetaTags() {
    this.setPageTitle(null, true);
    this.setMetaTagKeywords(null, true);
    this.setMetaTagDescription(null, true);
  }
}
