import { FormControl } from '@angular/forms';
import constants from '../../utility/constants';

export class AppValidators {
  public static noWhitespace(control: FormControl) {
    if (typeof control.value !== 'string') return null;
    if (control.value === '') return null;
    return control.value.trim().length === 0 ? { noWhitespace: true } : null;
  }

  public static onlyNumeric(control: FormControl) {
    if (typeof control.value !== 'string') return null;
    if (control.value === '') return null;
    return /^\d+$/.test(control.value) ? null : { onlyNumeric: true };
  }

  public static onlyNumericSpecialCharacter(control: FormControl) {
    let urlRegex = constants.common.validations.specialCharacterNumericRegex;
    if (typeof control.value !== 'string') return null;
    if (control.value === '') return null;
    let pattern = new RegExp(urlRegex);
    return pattern.test(control.value) ? null : { onlyNumericSpecialCharacters: true };
  }

  public static onlyPercentage(control: FormControl) {
    if (control.value == null) return null;
    if (control.value === '') return null;
    return /^([1-9]([0-9]){0,2}|0)(\.[0-9]{1,3})?$/.test(control.value) ? null : { onlyPercentage: true };
  }

  public static onlyAlphaNumeric(control: FormControl) {
    let urlRegex = constants.common.validations.alphaNumeric;
    if (typeof control.value !== 'string') return null;
    if (control.value === '') return null;
    let pattern = new RegExp(urlRegex);
    return pattern.test(control.value) ? null : { onlyAlphaNumeric: true };
  }

  public static locationLotNum(control: FormControl) {
    if (typeof control.value !== 'string') return null;
    if (control.value === '') return null;
    return /^[A-Za-z0-9-, ]+$/.test(control.value) ? null : { onlyAlphaNumeric: true };
  }

  public static checkWebAddress(control: FormControl) {
    let urlRegex = constants.common.validations.webAddressRegex;
    if (typeof control.value !== 'string') return null;
    if (control.value === '') return null;
    let pattern = new RegExp(urlRegex);
    return pattern.test(control.value) ? null : { invalidWebAddress: true };
  }

  public static acceptTerms(control: FormControl) {
    return (control.value == true) ? null : { notCheckTerms: true }
  }

  public static checkPassword(control: FormControl) {
    let passwordRegex = constants.common.validations.passwordRegex;
    if (typeof control.value !== 'string') return null;
    if (control.value === '') return null;
    let pattern = new RegExp(passwordRegex);
    return pattern.test(control.value) ? null : { invalidPassword: true };
  }

  public static checkEmail(control: FormControl) {
    let emailRegex = constants.common.validations.emailRegex;
    if (typeof control.value !== 'string') return null;
    if (control.value === '') return null;
    let pattern = new RegExp(emailRegex);
    return pattern.test(control.value) ? null : { invalidEmail: true };
  }

  public static checkLotNumber(control: FormControl) {
    let lotNumberRegex = constants.common.validations.lotNumberRegex;
    if (typeof control.value !== 'string') return null;
    if (control.value === '') return null;
    let pattern = new RegExp(lotNumberRegex);
    return pattern.test(control.value) ? null : { invalidlotNumber: true };
  }

  public static checkBulkLotNumber(control: FormControl) {
    let bulkLotNumberRegex = constants.common.validations.bulkLotNumberRegex;
    if (typeof control.value !== 'string') return null;
    if (control.value === '') return null;
    let pattern = new RegExp(bulkLotNumberRegex);
    return pattern.test(control.value) ? null : { invalidBulkLotNumber: true };
  }
}
