import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor, ErrorInterceptor } from './helpers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ToastrModule } from 'ngx-toastr';
import { AdminSharedModule } from './admin/shared/shared.module';

import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MetaModule } from '@ngx-meta/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonSharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent, ResetPasswordComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    CommonSharedModule,
    HttpClientModule,
    AppRoutingModule,
    AdminSharedModule,
    ToastrModule.forRoot({
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning',
      },
      closeButton: true,
      easing: 'ease-in-out',
      easeTime: 200,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      tapToDismiss: true,
    }),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    MetaModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
