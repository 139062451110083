<div class="icons-dropfilter-outer">
  <div class="for-icons-dropfilter mr-3">
    <span class="filter-name">{{ placeholder }}</span>
    <div class="custom-dropdown">
      <button
        class="no-bg-btn hover-icon"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [ngClass]="{
          'badge-active': isAll ? control != 1 : control != null
        }"
      >
        <img
          src="../assets/images/filter.svg"
          title="filter-bar-icon"
          alt="filter-bar-icon"
        />
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <div class="custom-dropdown filter-dropdown">
          <button
            *ngIf="!isAll"
            mat-menu-item
            role="button"
            (click)="onFilterClick(null)"
          >
            <span>All</span>
          </button>
          <button
            mat-menu-item
            *ngFor="let item of filters"
            role="button"
            (click)="onFilterClick(item.value)"
          >
            <input
              type="radio"
              name="auction-filter"
              [value]="item.value"
              [(ngModel)]="control"
            />
            <span>{{ item.text }}</span>
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
</div>
