export default {
  admin: {},
  public: {
    default: '',

    bgiSurplusLink: 'https://www.bgisurplus.com',

    about: 'about',
    valuations: 'valuations',
    removal: 'removal',
    payments: 'payments',
    faq: 'faq',
    sellers: 'sellers',
    history: 'history',
    buyAsset: 'buy-assets',
    policy: 'privacy-policy',
    sellAsset: 'sell-assets',
    terms: 'term-and-conditions',
    valueAsset: 'value-assets',
    whyBranford: 'why-branford',

    contactUs: 'contact',

    team: 'team',

    assetRecovery: 'asset-recovery',

    blogList: 'news',
    blogDetail: ':newsId',

    currentAuction: 'auctions',
    pastAuction: 'past-auctions',
    turnKeySale: 'turn-key-sales',
    machineryMarketPlace: 'machinery-marketplace',
    auctionDetail: ':id',
    auctionRegister: 'register',
    auctionLot: 'lots',
    viewAuctionLotDetail: ':lotId',
    enquire: 'enquire',

    profile: 'profile',
    editProfile: 'edit',

    userPaymentForm: 'user-payment',
    depositForm: 'deposit',
    publicLink: ':auctionCode',
    searchLotCatalog: 'search-lot-catalog',
    machinery: 'surplus',
    featuredPast: 'featured-past',
    newsletter: 'newsletter',

    profileSection: {
      allNotifications: 'notifications',
      notificationSetting: 'setting',
    },
  },
};
