import { EventBusService } from './../../../../../services/event-bus-service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, UserService } from '../../../../../services';
import { ModuleService } from '../../../../module.service';
import constants from '../../../../../utility/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  checkLoggedIn: string;
  mobileMenu: false;
  userId: number;
  role: number;
  userName: string;
  private readonly userTypes = constants.common.loginType;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private router: Router,
    private as: AuthenticationService,
    private ms: ModuleService,
    private us: UserService,
    private eventBusService: EventBusService
  ) {}

  ngOnInit() {
    this.userId = this.as.currentUserValue()?.user.id;
    this.role = this.as.currentUserValue()?.user.role.id;
    this.getUser();
    const req = this.eventBusService.getName().subscribe((data) => {
      this.userName = data;
    });
    this.subscriptions.push(req);
  }

  getUser() {
    const getUser = this.us.get(this.userId).subscribe((res) => {
      this.userName = res.firstName;
    });
    this.subscriptions.push(getUser);
  }

  logout() {
    this.as.logout(this.userTypes.admin);

    //this is hack, the ms service should be destroyed at this point.
    //So before logging out clear the modules,So after new login they
    //can be repopulated with newly user's modules
    this.ms.clearModules();
  }

  gotoManageAccount() {
    this.router.navigate(['/admin/dashboard/profile']);
  }

  gotoNotifications() {
    this.router.navigate(['/admin/dashboard/profile/setting']);
  }

  onLogo() {
    if (this.role <= 2)
      return this.router.navigate(['/admin', 'dashboard', 'admins']);
    return this.router.navigate(['/admin', 'dashboard']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
