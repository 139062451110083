export default {
  admin: {
    modules: {
      adminUserManagement: 'AdminUserManagement',
      roleAssignment: 'RoleAssignment',
      auctionCategoryManagement: 'AuctionCategoryManagement',
      contentManagement: 'ContentManagement',
      saleTypeManagement: 'SaleTypeManagement',
      salesUserManagement: 'SalesUserManagement',
      reserveBiddersGroupManagement: 'ReserveBiddersGroupManagement',
      saleStatusManagement: 'SaleStatusManagement',
      auctionManagement: 'AuctionManagement',
      lotItemSearch: 'LotItemSearch',
      registeredUsersOnSale: 'RegisteredUsersOnSale',
      registeredUserSearch: 'RegisteredUserSearch',
      auctionMonitoring: 'AuctionMonitoring',
      auctionSummary: 'AuctionSummary',
      reports: 'Reports',
      emailAndWebNotifications: 'EmailAndWebNotifications',
      announcement: 'Announcement',
      startStopRegistration: 'StartStopRegistration',
      cms: 'CMS',
      blog: 'Blog',
      buyerUser: 'BuyerUser',
      masterCatalog: 'MasterCatalog',
      myBids: 'MyBids',
      createNewAuction: 'CreateNewAuction',
      approveAuction: 'ApproveAuction',
      bulkEmail: 'BulkEmail',
      bidIncrement: 'BidIncrement',
    },
    initialValues: {
      generalBuyersPremium: 20,
      creditCardBuyersPremium: 23,
      extendMinutes: 5,
      lotClosesIn: 30,
      defaultStartTime: '08:00 am',
      defaultEndTime: '10:00 am',
      imageUploadBucket: 25,
      imagesAllowedToUpload: 100,
      defaultRequestDepositAmount: 500,
      maxRequestDeposit: 10000,
      inspectionPreview: 'Available by Appointment Only',
      millisecondsAfterClose: 12
    },
    uploadSetting: {
      partnerLogoType: 'jpg,jpeg,png',
      partnerLogoMaxSize: '5242880', //<= 5MB
      documentType: 'application/pdf',
      documentMaxSize: '5242880', //<= 5MB
      bannerType: 'jpg,jpeg,png',
      bannerMaxSize: '26214400', //bytes i.e. 25MB
      blogType: 'jpg,jpeg,png',
      blogMaxSize: '26214400', //bytes i.e. 25MB
      lotType: 'jpg,jpeg,png',
      lotMaxSize: '5242880', //bytes i.e. 5MB
      managementTeamPhotoType: 'jpg,jpeg,png',
      managementTeamPhotoMaxSize: '2097152', // bytes i.e. 2MB
      clientLogoType: 'jpg,jpeg,png',
      clientLogoMaxSize: '2097152', //bytes i.e. 2MB
      coverPhotoType: 'jpg,jpeg,png',
      coverPhotoMaxSize: '10485760', //bytes i.e. 10MB
      sliderImageType: 'image/jpg,image/jpeg,image/png',
      sliderImageMaxSize: '5242880', //<= 5MB
      photoGalleryType: 'image/jpg,image/jpeg,image/png',
      photoGalleryMaxSize: '5242880', //<= 5MB
      // '10485760', //bytes i.e. 10MB
    },
    csvUploadSetting: {
      lotImportCsvFileExtension: 'csv',
      lotImportCsvFileMaxSize: '5242880', //<= 5MB
      lotImportCsvFileType: 'text/plain',
    },
    assetType: {
      document: 1,
      sliderImage: 2,
      photoGallery: 3,
      coverPhoto: 4,
      clientLogo: 5,
      partnerLogo: 6,
      paymentDocument: 7,
      customButtonDocument: 8,
    },
    adminPageSize: 21,
    lotPageSize: 50,
    depositStatus: {
      notRequested: 1,
      pending: 2,
      received: 3,
    },
    noOfEmailsToAdd: 50,
  },
  public: {
    readyToSpentMin: 1,
    readyToSpentMax: 100000000000000,
    commonCms: {
      aboutUs: {
        title: 'Who We Are',
        subtitle: '',
        showHeader: true,
        page: 'aboutUs',
        headerBg: '/assets/images/who-we-are.jpg',
      },
      valuations: {
        title: 'Valuations',
        subtitle: '',
        showHeader: true,
        page: 'valuations',
        headerBg: '/assets/images/valuations-header.jpg',
      },
      payments: {
        title: 'Payments',
        subtitle: 'Payments of Branford Group',
        showHeader: true,
        page: 'payments',
        headerBg: '/assets/images/Payment.jpg',
      },
      faq: {
        title: 'FAQ',
        subtitle: 'FAQ of Branford Group',
        showHeader: true,
        page: 'faq',
        headerBg: '/assets/images/FAQ.jpg',
      },
      removal: {
        title: 'REMOVAL & RIGGING',
        subtitle: 'Removal of Branford Group',
        showHeader: true,
        page: 'removal',
        headerBg: '/assets/images/Removal.jpg',
      },
      sellers: {
        title: 'Sellers',
        subtitle: 'Sellers of Branford Group',
        showHeader: true,
        page: 'sellers',
        headerBg: '/assets/images/Sellers.jpg',
      },
      history: {
        title: 'History',
        subtitle: 'History of Branford Group',
        showHeader: true,
        page: 'history',
        headerBg: '/assets/images/banner-bg.jpg',
      },
      buyAssets: {
        title: 'Buy Assets',
        subtitle: 'About the assets',
        showHeader: true,
        page: 'buyAssets',
        headerBg: '/assets/images/banner-bg.jpg',
      },
      privacyPolicy: {
        title: 'Privacy Policy',
        subtitle: 'About Privacy Policy',
        showHeader: false,
        page: 'privacyPolicy',
        headerBg: '/assets/images/banner-bg.jpg',
      },
      sellAssets: {
        title: 'Sell Your Assets',
        subtitle: 'About the assets',
        showHeader: true,
        page: 'sellAssets',
        headerBg: '/assets/images/banner-bg.jpg',
      },
      tAndC: {
        title: 'Terms And Conditions',
        subtitle: 'About',
        showHeader: false,
        page: 'tAndC',
        headerBg: '/assets/images/banner-bg.jpg',
      },
      valueAssets: {
        title: 'Value Your Assets',
        subtitle: 'About the assets',
        showHeader: true,
        page: 'valueAssets',
        headerBg: '/assets/images/banner-bg.jpg',
      },
      whyBranford: {
        title: 'Why Choose Us',
        subtitle: '',
        showHeader: true,
        page: 'whyBranford',
        headerBg: '/assets/images/why-choose-us.jpg',
      },
      assetRecovery: {
        title: 'Asset Recovery Programs',
        subtitle: '',
        showHeader: true,
        page: 'assetRecovery',
        headerBg: '/assets/images/Asset-Recovery.jpg',
      },
    },
    blogPageSize: 6,
    publicPageSize: 21,
    lotPageSize: 50,
  },
  common: {
    bid: {
      bidMinAmount: 1,
      bidMaxAmount: 99999999,
    },
    modifier: {
      min: -50,
      max: 50,
    },
    notificationCounter: 5,
    saletypeRegistrationFormat: {
      tbgRegistration: 1,
      thirdPartyRegistration: 2,
      inquiryForm: 3,
    },
    validations: {
      searchMaxLength: 30,
      maxLength10: 10,
      maxLength15: 15,
      maxLength25: 25,
      maxLength20: 20,
      maxLength30: 30,
      maxLength50: 50,
      maxLength100: 100,
      maxLength150: 150,
      maxLength200: 200,
      maxLength250: 250,
      maxLength300: 300,
      maxLength500: 500,
      maxLength1000: 1000,
      maxLength1500: 1500,
      maxLength2000: 2000,
      maxLength2500: 2500,
      maxLength5000: 5000,
      maxLength8000: 8000,
      maxLength10000: 10000,
      maxLength36000: 36000,
      maxLengthPaddleNumber: 10,
      maxLengthMobileNumber: 15,
      maxLengthPhone: 15,
      // chars : >= 8 characters, minimum : 1 [A-Z] and 1 [0-9] and no whitespace
      passwordRegex: `(?=.*[0-9])(?=.*[A-Z])(?=.*[a-zA-Z])(?!.*[\t\n\r ]).{8,}`,
      webAddressRegex: `^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$`,
      emailRegex: `^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+([.]{1})+([a-zA-Z0-9-.]+)$`,
      lotNumberRegex: '^[1-9][0-9]*[a-zA-Z]?$',
      bulkLotNumberRegex: '^([A-Za-z]+)([a-zA-Z0-9]*)?$',
      numericRegex: '^[0-9]$',
      alphaNumeric: `^[A-Za-z0-9-,. ]+$`,
      specialCharacterNumericRegex: '^[0-9.()-_,]+$',
    },
    clientMail: {
      clientServiceEmail: 'service@branfordgroup.com',
    },
    toast: {
      errorTitle: 'Error',
      successTitle: 'Success',
      infoTitle: 'Info',
      notifyTitle: 'Notification',
      config: {
        timeOut: 2500,
      },
      notifyConfig: {
        timeOut: 4000,
      },
    },
    auctionStatus: {
      live: 1,
      upcoming: 2,
      pending: 3,
      past: 4,
      liveUpcoming: 5,
      LiveUpcomingPast: 6,
      LiveUpcomingFeatured: 7,
      livePast: 8,
      archived: 9,
      featuredPast: 10,
    },
    displaySection: {
      auctionCalender: 1,
      machinery: 2,
      turnKey: 3,
      featuredPast: [1,2,3]
    },
    auctionDate: {
      onlyStartDate: 1,
      onlyEndDate: 2,
      bothDates: 3,
      noDate: 4,
    },
    role: {
      systemSuperAdmin: 1,
      superAdmin: 2,
      admin: 3,
      customerService: 4,
      auctionAdmin: 5,
      marketingAdmin: 6,
      buyer: 7,
    },
    loginType: {
      admin: 1,
      public: 2,
    },
    lotTypes: {
      individual: 1,
      bulk: 2,
    },
    helpText: {
      catalogBidding: 1,
      readOnlyCatalog: 2,
      none: 3,
    },
    sortBy: {
      asc: 'Ascending',
      desc: 'Descending',
    },
    sortOrder: {
      startTime: '',
      endTime: '',
      name: 'Name',
      email: 'Email',
      dateRegistered: 'Date Registered',
    },
    cmsPages: {
      fePayment: 1,
      industryExperties: 2,
      logisticsAndRemoval: 3,
      sellAssets: 4,
      salesTaxForms: 5,
      testimonials: 6,
      whyBrandford: 7,
      privacyPolicy: 8,
      contactUs: 9,
      faq: 10,
      removalShipping: 11,
      payments: 12,
      termsAndConditions: 13,
      valuations: 14,
      sellYourAssets: 15,
      services: 16,
      helpUs: 17,
      helpTextCatalogWithBidding: 18,
      helpTextReadOnlyCatalog: 19,
      history: 20,
      meetOurTeam: 21,
      registrationAndDepositRules: 22,
      paymentInstructions: 23,
      aboutUs: 24,
      buyUserAssets: 25,
      valueYourAssets: 26,
      riggingInfo: 27,
      saleTaxInfo: 28,
      defaultRemovalDatesRules: 30,
    },
    bannerAlignment: {
      left: 1,
      right: 2,
    },
    soldStatus: {
      all: 1,
      sold: 2,
      unsold: 3,
      cancelled: 4,
      pending: 5,
    },
  },
};
