import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmailTemplate, ApiBaseResponse, CommonDropdown } from '../models';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateService {
  constructor(private http: HttpClient) {}

  getAllTemplates(): Observable<EmailTemplate[]> {
    return this.http
      .get<ApiBaseResponse<EmailTemplate[]>>(
        `${environment.apiUrl}/emailTemplate`
      )
      .pipe(map((it) => it.data));
  }

  getEmailTemplate(id: number): Observable<EmailTemplate> {
    return this.http
      .get<ApiBaseResponse<EmailTemplate>>(
        `${environment.apiUrl}/emailTemplate/${id}`
      )
      .pipe(map((it) => it.data));
  }

  updateEmailTemplatePage(templateModel: any): Observable<EmailTemplate> {
    return this.http
      .post<ApiBaseResponse<EmailTemplate>>(
        `${environment.apiUrl}/emailTemplate`,
        {
          id: templateModel.id,
          title: templateModel.title,
          subject: templateModel.subject,
          message: templateModel.message,
        }
      )
      .pipe(map((it) => it.data));
  }

  changeStatus(tempId: number, tempStatus: boolean): Observable<EmailTemplate> {
    return this.http
      .patch<ApiBaseResponse<EmailTemplate>>(
        `${environment.apiUrl}/emailTemplate`,
        {
          id: tempId,
          status: tempStatus,
        }
      )
      .pipe(map((it) => it.data));
  }

  getEmailTemplateDropdown(): Observable<CommonDropdown[]> {
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/emailTemplate/GetActiveDropDownList`
      )
      .pipe(map((it) => it.data));
  }
}
