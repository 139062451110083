import { AuthenticationService } from './services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { BuyerService } from './services/buyer.service';
import {
  ActivatedRoute,
  Router,
  GuardsCheckStart,
  GuardsCheckEnd,
  ResolveStart,
  ResolveEnd,
  NavigationError,
  NavigationEnd,
  NavigationStart,
} from '@angular/router';
import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from './services';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import constants from './utility/constants';
import { ViewportScroller } from '@angular/common';
import { environment } from '../environments/environment';
import { Subject, timer } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  showLoader$;
  modalRef: BsModalRef;

  constructor(
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private buyerService: BuyerService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private scroll: ViewportScroller,
    private cdRef: ChangeDetectorRef
  ) {
    this.routingEvent();
    this.addGaTrackScript();
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.startGaTracking();

    $(document).ready(() => {
      console.log('Jquery Integration With Angular!');
    });

    this.loaderService.isLoading$.subscribe((res) => {
      this.showLoader$ = res;
    });

    this.route.params.subscribe((params) => {
      if (this.router.url.split('/')[1] == 'activate') {
        let hash = params['token'];
        this.buyerService.activate(hash).subscribe(
          () => {
            this.toastr.success(
              'Your account has been activated. Please login to continue',
              constants.common.toast.successTitle,
              constants.common.toast.config
            );
            this.router.navigate(['']);
          },
          (error) => {
            this.toastr.error(
              error,
              constants.common.toast.errorTitle,
              constants.common.toast.config
            );
            this.router.navigate(['']);
          }
        );
      }
      if (this.router.url.split('/')[1] == 'user-reset-password') {
        let modalOptions: ModalOptions = {
          backdrop: 'static',
          keyboard: false,
          class: 'custom-popup user-login modal-dialog modal-dialog-centered',
        };
        let hash = params['token'];
        this.authenticationService
          .validatePasswordLink(hash)
          .subscribe((res) => {
            if (res.data == true) {
              this.router.navigate(['']);
              this.modalRef = this.modalService.show(
                ResetPasswordComponent,
                modalOptions
              );
              this.modalRef.content = hash;
            } else {
              this.toastr.error(
                'Reset password link has expired',
                constants.common.toast.errorTitle,
                constants.common.toast.config
              );
              this.router.navigate(['']);
            }
          });
      }
    });
  }

  private routingEvent() {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof GuardsCheckStart:
          this.loaderService.showLoader(true);
          break;
        case event instanceof ResolveStart:
          this.loaderService.showLoader(true);
          break;
        case event instanceof GuardsCheckEnd:
          this.loaderService.showLoader(false);
          break;
        case event instanceof ResolveEnd:
          this.loaderService.showLoader(false);
          break;
        case event instanceof NavigationError:
          this.router.navigate(['/not-found']);
          break;
      }
      if (event instanceof NavigationStart) {
        this.scroll.scrollToPosition([0, 0]);
      }
      if (event instanceof NavigationEnd) {
        if (typeof window !== 'undefined') {
          if(window['gtag']){
            window['gtag']('config', environment.gaTrackingId, {
              page_path: event.urlAfterRedirects,
            });
          }
      }
      }
    });
  }

  private addGaTrackScript() {
    if (environment.gaTrackingId) {
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaTrackingId}`;
      document.head.appendChild(gTagManagerScript);

      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
      `;
      document.head.appendChild(gaScript);
    }
  }

  private startGaTracking() {}

  ngOnDestroy() {
    this.destroy$.next();
  }
}
