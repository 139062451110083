import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'char-counter',
  template: `
  <div >{{ actualLength }} / {{ maxlength }} </div >
  `,
  styles: [`
  div{
    font-size: 12px;
    margin: 0;
    padding: 0;
    text-align: right;
    line-height: .8;
  }
    `]
})
export class CharCounterComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() maxlength: number = 0;

  private controlSubscription$: Subscription;

  actualLength: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.checkChars();
  }

  checkChars() {
    this.stringConv(this.control.value);
    this.controlSubscription$ =
      this.control.valueChanges.subscribe(value => {
        this.stringConv(value);
      })
  }

  private stringConv(value: any) {
    if (value) {
      if (typeof value == 'string')
        this.actualLength = value.length;
      if (typeof value == 'number')
        this.actualLength = value.toString().length;
    } else {
      this.actualLength = 0;
    }
  }

  ngOnDestroy() {
    this.controlSubscription$.unsubscribe();
  }
}
