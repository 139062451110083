import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '../../../utility';

@Component({
  selector: 'control-messages',
  template: `<div class="error-msg" *ngIf="errorMessage !== null">
    {{ errorMessage }}
  </div> `,
})
export class ControlMessagesComponent {
  @Input() control: FormControl;
  @Input() isFormSubmitted: boolean;
  constructor() { }

  public get errorMessage(): any | null {
    if (this.control === undefined || this.control == null) {
      return null;
    }
    for (let propertyName in this.control.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        (this.control.touched || this.isFormSubmitted)
      ) {
        if (propertyName == 'minlength' || propertyName == 'maxlength') {
          return (
            ValidationService.getValidatorErrorMessage(
              propertyName,
              this.control.errors[propertyName]
            ) +
            ' ' +
            this.control.errors[propertyName].requiredLength +
            ' characters'
          );
        }

        if (propertyName == 'min' || propertyName == 'max') {
          return (
            ValidationService.getValidatorErrorMessage(
              propertyName,
              this.control.errors[propertyName]
            ) +
            (this.control.errors[propertyName]?.min || this.control.errors[propertyName]?.max)
          );
        }

        //check keyword length
        if (propertyName == 'invalidKeywordLength') {
          return (
            ValidationService.getValidatorErrorMessage(
              'invalidKeywordLength',
              this.control.errors[propertyName]
            ) +
            ' ' +
            this.control.errors[propertyName].requiredLength +
            ' keywords'
          );
        }

        //check web address
        if (propertyName == 'invalidWebAddress') {
          return ValidationService.getValidatorErrorMessage(
            'invalidWebAddress',
            this.control.errors[propertyName]
          );
        }

        //check invalid percentage format
        if (propertyName == 'onlyPercentage') {
          return ValidationService.getValidatorErrorMessage(
            'onlyPercentage',
            this.control.errors[propertyName]
          );
        }

        //password change pattern
        if (propertyName == 'invalidPassword') {
          return ValidationService.getValidatorErrorMessage(
            'invalidPassword',
            this.control.errors[propertyName]
          );
        }

        // invalidMatchPassword
        if (propertyName == 'invalidMatchPassword') {
          return ValidationService.getValidatorErrorMessage(
            'passwordMismatch',
            this.control.errors[propertyName]
          );
        }

        // invalid email
        if (propertyName == 'invalidEmail') {
          return ValidationService.getValidatorErrorMessage(
            'invalidEmail',
            this.control.errors[propertyName]
          );
        }

        // async validator in add-auction cmpt for unique title
        if (propertyName == 'isTitleExists') {
          return ValidationService.getValidatorErrorMessage(
            'invalidTitle',
            this.control.errors[propertyName]
          );
        }

        // async validator in add-auction cmpt for unique auction code
        if (propertyName == 'isAuctionCodeExists') {
          return ValidationService.getValidatorErrorMessage(
            'invalidAuctionCode',
            this.control.errors[propertyName]
          );
        }

        // custom validator in add-auction cmpt for end Date( should be >= start Date )
        if (propertyName == 'invalidEndDate') {
          return ValidationService.getValidatorErrorMessage(
            'invalidEndDate',
            this.control.errors[propertyName]
          );
        }

        if (propertyName == 'invalidFileType') {
          return (
            ValidationService.getValidatorErrorMessage(
              propertyName,
              this.control.errors[propertyName]
            ) +
            ' ' +
            this.control.errors[propertyName].requiredType
          );
        }

        if (propertyName == 'invalidFileExtension') {
          return (
            ValidationService.getValidatorErrorMessage(
              propertyName,
              this.control.errors[propertyName]
            ) +
            ' ' +
            this.control.errors[propertyName].requiredType
          );
        }


        if (propertyName == 'invalidFileSize') {
          return (
            ValidationService.getValidatorErrorMessage(
              propertyName,
              this.control.errors[propertyName]
            ) +
            ' ' +
            this.control.errors[propertyName].requiredSize / (1024 * 1024) +
            ' MB'
          );
        }

        if (propertyName == 'invalidlotNumber') {
          return ValidationService.getValidatorErrorMessage(
            'invalidlotNumber',
            this.control.errors[propertyName]
          );
        }

        if (propertyName == 'invalidBulkLotNumber') {
          return ValidationService.getValidatorErrorMessage(
            'invalidBulkLotNumber',
            this.control.errors[propertyName]
          );
        }

        return ValidationService.getValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName]
        );
      }
    }
    return null;
  }
}
