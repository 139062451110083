import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { Category } from '../models';
import { PageData } from '../models/pageData';
import { ApiBaseResponse } from '../models/ApiBaseResponse';
import { map } from 'rxjs/operators';
import { Pagination } from '../models/paging';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  getCategories(paging: Pagination): Observable<PageData<Category[]>> {
    let param = new HttpParams();

    if (paging.onlyActive)
      param = param.append('onlyActive', '' + paging.onlyActive);

    if (paging.searchTerm != '')
      param = param.append('searchTerm', paging.searchTerm);

    if (paging.sortBy != '') param = param.append('sortBy', paging.sortBy);

    if (paging.sortOrder != '')
      param = param.append('sortOrder', paging.sortOrder);

    if (paging.isPaginated) {
      param = param.append('pageSize', '' + paging.pageSize);
      param = param.append('pageNumber', '' + paging.pageNumber);
      param = param.append('isPaginated', '' + paging.isPaginated);
    }

    return this.http
      .get<ApiBaseResponse<Category[]>>(
        `${environment.apiUrl}/category/getAll`,
        {
          params: param,
        }
      )
      .pipe(
        map((it) => {
          if (paging.isPaginated) {
            return {
              data: it.data,
              totalCount: it.pagingInfo.totalCount,
            };
          }
          return {
            data: it.data,
            totalCount: null,
          };
        })
      );
  }

  addCategory(
    categoryName: string,
    categoryDesc: string
  ): Observable<Category> {
    return this.http
      .post<ApiBaseResponse<Category>>(`${environment.apiUrl}/category/add`, {
        name: categoryName,
        description: categoryDesc,
      })
      .pipe(map((it) => it.data));
  }

  getCategory(id: number): Observable<Category> {
    return this.http
      .get<ApiBaseResponse<Category>>(
        `${environment.apiUrl}/category/getById/${id}`
      )
      .pipe(map((it) => it.data));
  }

  updateCategory(
    categoryId: number,
    categoryName: string,
    categoryDesc: string
  ): Observable<Category> {
    return this.http
      .post<ApiBaseResponse<Category>>(`${environment.apiUrl}/category/update`, {
        id: categoryId,
        name: categoryName,
        description: categoryDesc,
      })
      .pipe(map((it) => it.data));
  }

  changeActiveStatus(
    categoryId: number,
    categoryStatus: boolean
  ): Observable<Category> {
    return this.http
      .post<ApiBaseResponse<Category>>(
        `${environment.apiUrl}/category/changStatus`,
        {
          id: categoryId,
          status: categoryStatus,
        }
      )
      .pipe(map((it) => it.data));
  }

  categoryForDropDown(): Observable<{ text: string; value: number }[]> {
    return this.http
      .get<ApiBaseResponse<{ text: string; value: number }[]>>(
        `${environment.apiUrl}/category/GetActiveDropDownList`
      )
      .pipe(map((it) => it.data));
  }
}
