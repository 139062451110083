<section class="top-header fixed-top">
  <nav class="navbar navbar-expand-md p-0">
    <a class="navbar-brand" role="button" (click)="onLogo()">
      <img
        src="../assets/images/the-branford-logo.svg"
        title="logo"
        class="logo"
      />
    </a>

    <div class="noti-dropdown-outer ml-auto">
      <div class="noti-dropdown" notification-counter [isAdminBar]="true"></div>
    </div>

    <div class="menu-mobile-dd">
      <div class="header-profile-menu">
        <button mat-button [matMenuTriggerFor]="headermenu">
          <span class="hpm-img">
            <img src="../assets/images/user.svg" />
          </span>
          <span>{{ userName }}</span>
        </button>
        <mat-menu #headermenu="matMenu" xPosition="before">
          <div class="header-profile-menu">
            <button
              *ngIf="role != 1 && role != 2"
              mat-menu-item
              role="button"
              (click)="gotoNotifications()"
            >
              Notification Settings
            </button>
            <button mat-menu-item role="button" (click)="gotoManageAccount()">
              Manage Account
            </button>
            <button mat-menu-item role="button" (click)="logout()">
              Logout
            </button>
          </div>
        </mat-menu>
      </div>
    </div>
  </nav>
</section>

<section class="mobile-menu-wrap" *ngIf="mobileMenu">
  <div class="logo-wrap">
    <a class="for-logo">
      <img
        src="../assets/images/logo-small.svg"
        title="logo-mobile"
        class="logo"
      />
    </a>

    <span class="cross-icon">
      <img src="../assets/images/cross.svg" title="menu-cross-icon" />
    </span>
  </div>
  <div class="list-wrap">
    <div class="list-parts">
      <h3 class="parts-outer-name">Main Menu</h3>
      <ul class="mobile-menu-list">
        <li>
          <a href="">Manage Admins</a>
        </li>

        <li>
          <a routerLink="category">Auction Categories</a>
        </li>
      </ul>
    </div>

    <div class="list-parts">
      <h3 class="parts-outer-name">My Account</h3>
      <ul class="mobile-menu-list">
        <li>
          <a href="">Manage Account</a>
        </li>

        <li>
          <a role="button" (click)="logout()">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</section>
