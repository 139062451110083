import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  UrlTree
} from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthRoleGuard implements CanLoad, CanActivate {

  constructor(
  ) {}

  canLoad(): boolean | UrlTree {
    return true;
  }

  canActivate() {
    return true;
  }
}
