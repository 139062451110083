import { environment } from './../../environments/environment';
import { Buyer } from './../models/buyer';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiBaseResponse,
  PlatformUsers,
  CommonDropdown,
  PageData,
} from '../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { AdminBid } from '../models/adminBId';

@Injectable({
  providedIn: 'root',
})
export class BuyerService {
  constructor(private http: HttpClient) {}

  register(model: Buyer): Observable<Buyer> {
    model.password = this.encryptData(model.password);

    return this.http
      .post<ApiBaseResponse<Buyer>>(
        `${environment.apiUrl}/buyerUsers/Register`,
        model
      )
      .pipe(map((it) => it.data));
  }
  activate(hash: any): Observable<String> {
    return this.http
      .post<ApiBaseResponse<Buyer>>(
        `${environment.apiUrl}/buyerUsers/Activate/`,
        {
          encryptedPayload: hash,
        }
      )
      .pipe(map((it) => String(it.message)));
  }
  getById(id): Observable<Buyer> {
    return this.http
      .get<ApiBaseResponse<Buyer>>(
        `${environment.apiUrl}/buyerUsers/GetById/${id}`
      )
      .pipe(map((it) => it.data));
  }
  edit(model: Buyer): Observable<String> {
    return this.http
      .post<ApiBaseResponse<Buyer>>(
        `${environment.apiUrl}/buyerUsers/Edit/`,
        model
      )
      .pipe(map((it) => String(it.data)));
  }
  deactivate(id: number, password: string): Observable<String> {
    let encPassword = this.encryptData(password);

    return this.http
      .post<ApiBaseResponse<Buyer>>(
        `${environment.apiUrl}/buyerUsers/Deactivate/`,
        {
          id: id,
          password: encPassword,
        }
      )
      .pipe(map((it) => String(it.message)));
  }

  ////// listing methods
  getAllUsers(paging: PagingModel): Observable<PageData<PlatformUsers[]>> {
    let param = new HttpParams();
    if (paging.isPaginated) {
      param = param.append('isPaginated', '' + paging.isPaginated);
      param = param.append('pageSize', '' + paging.pageSize);
      param = param.append('pageNumber', '' + paging.pageNumber);
    }
    if (paging.searchTerm != '' && paging.searchTerm != null)
      param = param.append('searchTerm', '' + paging.searchTerm);
    if (paging.status != '' && paging.status != null)
      param = param.append('status', '' + paging.status);
    if (paging.sortBy != '' && paging.sortBy != null)
      param = param.append('sortBy', '' + paging.sortBy);
    if (paging.sortOrder != '' && paging.sortOrder != null)
      param = param.append('sortOrder', '' + paging.sortOrder);
    if (paging.userType > 0)
      param = param.append('userType', '' + paging.userType);

    return this.http
      .get<ApiBaseResponse<PlatformUsers[]>>(
        `${environment.apiUrl}/buyerUsers/getAll`,
        {
          params: param,
        }
      )
      .pipe(
        map((it) => {
          if (paging.isPaginated) {
            return {
              data: it.data,
              totalCount: it.pagingInfo.totalCount,
            };
          }
          return {
            data: it.data,
            totalCount: null,
          };
        })
      );
  }
  userTypesDropdown(): Observable<CommonDropdown[]> {
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/buyerUsers/GetRegisteredUserTypes`
      )
      .pipe(map((it) => it.data));
  }
  markAsBadBidder(userId: number, reason: string): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/buyerUsers/MarkUnmarkAsBadBidder`,
        {
          userId: userId,
          reason: reason,
        }
      )
      .pipe(map((it) => it.data));
  }
  markAsAlwaysAppproved(userId: number): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/buyerUsers/MarkUnmarkAsAlwaysApproved`,
        {
          userId: userId,
        }
      )
      .pipe(map((it) => it.data));
  }
  changeStatusByAdmin(userId: number): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/buyerUsers/ActivateDeactivateByAdmin`,
        {
          userId: userId,
        }
      )
      .pipe(map((it) => it.data));
  }

  getAllMyRegistrations(model: any): Observable<PageData<any>> {
    let param = new HttpParams();
    if (model.isPaginated) {
      param = param.append('loggedInUser', '' + model.loggedInUser);
      param = param.append('searchTerm', '' + model.searchTerm);
      param = param.append('pageNumber', '' + model.pageNumber);
      param = param.append('pageSize', '' + model.pageSize);
      param = param.append('sortBy', '' + model.sortBy);
      param = param.append('sortOrder', '' + model.sortOrder);
      param = param.append('isPaginated', '' + model.isPaginated);
    }
    return this.http
      .get<ApiBaseResponse<any>>(
        `${environment.apiUrl}/buyerUsers/MyRegistrations`,
        { params: param }
      )
      .pipe(
        map((it) => {
          return {
            data: it.data,
            totalCount: it.pagingInfo.totalCount,
          };
        })
      );
  }

  getAllActiveBids(
    pageSize: number,
    pageNumber: number
  ): Observable<PageData<any>> {
    let param = new HttpParams();
    param = param.append('pageNumber', '' + pageNumber);
    param = param.append('pageSize', '' + pageSize);
    param = param.append('isPaginated', '' + true);

    return this.http
      .get<ApiBaseResponse<any>>(
        `${environment.apiUrl}/BuyerUsers/MyActiveBids`,
        { params: param }
      )
      .pipe(
        map((it) => {
          return {
            data: it.data,
            totalCount: it.pagingInfo.totalCount,
          };
        })
      );
  }

  getBidHistory(model: any): Observable<PageData<AdminBid[]>> {
    return this.http
      .post<ApiBaseResponse<AdminBid[]>>(
        `${environment.apiUrl}/Bid/GetBidHistory`,
        {
          auctionId: model.auctionFilter,
          bidderStatus: model.statusFilter,
          searchTerm: model.search,
          pageNumber: model.pageNumber,
          pageSize: model.pageSize,
          isPaginated: true,
        }
      )
      .pipe(
        map((it) => {
          return {
            data: it.data,
            totalCount: it.pagingInfo.totalCount,
          };
        })
      );
  }

  private encryptData(pass: string) {
    let encryptSecretKey = environment.encryptionKey;
    var keySize = 256;
    var salt = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.PBKDF2(encryptSecretKey, salt, {
      keySize: keySize / 32,
      iterations: 100,
    });
    var iv = CryptoJS.lib.WordArray.random(128 / 8);
    var encrypted = CryptoJS.AES.encrypt(pass, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    var result = CryptoJS.enc.Base64.stringify(
      salt.concat(iv).concat(encrypted.ciphertext)
    );
    return result;
  }
}

export interface PagingModel {
  userType: number;
  status: string | null;
  searchTerm: string;
  pageNumber: number;
  pageSize: number;
  sortBy: string | number;
  sortOrder: string;
  isPaginated: boolean;
  onlyActive: boolean;
}
