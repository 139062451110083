import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Country, ApiBaseResponse } from '../models';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private http: HttpClient) {}

  getCountries(): Observable<Country[]> {
    return this.http
      .get<ApiBaseResponse<Country[]>>(`${environment.apiUrl}/country/`)
      .pipe(map((it) => it.data));
  }
  getAllCountryNameFlagIsd():Observable<{ value: number; text: string }[]> {
    return this.http
      .get<ApiBaseResponse<{ value: number; text: string }[]>>(
        `${environment.apiUrl}/country/GetAllCountryNameFlagIsd`
      )
      .pipe(map((it) => it.data));
  }
  getAllCountryFlagIsd():Observable<{ value: number; text: string }[]> {
    return this.http
      .get<ApiBaseResponse<{ value: number; text: string }[]>>(
        `${environment.apiUrl}/country/GetAllCountryFlagIsd`
      )
      .pipe(map((it) => it.data));
  }
}
