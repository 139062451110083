export class Utils {
  static isDST(date) {
    // Get the timezone offset for January 1st of the current year (non-DST date)
    const janOffset = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();

    // Compare it with the offset of the provided date
    return date.getTimezoneOffset() < janOffset;
  }

  static toLocalDateTime(datetime) {
    const localDateTime = new Date(datetime);

    // Get the current timezone offset
    const currentOffset = -1 * new Date().getTimezoneOffset();

    // Check if DST is in effect for the given date
    if (Utils.isDST(localDateTime)) {
      // DST is in effect, adjust the offset
      // Assuming DST adjustment is typically 1 hour
      const dstAdjustment = 60;
      localDateTime.setMinutes(localDateTime.getMinutes() + currentOffset + dstAdjustment);
    } else {
      // No DST adjustment needed
      localDateTime.setMinutes(localDateTime.getMinutes() + currentOffset);
    }

    return localDateTime.toString();
  }
}
