import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { auctionLots } from '../models/auctionLots';
import {
  ApiBaseResponse,
  CommonDropdown,
  PageData,
  LotFromBlob,
} from '../models';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LotService {
  constructor(private http: HttpClient) {}

  getLotDataHandleOptions() {
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/lot/HanldeOldDataOptions`
      )
      .pipe(map((it) => it.data));
  }

  importLots(formData: FormData): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/lot/ImportLots`,
        formData
      )
      .pipe(map((it) => it.data));
  }

  // AuctionLot view
  getAuctionLot(paging: any): Observable<PageData<auctionLots[]>> {
    let param = new HttpParams();
    if (paging.isPaginated) {
      param = param.append('pageSize', '' + paging.pageSize);
      param = param.append('pageNumber', '' + paging.pageNumber);
      param = param.append('isPaginated', '' + paging.isPaginated);
      param = param.append('SearchTerm', '' + paging.searchTerm);
    }
    param = param.append('auctionId', '' + paging.auctionId);
    return this.http
      .get<ApiBaseResponse<auctionLots[]>>(
        `${environment.apiUrl}/Lot/GetLotsByAuction`,
        { params: param }
      )
      .pipe(
        map((it) => {
          if (paging.isPaginated) {
            return {
              data: it.data,
              totalCount: it.pagingInfo.totalCount,
            };
          }
          return {
            data: it.data,
            totalCount: null,
          };
        })
      );
  }

  deleteLot(lotId: number): Observable<any> {
    return this.http.post<ApiBaseResponse<any>>(
      `${environment.apiUrl}/lot/delete`,
      {
        id: lotId,
      }
    );
  }

  getLotsByAuction(
    auctionId: number,
    lotType: number = 1
  ): Observable<CommonDropdown[]> {
    let param = new HttpParams();
    if (auctionId) param = param.append('auctionId', '' + auctionId);
    if (lotType) param = param.append('addType', '' + lotType);
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/Lot/GetLotsByAuctionForDropdownList`,
        {
          params: param,
        }
      )
      .pipe(map((it) => it.data));
  }

  // AddLotModel <-- model for Body Data
  addUpdateLot(lotModal: any): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(`${environment.apiUrl}/Lot/AddEdit`, lotModal)
      .pipe(map((it) => it.data));
  }

  viewLotById(
    lotId: number,
    lotNumber?: string,
    auctionId?: number
  ): Observable<any> {
    let param = new HttpParams();
    if (lotId) param = param.append('LotId', '' + lotId);
    if (lotNumber) param = param.append('LotNumber', '' + lotNumber);
    if (auctionId) param = param.append('AuctionId', '' + auctionId);

    return this.http
      .get<ApiBaseResponse<any>>(`${environment.apiUrl}/Lot/GetById`, {
        params: param,
      })
      .pipe(map((it) => it.data));
  }

  deleteLotImage(lotModal): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Lot/DeleteLotImage`,
        lotModal
      )
      .pipe(map((it) => it.data));
  }

  // front-end list view
  getAllCurrentAuctionsLots(model: any): Observable<PageData<any>> {
    let param = new HttpParams();
    if (model.isPaginated) {
      param = param.append('lotTypes', '' + (model.lotTypes || 1));
      param = param.append('locations', '' + (model.locations ?? ''));
      param = param.append('categories', '' + (model.categories ?? ''));
      param = param.append('searchTerm', '' + model.searchTerm);
      param = param.append('pageNumber', '' + model.pageNumber);
      param = param.append('pageSize', '' + model.pageSize);
      param = param.append('sortBy', '' + model.sortBy);
      param = param.append('sortOrder', '' + model.sortOrder);
      param = param.append('isPaginated', '' + model.isPaginated);
      param = param.append('onlyActive', '' + model.onlyActive);
      param = param.append('isFromUrl', '' + model.isFromUrl);
    }
    param = param.append('skipFeatured', '' + model.skipFeatured);
    param = param.append('auctionId', '' + model.auctionId);
    return this.http
      .get<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Lot/GetLotsByAuctionPublic?`,
        { params: param }
      )
      .pipe(
        map((it) => {
          return {
            data: it.data,
            totalCount: it.pagingInfo.totalCount,
          };
        })
      );
  }

  getLotCategories(id) {
    return this.http
      .get<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Lot/GetCategoriesByAuctionLots?AuctionId=${id}`
      )
      .pipe(map((it) => it.data));
  }

  getLotLocation(id) {
    return this.http
      .get<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Lot/GetLocationsByAuctionLots?AuctionId=${id}`
      )
      .pipe(map((it) => it.data));
  }

  getLotConsignor(auctionId: number): Observable<string[]> {
    return this.http
      .get<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Lot/GetConsignorsByAuctionLots?AuctionId=${auctionId}`
      )
      .pipe(map((it) => it.data));
  }

  getLotDetail(lotId: number, auctionCode: string, lotNum: string) {
    let param = new HttpParams();
    if (lotId) param = param.append('lotId', '' + lotId);
    param = param.append('lotNumber', lotNum);
    param = param.append('auctionCode', auctionCode);

    return this.http
      .get<ApiBaseResponse<any>>(`${environment.apiUrl}/Lot/GetLotByIdPublic`, {
        params: param,
      })
      .pipe(map((it) => it.data));
  }

  downloadCSV(auctionId) {
    return this.http
      .get<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Lot/ExportLotsByAuction?AuctionId=${auctionId}`
      )
      .pipe(map((it) => it.data));
  }

  publishLots(model) {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Lot/PublishLotsOfAuction`,
        model
      )
      .pipe(map((it) => it.data));
  }

  ///////// for syncing
  getImagesFromBlob(auctionId: number): Observable<LotFromBlob[]> {
    return this.http
      .post<ApiBaseResponse<LotFromBlob[]>>(
        `${environment.apiUrl}/Lot/GetImagesFromBlob`,
        {
          auctionId: auctionId,
        }
      )
      .pipe(map((it) => it.data));
  }

  deleteImagesFromBlob(
    auctionId: number,
    imagePath: string
  ): Observable<LotFromBlob> {
    return this.http
      .post<ApiBaseResponse<LotFromBlob>>(
        `${environment.apiUrl}/Lot/DeleteImageFromBlob`,
        {
          auctionId: auctionId,
          path: imagePath,
        }
      )
      .pipe(map((it) => it.data));
  }

  uploadImagesToBlob(model: FormData): Observable<LotFromBlob> {
    return this.http
      .post<ApiBaseResponse<LotFromBlob>>(
        `${environment.apiUrl}/Lot/UploadImagesToblob`,
        model
      )
      .pipe(map((it) => it.data));
  }

  syncImages(auctionId: number): Observable<LotFromBlob> {
    return this.http
      .post<ApiBaseResponse<LotFromBlob>>(
        `${environment.apiUrl}/Lot/SyncImages`,
        {
          auctionId: auctionId,
        }
      )
      .pipe(map((it) => it.data));
  }

  removeImages(auctionId: number): Observable<LotFromBlob> {
    return this.http
      .post<ApiBaseResponse<LotFromBlob>>(
        `${environment.apiUrl}/Lot/CleanLotsImageDirectory`,
        {
          auctionId: auctionId,
        }
      )
      .pipe(map((it) => it.data));
  }

  getLotTypesDropDownList(): Observable<CommonDropdown[]> {
    return this.http
      .get<ApiBaseResponse<CommonDropdown[]>>(
        `${environment.apiUrl}/Lot/GetLotTypesDropDownList`
      )
      .pipe(map((it) => it.data));
  }

  getLotStatusDropDownList(): Observable<CommonDropdown[]> {
    return this.http
      .get<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Lot/GetLotStatusDropDownList`
      )
      .pipe(map((it) => it.data));
  }

  // AuctionLot view
  getAllAuctionLot(paging: any): Observable<PageData<any>> {
    let param = new HttpParams();
    if (paging.isPaginated) {
      param = param.append('pageSize', '' + paging.pageSize);
      param = param.append('pageNumber', '' + paging.pageNumber);
      param = param.append('isPaginated', '' + paging.isPaginated);
      param = param.append('SearchTerm', '' + paging.searchTerm);
    }
    param = param.append('lotType', '' + paging.lotType);
    param = param.append('auctionCategory', '' + paging.auctionCategory);
    param = param.append('auctionStatus', '' + paging.auctionStatus);
    param = param.append('sortBy', '' + paging.sortBy);
    param = param.append('sortOrder', '' + paging.sortOrder);
    param = param.append('onlyActive', '' + paging.onlyActive);
    return this.http
      .get<ApiBaseResponse<any>>(`${environment.apiUrl}/Lot/GetAllPublic`, {
        params: param,
      })
      .pipe(
        map((it) => {
          if (paging.isPaginated) {
            return {
              data: it.data,
              totalCount: it.pagingInfo.totalCount,
            };
          }
          return {
            data: it.data,
            totalCount: null,
          };
        })
      );
  }

  // AuctionLot view
  getAll(paging: any): Observable<PageData<any>> {
    let param = new HttpParams();
    if (paging.isPaginated) {
      param = param.append('pageSize', '' + paging.pageSize);
      param = param.append('pageNumber', '' + paging.pageNumber);
      param = param.append('isPaginated', '' + paging.isPaginated);
    }
    param = param.append('SearchTerm', '' + paging.searchTerm);
    param = param.append('lotType', '' + paging.lotType);
    param = param.append('lotStatus', '' + paging.lotStatus);
    param = param.append('auctionStatus', '' + paging.auctionStatus);
    return this.http
      .get<ApiBaseResponse<any>>(`${environment.apiUrl}/Lot/GetAll`, {
        params: param,
      })
      .pipe(
        map((it) => {
          if (paging.isPaginated) {
            return {
              data: it.data,
              totalCount: it.pagingInfo.totalCount,
            };
          }
          return {
            data: it.data,
            totalCount: null,
          };
        })
      );
  }

  // /api/Lot / PauseResumeBidding
  pauseResumeBidding(lotId: number): Observable<boolean> {
    return this.http
      .post<ApiBaseResponse<boolean>>(
        `${environment.apiUrl}/Lot/PauseResumeBidding?lotId=${+lotId}`,
        {}
      )
      .pipe(map((it) => it.data));
  }

  unpublishLots(auctionId: number): Observable<boolean> {
    return this.http
      .post<ApiBaseResponse<boolean>>(
        `${environment.apiUrl}/Lot/UnpublishLotsOfAuction`,
        {
          auctionId: +auctionId,
        }
      )
      .pipe(map((it) => it.data));
  }

  timeLeft(lotId: number): Observable<string> {
    return this.http
      .get<ApiBaseResponse<string>>(`${environment.apiUrl}/Lot/TimeLeftToBid`, {
        params: {
          lotId: '' + lotId,
        },
      })
      .pipe(map((it) => it.data));
  }

  markLotFeatured(lotId: number) {
    return this.http
      .patch<ApiBaseResponse<any>>(`${environment.apiUrl}/Lot/MarkAsFeatured`, {
        id: lotId,
      })
      .pipe(map((it) => it.data));
  }

  multiplePriceChange(
    auctionId: number,
    lotPriceArray: {
      id: number;
      lotType: number;
      openingPrice: string;
      reservePrice: string;
    }[]
  ): Observable<any> {
    let lotArray = lotPriceArray.map((i) => ({
      id: +i.id,
      lotType: +i.lotType,
      openingPrice: i.openingPrice === '' ? null : +i.openingPrice,
      reservePrice: i.reservePrice === '' ? null : +i.reservePrice,
    }));

    return this.http
      .patch<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Lot/UpdateMultipleLots`,
        {
          auctionId: auctionId,
          lots: lotArray,
        }
      )
      .pipe(map((it) => it.data));
  }

  // bulk lot APIs
  getAllBulkLots(auctionId: number): Observable<any> {
    return this.http
      .get<ApiBaseResponse<any>>(
        `${environment.apiUrl}/BulkLot/GetLotsByAuctionIdForSelection`,
        {
          params: {
            auctionId: '' + auctionId,
          },
        }
      )
      .pipe(map((it) => it.data));
  }

  getLotsForBulkLot(bulkLotId: number): Observable<any> {
    return this.http
      .get<ApiBaseResponse<any>>(`${environment.apiUrl}/BulkLot/GetLots`, {
        params: {
          bulkLotId: '' + bulkLotId,
        },
      })
      .pipe(map((it) => it.data));
  }

  removeLotFromBulk(lotId: number): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/BulkLot/RemoveFromBulkLot`,
        {
          lotId: lotId,
        }
      )
      .pipe(map((it) => it.data));
  }

  addLotToBulkLot(lotId: number, bulkLotId: number): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/BulkLot/AddToBulkLot`,
        {
          lotId: lotId,
          bulkLotId: bulkLotId,
        }
      )
      .pipe(map((it) => it.data));
  }

  isAnyBulkLotPresent(auctionId: number): Observable<boolean> {
    return this.http
      .get<ApiBaseResponse<boolean>>(
        `${environment.apiUrl}/Auction/IsBulkPresentInAuction`,
        {
          params: {
            auctionId: '' + auctionId,
          },
        }
      )
      .pipe(map((it) => it.data));
  }

  getLotImages(lotId: number): Observable<
    Array<{
      id: number;
      name: string;
      thumbnailName: string;
      order: number;
    }>
  > {
    return this.http
      .get<
        ApiBaseResponse<
          Array<{
            id: number;
            name: string;
            thumbnailName: string;
            order: number;
          }>
        >
      >(`${environment.apiUrl}/LotImage/GetImages`, {
        params: {
          lotId: '' + lotId,
        },
      })
      .pipe(map((it) => it.data));
  }
}

export interface AddLotModel {
  lotId: number;
  auctionId: number;
  lotNumber: string;
  lotType: number;
  description: string;
  location: string;
  category: string;
  consignor: string;
  openingPrice: number;
  quantity?: number;
  reservePrice?: number;
  lotNumberAfterWhichThisWillBeAdded?: number;
  modifier?: number;
  lots?: string;
  isUpdateAndPublish?: boolean;
}
