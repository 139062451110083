import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiBaseResponse } from '../models';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { EncrDecrService } from './encrDecrService.service';

@Injectable({
  providedIn: 'root',
})
export class AuctionDepositService {
  constructor(private http: HttpClient, private encryption: EncrDecrService) {}

  validateLink(payload: string): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/AuctionDeposit/IsLinkValid`,
        {
          encryptedPayload: payload,
        }
      )
      .pipe(map((it) => it.data));
  }

  submitForm(submitModel: any, payload: string): Observable<any> {
    let encryptedData = this.encryption.encryptObject(submitModel);
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/AuctionDeposit/Submit`,
        {
          submittedEncryptedPayload: encryptedData,
          providedEncryptedPayload: payload,
        }
      )
      .pipe(map((it) => it.data));
  }

  depositReceived(
    amount: number,
    comment: string,
    userId: number
  ): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/AuctionDeposit/MarkAsDepositReceived`,
        {
          auctionUserId: userId,
          amount: +amount,
          comment: comment,
        }
      )
      .pipe(map((it) => it.data));
  }

  submitUserPayment(submitModel: any): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/AuctionDeposit/SubmitForPayment`,
        submitModel
      )
      .pipe(map((it) => it.data));
  }
}
