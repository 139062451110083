import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthenticationService } from '../services';
import { ToastrService } from 'ngx-toastr';
import constants from '../utility/constants';
import { AppValidators } from '../core/validators/app-validators';
import {  Subscription } from 'rxjs';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  hash: any;
  resetPassword: FormGroup;
  loading: boolean = true;
  isLinkExpired: boolean = false;
  fieldMaxLength = constants.common.validations.maxLength50;
  isMatch: boolean;
  submitLoader: boolean = false;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.hash = params['token'];
      this.checkHash(this.hash);
    });

    this.resetPassword = new FormGroup(
      {
        newPassword: new FormControl('', [
          Validators.required,
          AppValidators.checkPassword,
          Validators.maxLength(this.fieldMaxLength),
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
          Validators.maxLength(this.fieldMaxLength),
        ]),
      },
      this.matchPassword.bind(this)
    );
  }

  checkHash(hash: string) {
    const validatePassword = this.authService.validatePasswordLink(hash).subscribe(
      (res: any) => {
        if (res.data == true) {
          this.loading = false;
        } else {
          this.loading = false;
          this.isLinkExpired = true;
        }
      },
      (err) => {
        this.toastr.error(err, constants.common.toast.errorTitle, constants.common.toast.config);
      }
    );
    this.subscriptions.push(validatePassword)
  }

  matchPassword(controls: FormControl): { [s: string]: boolean | null } {
    let pswd = controls.value.newPassword;
    let cpswd = controls.value.confirmPassword;
    if (pswd != null && cpswd != null) {
      if (pswd != cpswd || cpswd != pswd) {
        this.isMatch = false;
        return { passwordMismatch: true };
      }
    } else {
      this.isMatch = true;
      return null;
    }
  }

  onSubmit() {
    this.submitLoader = true;

    const resetPassword = this.authService
      .resetPassword(this.hash, this.resetPassword.value.newPassword)
      .subscribe(
        () => {
          this.toastr.success('Password updated successfully', constants.common.toast.successTitle, constants.common.toast.config);
          this.router.navigateByUrl('admin/login');
        },
        (err) => {
          this.toastr.error(err, constants.common.toast.errorTitle, constants.common.toast.config);
        }
      );
      this.subscriptions.push(resetPassword)
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
