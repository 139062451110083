import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiBaseResponse } from '../models';
import { map } from 'rxjs/operators';
import { SaleTypeStatics, UpcomingAuctions } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getNewRegistrations(): Observable<number> {
    return this.http
      .get<ApiBaseResponse<number>>(
        `${environment.apiUrl}/dashboard/GetNewRegistrations`
      )
      .pipe(map((it) => it.data));
  }

  getAuctionCount(auctionStatus: number): Observable<number> {
    return this.http
      .get<ApiBaseResponse<number>>(
        `${environment.apiUrl}/dashboard/GetAuctionCountByStatus`,
        {
          params: {
            AuctionStatus: '' + auctionStatus,
          },
        }
      )
      .pipe(map((it) => it.data));
  }

  getUpcomingAuctions(count: number): Observable<UpcomingAuctions[]> {
    return this.http
      .get<ApiBaseResponse<UpcomingAuctions[]>>(
        `${environment.apiUrl}/dashboard/GetUpcomingAuctions?Count=${count}`
      )
      .pipe(map((it) => it.data));
  }

  GetSaleTypeStatistics(): Observable<SaleTypeStatics[]> {
    return this.http
      .get<ApiBaseResponse<SaleTypeStatics[]>>(
        `${environment.apiUrl}/dashboard/GetSaleTypeStatistics`
      )
      .pipe(map((it) => it.data));
  }
}
