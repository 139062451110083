import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ControlMessagesComponent } from './component/control-messages/control-messages.component';
import { LoaderComponent } from './component/loader/loader.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { PaginatorComponent } from './component/paginator/paginator.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from '../core/core.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ImageViewerComponent } from './component/image-viewer/image-viewer.component';
import { ImagePreviewDirective } from './directive/image-preview.directive';
import { TimerCountdownComponent } from './component/timer-countdown/timer-countdown.component';
import { LotThumbnailDirective } from './directive/lot-thumbnail.directive';
import { NotificationCounterComponent } from './component/notification-counter/notification-counter.component';
import { NotificationsComponent } from './component/notifications/notifications.component';
import { AuctionDefaultImageDirective } from './directive/auction-default-image.directive';
import { ImgFallbackDirective } from './directive/img-fallback.directive';
import { EmailSettingComponent } from './component/email-setting/email-setting.component';

const components = [
  LoaderComponent,
  PaginatorComponent,
  ControlMessagesComponent,
  NotFoundComponent,
  ImageViewerComponent,
  ImagePreviewDirective,
  LotThumbnailDirective,
  TimerCountdownComponent,
  NotificationCounterComponent,
  NotificationsComponent,
  EmailSettingComponent,
  AuctionDefaultImageDirective,
  ImgFallbackDirective
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    MatMenuModule,
    RouterModule,
    MatIconModule,
    CoreModule,
    MatTooltipModule,
    MatTableModule,
    SlickCarouselModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  entryComponents: [ImageViewerComponent],
  exports: [
    ...components,
    NgxPaginationModule,
    SlickCarouselModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    CoreModule,
    MatTooltipModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
})
export class CommonSharedModule {}
