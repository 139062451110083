import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Announcement, ApiBaseResponse } from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  constructor(private http: HttpClient) {}

  changeActiveStatus(status: boolean): Observable<Announcement> {
    return this.http
      .patch<ApiBaseResponse<Announcement>>(
        `${environment.apiUrl}/announcement`,
        {
          status: status,
        }
      )
      .pipe(map((it) => it.data));
  }

  editAnnouncement(content: string): Observable<Announcement> {
    return this.http
      .post<ApiBaseResponse<Announcement>>(
        `${environment.apiUrl}/announcement`,
        {
          description: content,
        }
      )
      .pipe(map((it) => it.data));
  }

  getAnnouncements(): Observable<Announcement> {
    return this.http
      .get<ApiBaseResponse<Announcement>>(`${environment.apiUrl}/announcement`)
      .pipe(map((it) => it.data));
  }
}
