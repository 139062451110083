import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import constants from '../../../utility/constants';
import { ModuleService } from '../../module.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class IncrementGuard implements CanLoad, CanActivate {
  constructor(
    private ms: ModuleService,
    private router: Router,
    private toast: ToastrService
  ) {}

  canLoad():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.shouldLoadOrActivate();
  }

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.shouldLoadOrActivate();
  }

  private shouldLoadOrActivate() {
    if (this.ms.userHas(constants.admin.modules.bidIncrement)) return true;
    this.toast.info("No sufficient permission to access 'Increment Table(s)'");
    this.router.navigate(['/admin/dashboard']);
    return false;
  }
}
