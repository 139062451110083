import { Pipe, PipeTransform } from '@angular/core';
import { AuctionLocation } from '../../models';

@Pipe({
  name: 'locationNames'
})
export class LocationFormatPipe implements PipeTransform {
  transform(auctionLocations: AuctionLocation[] | null): string {
    let locationNameString: string="";

    if (auctionLocations == null) return locationNameString;

    if (auctionLocations.length > 0) {
      for (let index = 0; index < auctionLocations.length; index++) {
        locationNameString += auctionLocations[index].name;
        if (index + 1 != auctionLocations.length)
          locationNameString += ', ';
      }
      return locationNameString;
    }
    return locationNameString;
  }
}
