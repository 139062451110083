import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css'],
})
export class ImageViewerComponent implements OnInit {
  images: Array<{ id: number; name: string }>;
  thumbnails: Array<{ id: number; name: string }>;
  currentImage: { id: number; name: string };
  heading: string;
  lotData: { lotNumber: string; lotDesc: string };

  imagesSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    dots: false,
    cssEase: 'ease-in-out',
    asNavFor: '.view-more-slider .thumbs',
    fade: true,
    adaptiveHeight: true,
    lazyLoad: 'ondemand',
  };

  thumbnailWoCenterSlider = {
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    dots: false,
    cssEase: 'ease-in-out',
    asNavFor: '.view-more-slider .image-slides2',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  thumbnailsSlider = {
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: false,
    arrows: false,
    dots: false,
    cssEase: 'ease-in-out',
    asNavFor: '.view-more-slider .image-slides2',
    centerMode: true,
    centerPadding: '50px',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  currentSlide: number = 0;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.images = this.selectedImageSlider(this.images);
    this.thumbnails = this.selectedImageSlider(this.thumbnails);
  }

  slickInit(slideEvent) {
    slideEvent.slick.$slider.find('img').first().on('load', function () {
      window.dispatchEvent(new Event('resize'));
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  selectedImageSlider(
    photos: Array<{ id: number; name: string }>
  ): Array<{ id: number; name: string }> {
    if (this.currentImage == null) {
      return photos;
    } else {
      let currentImage = photos.find((i) => i.id === this.currentImage.id);
      let currentImageIndex = photos.findIndex((i) => i.id === this.currentImage.id);
      let leftImgArr,rightImgArr = [];
      if(currentImageIndex > -1)
      {
         leftImgArr = photos.slice(0,currentImageIndex);
         rightImgArr = photos.slice(currentImageIndex + 1);
      }

      return [currentImage, ...rightImgArr, ...leftImgArr];
    }
  }

  onSlideChange(events) {
    this.currentSlide = events.currentSlide;
  }
}
