<section class="mobile-logo-zone d-block d-md-none">
  <div class="logo-wrap">
    <a [routerLink]="['/admin', 'login']">
      <img src="../assets/images/the-branford-logo.svg" class="logo" />
    </a>
  </div>
</section>

<section class="form-page-wrapper">
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-md-5 p-0 d-none d-md-block">
        <div class="login-sidebar">
          <img src="../assets/images/bg_image.jpg" class="img-fluid side-img" />
          <div class="brand-gp-text">
            <div class="text-inner">
              <h4 class="main-hdg mb-3">
                The <span>Branford Group</span> is a recognized leader in
                surplus industrial machinery and equipment auctions and
                valuations.
              </h4>
              <p class="des m-0">
                The Branford Group<br />Equipment Auctions Worldwide
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 p-0">
        <div class="login-form-wrap">
          <div class="brand-gp-text d-none">
            <div class="text-inner">
              <h4 class="main-hdg mb-3">
                The <span>Branford Group</span> is a recognized leader in
                surplus industrial machinery and equipment auctions and
                valuations.
              </h4>
              <p class="des m-0">
                The Branford Group<br />Equipment Auctions Worldwide
              </p>
            </div>
          </div>

          <div class="logo-wrap">
            <a [routerLink]="['/admin', 'login']">
              <img src="../assets/images/the-branford-logo.svg" class="logo" />
            </a>
          </div>

          <div class="d-flex justify-content-center" *ngIf="loading">
            <div
              class="spinner-grow"
              style="width: 5rem; height: 5rem"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div *ngIf="isLinkExpired">
            <p class="alert alert-danger">Your Link is expired</p>
          </div>

          <div *ngIf="!loading && this.isLinkExpired == false">
            <div class="login-form-box">
              <div class="login-form-inner">
                <h4 class="form-hdg m-0">Reset Password</h4>
                <p class="form-sb-hdg">Enter new password for your account</p>
                <div class="form-body">
                  <form (ngSubmit)="onSubmit()" [formGroup]="resetPassword">
                    <div class="form-group mb-4">
                      <mat-form-field appearance="outline">
                        <mat-label>New Password</mat-label>
                        <input
                          matInput
                          type="password"
                          placeholder="Enter Password"
                          formControlName="newPassword"
                          [maxlength]="fieldMaxLength"
                        />
                      </mat-form-field>
                      <control-messages
                        [control]="resetPassword.controls.newPassword"
                      ></control-messages>
                    </div>

                    <div class="form-group mb-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Confirm Password</mat-label>
                        <input
                          matInput
                          placeholder="Confirm Password"
                          type="password"
                          formControlName="confirmPassword"
                          [maxlength]="fieldMaxLength"
                        />
                      </mat-form-field>
                      <control-messages
                        [control]="resetPassword"
                      ></control-messages>
                    </div>

                    <button
                      class="btn btn-colored w-100"
                      [disabled]="resetPassword.invalid || submitLoader"
                      type="submit"
                    >
                      <span
                        *ngIf="submitLoader"
                        class="spinner-border spinner-border-sm mr-1"
                      ></span>
                      Reset Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
