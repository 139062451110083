<pagination-template #p="paginationApi" [id]="paginationId" maxSize="5" (pageChange)="selectPage($event)">
  <div class="pagination__outer__wrapper justify-content-between"
    [ngClass]="{'flex-row-reverse': ( pageSizes === null || pageSizes?.length === 0)}">
    <ng-container *ngIf="pageSizes && pageSizes.length > 0">
      <div>
        <div class="pagination-input-field-wrapper m-0 d-flex align-items-center">
          <span>Page size</span>
          <div class="auction-search mb-0 pl-2 float-left">
            <div class="form-group m-0 no-float-fields" style="width: 90px;">
              <mat-select [value]="pageSize" (selectionChange)="selectPageSize($event.value)"
                placeholder="Select page size">
                <mat-option *ngFor="let size of pageSizes" [value]="size">{{ size }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row align-items-center mx-0 px-0">
      <div class="row">
        <div class="col-auto ml-auto">
          <div class="pagination">
            <div class="pagination-prev-next" [class.disabled]="p.isFirstPage()">
              <button *ngIf="!p.isFirstPage()" role="button" (click)="p.previous()" class="pagination-prev-next">
                Prev
              </button>
            </div>
            <div class="pagination-number" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
              <button role="button" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </button>
              <div *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>
            <div class="pagination-prev-next" [class.disabled]="p.isLastPage()">
              <button *ngIf="!p.isLastPage()" role="button" (click)="p.next()" class="pagination-prev-next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <form #pageForm="ngForm" (ngSubmit)="selectPage(pageForm?.value?.page); pageForm.reset()">
        <div class="pagination-input-field-wrapper">
          <span>Go to page</span>
          <input type="number" min="1" [max]="p.getLastPage()" name="page" ngModel required />
          <button class="btn-colored" type="submit" [disabled]="
              !pageForm.valid ||
              pageForm?.value?.page > p.getLastPage() ||
              pageForm?.value?.page < 1
            ">
            Go
          </button>
        </div>
      </form>
    </div>
  </div>
</pagination-template>
