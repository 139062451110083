<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <a routerLink="/">
            <img src="../assets/images/the-branford-logo.svg" class="logo" />
          </a>
        </div>

        <div class="row text-center justify-content-center">
          <div class="col-sm-12 col-md-6">
            <div class="white-wrap-admin p-0">
              <ng-container *ngIf="!isUserSubmittedPassword; else showNewsLetterOptions">
                <div class="login-form-wrap" style="min-height: 0;">
                  <div class="login-form-box my-0">
                    <div>
                      <h4 class="form-hdg m-0">Unsubscribe to email updates</h4>
                      <p class="form-sb-hdg">Enter your password to authenticate</p>
                      <div class="form-body">
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                          <div class="form-group">
                            <mat-form-field appearance="outline">
                              <mat-label>Password</mat-label>
                              <input matInput [type]="hide ? 'password' : 'text'" maxlength="15"
                                formControlName="password" placeholder="Password" autocomplete="current-password" />
                              <mat-icon class="cursor-pointer" matSuffix role="button" (click)="hide = !hide">{{ hide ?
                                "visibility_off" : "visibility" }}</mat-icon>
                            </mat-form-field>
                            <control-messages [control]="loginForm.controls.password"></control-messages>
                          </div>

                          <button [disabled]="loading || loginForm.invalid" class="btn btn-colored w-100">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Submit
                          </button>
                          <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
                            {{ error }}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #showNewsLetterOptions>
                <div class="p-5">
                  <h2 class="white-wrap-hdg mb-2 mb-md-4 pb-2">Notification Settings</h2>
                  <div class="notification-list-outer">
                    <ul class="notification-list border-dotted-top mb-4">
                      <li>
                        <span class="notification-name">{{ isEmailNotification ? "Disable" : "Enable"}} Email
                          Notifications</span>
                        <label class="switch-slider">
                          <input type="checkbox" class="form-check-input1 slide-check-input"
                            [value]="isEmailNotification" [checked]="isEmailNotification"
                            (change)="saveEmailSetting($event)" />
                          <span class="slider round"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <h2 *ngIf="showHide" class="noti-hdg2 mb-3 pt-2">Receive Updates </h2>
                  <div *ngIf="showHide" class="notification-list-outer">
                    <ul *ngIf="notificationList && notificationList.length" class="notification-list">

                      <li *ngFor="let list of notificationList">
                        <span class="notification-name">{{ list.emailName }}</span>
                        <label class="switch-slider">
                          <input type="checkbox" class="form-check-input1 slide-check-input" [checked]="list.isActive"
                            [value]="list.emailId" [disabled]="!showHide"
                            (change)="saveSpecificEmailSetting($event,list)" />
                          <span class="slider round"></span>
                        </label>
                      </li>


                    </ul>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
