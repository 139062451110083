import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Banner, ApiBaseResponse ,LotFromBlob,} from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(private http: HttpClient) { }

  getBanners(): Observable<Banner[]> {
    return this.http
      .get<ApiBaseResponse<Banner[]>>(`${environment.apiUrl}/banner`)
      .pipe(map((it) => it.data));
  }

  // 'banner' parameter is any coz it is a multipart/formdata
  AddBanner(banner: any): Observable<Banner> {
    return this.http
      .post<ApiBaseResponse<Banner>>(
        `${environment.apiUrl}/banner`,
        banner
      )
      .pipe(map((it) => it.data));
  }

  // 'banner' parameter is any coz it is a multipart/formdata
  updateBanner(banner: any): Observable<Banner> {
    return this.http
      .post<ApiBaseResponse<Banner>>(
        `${environment.apiUrl}/banner/UpdateBanner`,
        banner
      )
      .pipe(map((it) => it.data));
  }

  getBanner(id: number): Observable<Banner> {
    return this.http
      .get<ApiBaseResponse<Banner>>(`${environment.apiUrl}/banner/${id}`)
      .pipe(map((it) => it.data));
  }

  deleteBanner(id: number): Observable<Banner> {
    return this.http
      .post<ApiBaseResponse<Banner>>(
        `${environment.apiUrl}/banner/${id}`, {
          id: id,
        }
      )
      .pipe(map((it) => it.data));
  }

  deleteBannerLogo(id: number): Observable<Banner> {
    return this.http
      .delete<ApiBaseResponse<Banner>>(
        `${environment.apiUrl}/banner/DeleteLogo/${id}`
      )
      .pipe(map((it) => it.data));
  }

  changeBannerStatus(bannerId: number): Observable<Banner> {
    return this.http
      .post<ApiBaseResponse<Banner>>(
        `${environment.apiUrl}/banner/ChangeStatus`,
        {
          id: bannerId,
        }
      )
      .pipe(map((it) => it.data));
  }

  getRandomizeSetting(): Observable<boolean> {
    return this.http.get<ApiBaseResponse<boolean>>(`${environment.apiUrl}/BannerSetting`).pipe(map((it) => it.data));
  }

  setRandomizeSetting(): Observable<boolean> {
    return this.http.post<ApiBaseResponse<boolean>>(`${environment.apiUrl}/BannerSetting/ChangeRandamize`, {}).pipe(map((it) => it.data));
  }

  // public side banner listing
  getBannersCarousel(): Observable<Banner[]> {
    return this.http
      .get<ApiBaseResponse<Banner[]>>(
        `${environment.apiUrl}/banner/GetAllForPublic`
      )
      .pipe(map((it) => it.data));
  }
  SyncBannerImages(auctionId: number): Observable<LotFromBlob> {
    
    return this.http
      .post<ApiBaseResponse<LotFromBlob>>(
        `${environment.apiUrl}/banner/SyncBannerImages`,
        {
          auctionId: auctionId,
        }
      )
      .pipe(map((it) => it.data));
  }

  changeMemberPosition(
    userId: number,
    fromPos: number,
    toPos: number
  ): Observable<Banner[]> {
    return this.http
      .post<ApiBaseResponse<Banner[]>>(
        `${environment.apiUrl}/banner/PositionChange`,
        {
          id: userId,
          from: fromPos,
          to: toPos,
        }
      )
      .pipe(map((it) => it.data));
  }
  
}
