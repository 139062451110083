export default {
  auctionListing: {
    markAsSold: 'Auction marked as sold',
    markItFeatured: 'Auction marked as featured',
    markItUnFeatured: 'Auction remove from featured',
    auctionStopRegistration: 'Auction registration stopped successfully',
    auctionStartRegistration: 'Auction registration enabled successfully',
    approveAuction: 'Auction approved successfully',
    deleteAuction: 'Auction has been deleted',
    deActivateAuction: 'Auction deactivated successfully',
    activateAuction: 'Auction activated successfully',
    stopSyncRegistrationWithOtherAuction: 'Auction common registration stopped',
    startSyncRegistrationWithOtherAuction:
      'Auction common registration started',
  },
  addUser: {
    roles: 'Could not load roles.',
    countries: 'Could not load countries.',
    users: 'Could not load user.',
  },
  addAuction1: {
    saved: 'Step 1 saved successfully',
    updated: 'Step 1 updated successfully',
  },
  addAuction2: {
    image: 'Image removed successfully',
    saved: 'Step 2 saved successfully',
    updated: 'Step 2 updated successfully',
  },
  addAuction3: {
    fileUpload: 'File uploaded successfully',
    fileRemoved: 'File removed successfully',
    saved: 'Step 3 saved successfully',
    updated: 'Step 3 updated successfully',
    addDocument: 'Add Document',
    addAnotherDocument: 'Add Another Document',
    addNewButton: 'Add New Button',
    addAnotherButton: 'Add Another Button',
  },
  addAuction4: {
    saved: 'Step 4 saved successfully',
    updated: 'Auction updated successfully',
  },
  catagoryForm: {
    updated: 'Category Updated Successfully',
    added: 'Category Added Successfully',
  },
  forgetPassword: {
    emailSent: 'Email sent, check your email',
  },
  resendCode: {
    otpSent: 'OTP sent successfully',
  },
  addBlog: {
    updated: 'News updated successfully',
    added: 'New News added successfully',
    deleted: 'News deleted successfully',
  },
  announcement: {
    updated: 'Announcement updated successfully.',
    status: "Announcement's status updated successfully.",
  },
  bannerList: {
    deleted: 'Banner deleted successfully.',
    logoDeleted: 'Client Logo deleted successfully',
  },
  cmsEdit: {
    pageNotLoad: 'Could not load content page.',
    updated: 'Content page updated successfully.',
  },
  emailTemplateEdit: {
    updated: 'Template updated successfully',
  },
  teamManagementAdd: {
    updated: 'Member updated successfully',
    added: 'Member added successfully',
  },
  teamManagementList: {
    deleted: 'Team member deleted successfully.',
  },
  changePassword: {
    changeSuccess: 'Password Changed Successfully',
  },
  editProfile: {
    updated: 'Profile updated successfully',
    notLoaded: 'User not loaded',
  },
  manageAccount: {
    statusChange: 'Status changed successfully',
    methodChange: 'Verification method changed successfully',
  },
  saleType: {
    added: 'Sale type added successfully',
  },
  publicHome: {
    activated: 'Your account has been activated. Please login to continue',
    linkExipred: 'Reset password link has expired',
  },
  register: {
    status: 'You have registered successfully',
  },
  resetPassword: {
    status: 'Your Password has been reset successfully',
  },
  lot: {
    imported: 'File uploaded successfully',
    deleted: 'Lot deleted successfully',
  },
  csvFile: {
    onlyCsvFileAllowed: 'Only .Csv file is allowed',
    inValid: 'CSV file is invalid',
  },
};
