import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import constants from "../../../utility/constants";
import { ModuleService } from "../../module.service";

@Injectable()
export class SaleTypeGuard implements CanLoad, CanActivate {

    constructor(private ms: ModuleService, private router: Router) {}

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.shouldLoardOrActivate();
    }

    canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.shouldLoardOrActivate();
    }

    private shouldLoardOrActivate() {
        if (this.ms.userHas(constants.admin.modules.saleTypeManagement))
            return true;
        this.router.navigate(['/admin/not-found']);
        return false;
    }
}
