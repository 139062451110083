<div>
  <div style="position: relative; top: 0">
    <div id="editor{{ createdId }}"></div>

    <ng-container *ngIf="showEditor">
      <div *ngIf="showHtmlInEditor" class="editor-html-preview">
        {{ previewContent }}
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="showEditor">
    <div class="p-1 editor-footer">
      <small class="show-btn" role="button" (click)="toggleShowHtml()">{{
        !showHtmlInEditor ? "show html" : "showeditor"
      }}</small>
      <small>
        <span *ngIf="charLimit" [ngClass]="{ 'limit-over': charsLeft < 0 }">
          {{
            charsLeft < 0
              ? "character limit exceeded by " + charsLeft
              : charUsed + "
            / " + charLimit
          }}
        </span>
      </small>
    </div>
  </ng-container>
</div>
