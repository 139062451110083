import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { User, ApiBaseResponse, PageData } from '../models';
import { map } from 'rxjs/operators';
import constants from '../utility/constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  add(ro: AddUserRequest): Observable<User> {
    return this.http
      .post<ApiBaseResponse<User>>(`${environment.apiUrl}/user`, ro)
      .pipe(map((it) => it.data));
  }

  update(ro: UpdateUserRequest): Observable<User> {
    return this.http
      .post<ApiBaseResponse<User>>(`${environment.apiUrl}/user/Update`, ro)
      .pipe(map((it) => it.data));
  }

  getUsers(
    active: string = null,
    role: string = null,
    search: string = '',
    pageNum: number = 1,
    pageSize: number = constants.admin.adminPageSize
  ): Observable<PageData<User[]>> {
    let activeStatus = null;
    if (active != null) activeStatus = active == 'true';

    return this.http
      .post<ApiBaseResponse<User[]>>(`${environment.apiUrl}/user/list`, {
        isActive: activeStatus,
        roleId: +role || null,
        searchTerm: search,
        pageNumber: pageNum,
        pageSize: pageSize,
        sortBy: '',
        sortOrder: '',
        isPaginated: true,
        onlyActive: false,
      })
      .pipe(
        map((it) => {
          return {
            data: it.data,
            totalCount: it.pagingInfo.totalCount,
          };
        })
      );
  }

  get(userId: number): Observable<User> {
    return this.http
      .get<ApiBaseResponse<User>>(`${environment.apiUrl}/user/${userId}`)
      .pipe(map((it) => it.data));
  }

  changeActiveStatus(id: number, activeStatus: boolean): Observable<User> {
    return this.http
      .patch<ApiBaseResponse<User>>(`${environment.apiUrl}/user`, {
        userId: id,
        status: activeStatus,
      })
      .pipe(map((it) => it.data));
  }

  enableDisableMfa(id: number, mfaChange: boolean) {
    return this.http
      .post<ApiBaseResponse<User>>(`${environment.apiUrl}/User/EnableDisableMfa`, {
        userId: id,
        isMfaEnable: mfaChange,
      })
      .pipe(map((it) => it.data));
  }

  changeMfaOption(id: number, mfaStatus: string = "email" || "sms") {
    let isMfaWithEmail: boolean = false;
    let isMfaWithSms: boolean = false;
    if (mfaStatus == 'sms') isMfaWithSms = true;
    if (mfaStatus == 'email') isMfaWithEmail = true;

    return this.http
      .post<ApiBaseResponse<User>>(`${environment.apiUrl}/User/ChangeMfaOption`, {
        userId: id,
        isMfaWithSms: isMfaWithSms,
        isMfaWithEmail: isMfaWithEmail,
      })
      .pipe(map((it) => it.data));
  }
}

interface AddUserRequest {
  name: string;
  email: string;
  roleId: number;
  paddleNumber: string;
  mobileNumber: string;
  address: string;
  isMfaWithSms: boolean;
  isMfaWithEmail: boolean;
  countryId: number;
}

interface UpdateUserRequest {
  userId: number;
  name: string;
  roleId: number;
  paddleNumber: string;
  mobileNumber: string;
  address: string;
  isMfaWithSms: boolean;
  isMfaWithEmail: boolean;
  countryId: number;
}
