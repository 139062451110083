import { Component, Input, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { CommonDropdown } from '../../../../models';

@Component({
  selector: 'filter-control',
  templateUrl: './filter-control.component.html',
  styleUrls: ['./filter-control.component.css']
})
export class FilterControlComponent implements AfterViewInit {
  @Input('control') control: any;
  @Input('title') placeholder: string;
  @Output('currentValue') currentFilter = new EventEmitter<number>();
  @Input('filters') filters: Array<CommonDropdown>;
  @Input() isAll: boolean = false;

  ngAfterViewInit() {

  }

  onFilterClick(value: number) {
    this.currentFilter.emit(value);
  }
}
