import { Pipe, PipeTransform } from '@angular/core';

export interface BulkLots {
  id: number;
  lotNumber: string;
}

@Pipe({
  name: 'bulkLots'
})
export class BulkLotsPipe implements PipeTransform {

  transform(value: BulkLots[]): string {
    if (value && value.length) {
      return value.map((lot) => (lot.lotNumber)).join(', ')
    }
    return null;
  }

}
