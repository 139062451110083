import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiBaseResponse, PageData, webNotify } from '../models';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebNotificationService {

  constructor(private http: HttpClient) { }

  getLatestNoOfNotifications(count: number): Observable<Array<webNotify>> {
    let param = new HttpParams();
    param = param.append('pageSize', '' + count);
    param = param.append('pageNumber', '' + 1);
    param = param.append('isPaginated', '' + true);

    return this.http
      .get<ApiBaseResponse<Array<webNotify>>>(
        `${environment.apiUrl}/Notification/GetAll`, { params: param }
      )
      .pipe(map((it) => (it.data)));
  }

  getAllNotifications(pageSize: number, pageNumber: number, isPaginated: boolean = true): Observable<PageData<Array<webNotify>>> {
    let param = new HttpParams();
    param = param.append('pageSize', '' + pageSize);
    param = param.append('pageNumber', '' + pageNumber);
    param = param.append('isPaginated', '' + isPaginated);

    return this.http
      .get<ApiBaseResponse<Array<webNotify>>>(
        `${environment.apiUrl}/Notification/GetAll`, { params: param }
      )
      .pipe(map((it) => ({
        data: it.data,
        totalCount: it.pagingInfo.totalCount,
      })));
  }

  markNotifyAsRead(notifyId: number): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(`${environment.apiUrl}/Notification/MarkAsRead`, {
        userNotificationId: notifyId
      })
      .pipe(map((it) => it.data));
  }

  markAllNotifyAsRead(): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(`${environment.apiUrl}/Notification/MarkAllAsRead`, {})
      .pipe(map((it) => it.data));
  }
}
