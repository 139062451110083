import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css'],
})
export class PaginatorComponent implements OnInit {
  @Input('pageSize') pageSize: number;
  @Input('currentPage') currentPage: number;
  @Input('paginationId') paginationId: any;
  @Input('pageSizes') pageSizes: Array<number> = [];
  @Output('page') page = new EventEmitter<number>();
  @Output('selectedPageSize') selectedPageSize = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  selectPage(page): void {
    this.currentPage = page;
    this.page.emit(page);
  }

  selectPageSize(pageSize): void {
    this.pageSize = +pageSize;
    this.selectedPageSize.emit(+pageSize);
  }
}
