import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiBaseResponse } from '../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor(private http: HttpClient) {}

  getCurrency(): Observable<Currency[]> {
    return this.http
      .get<ApiBaseResponse<Currency[]>>(`${environment.apiUrl}/currency`)
      .pipe(map((it) => it.data));
  }
}

interface Currency {
  text: string;
  value: number;
}
