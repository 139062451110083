import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Role, ApiBaseResponse } from '../models';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private http: HttpClient) {}

  getAllRoles(): Observable<Role[]> {
    return this.http
      .get<ApiBaseResponse<Role[]>>(`${environment.apiUrl}/role`)
      .pipe(map((it) => it.data));
  }
}
