import { DOCUMENT } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  Inject,
} from '@angular/core';
import { environment } from '../../../../../environments/environment';
declare var ClassicEditor;

@Component({
  selector: 'editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
})
export class EditorComponent implements OnInit {
  @Input('placeholder') placeholder: string | null = null;
  @Input('initialValue') value: string = '';
  @Input('imageUploadUrl') imageUrl: string | null = null;
  @Input('characterLimit') charLimit: number | null = null;
  @Output('editorData') valueGoOut = new EventEmitter<{
    data: string;
    limitReached: boolean;
  }>();

  config: Object;
  showEditor: boolean;
  private serverUrl: string;
  showHtmlInEditor: boolean = false;
  previewContent: string;

  charsLeft: number;
  charUsed: number;
  private limitReached: boolean = null;

  createdId: string;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.serverUrl = environment.serverUrl;
    this.createdId = this.newGuid();
  }

  private newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  ngOnInit(): void {
    this.createConfig();
  }

  private loadEditor() {
    let id = `#editor${this.createdId}`;
    ClassicEditor.create(this.document.querySelector(id), this.config).then(
      (editor) => {
        this.showEditor = true;
        if (this.value) editor.setData(this.value);
        this.editorContent(this.value);
        editor.model.document.on('change', () => {
          this.editorContent(editor.getData());
        });
      }
    );
  }

  private createConfig() {
    this.showEditor = false;

    this.previewContent = this.value;

    this.config = {
      placeholder: this.placeholder ? this.placeholder : '',
      toolbar: {
        items: [
          'heading',
          '|',
          'fontFamily',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          'removeFormat',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
          'alignment',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'blockQuote',
          'code',
          'codeBlock',
          'link',
          'specialCharacters',
          'htmlEmbed',
          '|',
          'indent',
          'outdent',
          '|',
          'undo',
          'redo',
          '|',
        ],
        shouldNotGroupWhenFull: true,
      },
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
      },
      alignment: {
        options: ['left', 'right', 'center', 'justify'],
      },
      indentBlock: {
        offset: 1,
        unit: 'em',
      },
      fontSize: {
        options: [9, 11, 13, 'default', 17, 19, 21],
      },
      htmlEmbed: {
        showPreviews: true,
      },
      wordCount: {
        onUpdate: (stats) => {
          this.wordCounter(stats);
        },
      },
    };

    if (this.imageUrl != null) {
      this.config['toolbar'].items.push('imageUpload');
      this.config['image'] = {
        styles: ['alignLeft', 'alignCenter', 'alignRight'],
        resizeOptions: [
          {
            name: 'imageResize:original',
            label: 'Original',
            value: null,
          },
          {
            name: 'imageResize:50',
            label: '50%',
            value: '50',
          },
          {
            name: 'imageResize:75',
            label: '75%',
            value: '75',
          },
        ],
        toolbar: [
          'imageStyle:alignLeft',
          'imageStyle:alignCenter',
          'imageStyle:alignRight',
          '|',
          'imageResize',
          '|',
          'imageTextAlternative',
        ],
      };
      this.config['simpleUpload'] = {
        uploadUrl: this.serverUrl + this.imageUrl,
      };
    }

    setTimeout(() => {
      this.loadEditor();
    }, 500);
  }

  wordCounter(value) {
    if (this.charLimit) {
      this.charUsed = value.characters;
      this.charsLeft = this.charLimit - this.charUsed;
      this.limitReached = this.charsLeft < 0;
    }
  }

  toggleShowHtml() {
    this.showHtmlInEditor = !this.showHtmlInEditor;
  }

  editorContent(content) {
    if (content !== undefined) {
      this.previewContent = content;
      content == ''
        ? this.valueGoOut.emit({ data: null, limitReached: null })
        : this.valueGoOut.emit({
            data: content,
            limitReached: this.limitReached,
          });
    }
  }
}
