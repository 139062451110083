import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizeStringPipe implements PipeTransform {

  transform(value: number | string): string {
    if (typeof value === 'string')
      value = +value;
    if (value == null || value == 0 || value == undefined) return null;
    let result: number | string = value / (1024 * 1024);
    (result % 1 == 0) ? result : result = (+result.toFixed(3)).toString();
    return result + ' MB';
  }

}
