import { Directive, Input, HostBinding, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[LotThumbnail]',
})
export class LotThumbnailDirective implements AfterViewInit {
  @Input() lotNumber: string;
  @Input('lotImages') actualImages: Array<{ id: number; name: string }> = null;
  @Input('lotImage') actualImage: string = null;

  @HostBinding('src') imageSrc: string;

  ngAfterViewInit() {
    if (this.actualImages && this.actualImages.length > 0) {
      this.imageSrc =
        this.actualImages && this.actualImages.length > 0
          ? this.actualImages[0].name
          : '/assets/images/default_bg_img.jpg';
    } else if (this.actualImage) {
      this.imageSrc = this.actualImage
        ? this.actualImage
        : '/assets/images/default_bg_img.jpg';
    } else {
      this.imageSrc = '/assets/images/default_bg_img.jpg';
    }
  }
}
