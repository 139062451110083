import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import routeTable from '../../../utility/routeTable';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { environment } from '../../../../environments/environment';
import { webNotify } from '../../../models';
import {
  AuthenticationService,
  WebNotificationService,
} from '../../../services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import constants from '../../../utility/constants';
import { MatMenuTrigger } from '@angular/material/menu';
import {  Subscription } from 'rxjs';
@Component({
  selector: '[notification-counter]',
  templateUrl: './notification-counter.component.html',
  styleUrls: ['./notification-counter.component.css'],
})
export class NotificationCounterComponent implements OnInit, OnDestroy {
  @Input() isAdminBar: boolean = false;
  @ViewChild('notifyMenu', { static: false }) notifyMenu: MatMenuTrigger;

  user: any;

  routes = routeTable.public;
  private notificationHub: HubConnection;
  private hubUrl: string = environment.serverUrl;
  isConnected: boolean = false;
  private subscriptions: Array<Subscription> = [];

  notificationCount: number = constants.common.notificationCounter;

  topNotifications: Array<webNotify> = [];
  notificationLoader: boolean = false;
  unreadNotify: number;

  constructor(
    private authService: AuthenticationService,
    public router: Router,
    private webNotification: WebNotificationService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.hubUrl =
      this.hubUrl +
      (this.isAdminBar
        ? environment.hubs.adminNotification
        : environment.hubs.notification);

    this.user = this.isAdminBar
      ? this.authService.currentUserValue()?.user
      : this.authService.currentBuyerValue()?.user;

    if (this.user) this.buildConnection();
  }

  buildConnection() {
    this.notificationHub = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withUrl(this.hubUrl)
      .withAutomaticReconnect([2000, 4000])
      .build();

    this.registerHubEvents();

    this.startConnection();
  }

  private startConnection() {
    this.notificationHub
      .start()
      .then(() => {
        this.isConnected = true;
        this.addToGroup();
      })
      .catch(() => {
        this.buildConnection();
      });
  }

  private registerHubEvents() {
    this.notificationHub.onreconnected((id) => {
      this.isConnected = false;
      this.addToGroup();
    });

    this.notificationHub.onclose(() => {
      if (!this.isConnected) this.buildConnection();
    });

    this.latestNotificationPopupListener();
  }

  private latestNotificationPopupListener() {
    this.notificationHub.on(
      'GetNotificationByGroupName',
      (data: webNotify | number) => {
        this.unreadNotify =
          +(data as Number).toString() || (data as webNotify).count;
        if ((data as webNotify).notificationTitle)
          this.toastr.info(
            (data as webNotify).entityTitle +
              ' : ' +
              (data as webNotify).notificationTitle,
            constants.common.toast.notifyTitle,
            constants.common.toast.notifyConfig
          );
      }
    );
  }

  private addToGroup() {
    let groupName: string = 'Notification#' + this.user.id;
    this.notificationHub
      .invoke('AddUserToNotificationGroup', groupName)
      .then((data: number) => {
        this.unreadNotify = data;
      });
  }

  seeLatestNotifications() {
    this.notificationLoader = true;
    this.webNotification
      .getLatestNoOfNotifications(this.notificationCount)
      .toPromise()
      .then((res) => {
        this.topNotifications = res;
        this.notificationLoader = false;
      });
  }

  markAsRead(userNotify: webNotify) {
    if (!userNotify.isRead){
      const markNotifyAsRead = this.webNotification
      .markNotifyAsRead(userNotify.userNotificationId)
      .subscribe((res) => {
        userNotify.isRead = true;
      });
      this.subscriptions.push(markNotifyAsRead)
    }
  }

  markAllAsRead() {
    const markAllNotifyAsRead = this.webNotification.markAllNotifyAsRead().subscribe(() => {
      this.toastr.success(
        'All Notifications have been marked as Read',
        constants.common.toast.successTitle,
        constants.common.toast.config
      );
      this.unreadNotify = 0;
      if (this.notifyMenu.menuOpen) this.notifyMenu.closeMenu();
    });
    this.subscriptions.push(markAllNotifyAsRead)
  }

  ngOnDestroy() {
    this.notificationHub.stop();
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
