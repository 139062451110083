import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { PageData } from '../models/pageData';
import { ApiBaseResponse } from '../models/ApiBaseResponse';
import { map } from 'rxjs/operators';
import { Pagination } from '../models/paging';
import { ManagementTeam, Designation } from '../models/managementTeam';
@Injectable({
  providedIn: 'root',
})
export class ManagementTeamService {
  constructor(private http: HttpClient) {}

  getAll(paging: Pagination): Observable<PageData<ManagementTeam[]>> {
    let param = new HttpParams();

    if (paging.onlyActive)
      param = param.append('onlyActive', '' + paging.onlyActive);

    if (paging.searchTerm != '')
      param = param.append('searchTerm', paging.searchTerm);

    if (paging.sortBy != '') param = param.append('sortBy', paging.sortBy);

    if (paging.sortOrder != '')
      param = param.append('sortOrder', paging.sortOrder);

    if (paging.isPaginated) {
      param = param.append('pageSize', '' + paging.pageSize);
      param = param.append('pageNumber', '' + paging.pageNumber);
      param = param.append('isPaginated', '' + paging.isPaginated);
    }

    return this.http
      .get<ApiBaseResponse<ManagementTeam[]>>(
        `${environment.apiUrl}/ManagementTeam/getAll`,
        {
          params: param,
        }
      )
      .pipe(
        map((it) => {
          if (paging.isPaginated) {
            return {
              data: it.data,
              totalCount: it.pagingInfo.totalCount,
            };
          }
          return {
            data: it.data,
            totalCount: null,
          };
        })
      );
  }

  // getAll public
  getAlldata(): Observable<ManagementTeam[]> {
    return this.http
      .get<ApiBaseResponse<ManagementTeam[]>>(
        `${environment.apiUrl}/ManagementTeam/getAllPublic`
      )
      .pipe(map((it) => it.data));
  }

  add(managementTeam: any): Observable<ManagementTeam> {
    return this.http
      .post<ApiBaseResponse<ManagementTeam>>(
        `${environment.apiUrl}/ManagementTeam/add`,
        managementTeam
      )
      .pipe(map((it) => it.data));
  }

  getById(id: number): Observable<ManagementTeam> {
    return this.http
      .get<ApiBaseResponse<ManagementTeam>>(
        `${environment.apiUrl}/ManagementTeam/${id}`
      )
      .pipe(map((it) => it.data));
  }

  update(managementTeam: any): Observable<ManagementTeam> {
    return this.http
      .post<ApiBaseResponse<ManagementTeam>>(
        `${environment.apiUrl}/ManagementTeam/update`,
        managementTeam
      )
      .pipe(map((it) => it.data));
  }

  delete(id: number): Observable<ManagementTeam> {
    return this.http
      .post<ApiBaseResponse<ManagementTeam>>(
        `${environment.apiUrl}/ManagementTeam/delete`,
        {
          id: id,
        }
      )
      .pipe(map((it) => it.data));
  }

  getDesignationList(): Observable<Designation[]> {
    return this.http
      .get<ApiBaseResponse<Designation[]>>(`${environment.apiUrl}/Designation`)
      .pipe(map((it) => it.data));
  }

  changeMemberPosition(
    userId: number,
    fromPos: number,
    toPos: number
  ): Observable<ManagementTeam[]> {
    return this.http
      .post<ApiBaseResponse<ManagementTeam[]>>(
        `${environment.apiUrl}/ManagementTeam/positionChange`,
        {
          id: userId,
          from: fromPos,
          to: toPos,
        }
      )
      .pipe(map((it) => it.data));
  }
}
