import { Component, OnInit } from '@angular/core';
import { WebNotificationService } from '../../../services';
import constants from '../../../utility/constants';
import { ToastrService } from 'ngx-toastr';
import { webNotify } from '../../../models';
import {  Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  pageNum: number = 1;
  pageSize: number = constants.public.publicPageSize;
  private subscriptions: Array<Subscription> = [];
  submitLoader: boolean = false;

  totalCount: number;
  allNotify: Array<webNotify> = [];

  constructor(private webNotifyService: WebNotificationService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getNotifys();
  }

  private getNotifys() {
    this.webNotifyService.getAllNotifications(this.pageSize, this.pageNum).toPromise().then((res) => {
      this.allNotify = res.data;
      this.totalCount = res.totalCount;
    })
  }

  markAsRead(userNotify: webNotify) {
    if (!userNotify.isRead) {
      const markNotifyRead = this.webNotifyService.markNotifyAsRead(userNotify.userNotificationId).subscribe((res) => {
        userNotify.isRead = true;
      })
      this.subscriptions.push(markNotifyRead)
    }
  }

  onPageChange(paging: number) {
    this.pageNum = paging;
    this.getNotifys();
  }

  markAllAsRead() {
    this.submitLoader = true;
    const markAllNotifyRead= this.webNotifyService.markAllNotifyAsRead().subscribe(() => {
      this.submitLoader = false;
      this.getNotifys();
      this.toastr.success('All Notifications have been marked as Read', constants.common.toast.successTitle, constants.common.toast.config);
    })
    this.subscriptions.push(markAllNotifyRead)
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
