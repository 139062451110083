<div class="modal-header align-items-start">
  <h4 *ngIf="heading != null" class="modal-title pull-left">{{ heading }}</h4>
  <button type="button" class="close pull-right p-0 m-0" aria-label="Close" role="button" (click)="closeModal()">
    <img alt="cross" src="../assets/images/cross.svg" />
  </button>
</div>
<div class="modal-body">
  <div *ngIf="lotData != null" class="row d-flex justify-content-between">
    <div>Lot #{{ lotData?.lotNumber }}, {{ lotData?.lotDesc | characterLimit:200 }}</div>
    <div *ngIf="images.length == 0" class="viewing-no"> 
      &lt;Viewing {{ currentSlide }} / {{ thumbnails?.length }}&gt;</div>

      <div *ngIf="images.length > 0" class="viewing-no">
      &lt;Viewing {{ currentSlide + 1 }} / {{ thumbnails?.length }}&gt;</div>
  </div>
  <div class="lot-slider custom-slick-arrow view-more-slider details-lot-slider">
    <div class="main-slides" *ngIf="images != null">
      <div class="image-slides-wrap">
        <ngx-slick-carousel class="carousel image-slides2" #slickModal="slick-carousel" [config]="imagesSlider"
          (afterChange)="onSlideChange($event)" (init)="slickInit($event);">
          <div ngxSlickItem *ngFor="let image of images" class="slide">
            <img class="preview-image" [src]="image.name" alt="" width="100%" />
          </div>
        </ngx-slick-carousel>
      </div>
    </div>

    <div class="main-slides" *ngIf="images.length == 0">
      <div class="image-slides-wrap">
        No Image found
      </div>
    </div>

    <div class="thumbs-outer" *ngIf="thumbnails != null">
      <ngx-slick-carousel class="carousel thumbs" [config]="
          thumbnails.length <= 5 ? thumbnailWoCenterSlider : thumbnailsSlider
        ">
        <div ngxSlickItem *ngFor="let thumb of thumbnails" class="slide">
          <img class="preview-image" alt="image" [src]="thumb.name" width="100%" />
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>
