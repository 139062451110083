import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnquiryNow, ApiBaseResponse } from '../models';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EnquiryNowService {
  constructor(private http: HttpClient) {}

    postEnquiry(enquiryModel): Observable<EnquiryNow[]> {
        return this.http
        .post<ApiBaseResponse<EnquiryNow[]>>(`${environment.apiUrl}/Auction/Inquire`, enquiryModel)
        .pipe(map((it) => it.data));
  }
}