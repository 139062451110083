<div class="preloader" *ngIf="showLoader" [style.position]="inlineLoader ? 'absolute' : 'fixed'">
  <div class="loader">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
