import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private subject = new Subject();
  private subjectName = new Subject();
  notification = new EventEmitter();

  setter(data:any) {
  this.subject.next(data);
  }
  
  clear() {
  this.subject.next();
  }
  
  getter(): Observable<any>{
    return this.subject.asObservable();
  }

  setName(data:any) {
    this.subjectName.next(data);
  }
    
  clearName() {
    this.subjectName.next();
  }
  
  getName(): Observable<any>{
    return this.subjectName.asObservable();
  }
}