import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiBaseResponse, NotificationType } from '../models';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationSettingService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  getUserNotificationType(userId: number) {
    return this.http
      .get<ApiBaseResponse<Notification>>(
        `${environment.apiUrl}/EmailNotification/${userId}`
      )
      .pipe(map((it) => it.data));
  }

  updateUserNotification(updateModel: UpdateNotificationRequest) {
    return this.http
      .post<ApiBaseResponse<Notification>>(
        `${environment.apiUrl}/EmailNotification`,
        updateModel
      )
      .pipe(map((it) => it.data));
  }

  getRoleWiseNotificationType(roleId: number) {
    return this.http
      .get<ApiBaseResponse<NotificationType>>(
        `${environment.apiUrl}/EmailTemplate/GetActiveRoleBasedDropDownList`,
        {
          params: new HttpParams().append('roleId', '' + roleId),
        }
      )
      .pipe(map((it) => it.data));
  }
  enableDisableEmail(enableDisableModel: EnableDisable) {
    return this.http
      .post<ApiBaseResponse<NotificationType>>(
        `${environment.apiUrl}/EmailNotification/EnableDisable`,
        enableDisableModel
      )
      .pipe(map((it) => it.data));
  }
  enableDisableSpecificEmail(
    enableDisableSpecificEmail: EnableDisableSpecificEmail
  ) {
    return this.http
      .post<ApiBaseResponse<NotificationType>>(
        `${environment.apiUrl}/EmailNotification/EnableDisableSpecificEmail`,
        enableDisableSpecificEmail
      )
      .pipe(map((it) => it.data));
  }

  getVerifyTokenAndPreferences(token, password) {
    let cryptedPassword = this.authService.encryptData(password);
    let model = {
      encodedPayload: token,
      password: cryptedPassword,
    };

    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/EmailNotification/VerifyAndGetEmailTemplates`,
        model
      )
      .pipe(map((it) => it.data));
  }
}

interface UpdateNotificationRequest {
  userId: number;
  isEmailNotificationOn: boolean;
  activeEmails: Array<number>;
}
interface EnableDisable {
  userId: number;
  isEmailNotificationOn: boolean;
}
interface EnableDisableSpecificEmail {
  userId: number;
  emailId: number;
  isActive: boolean;
}
