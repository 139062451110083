import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  UrlTree,
  CanLoad
} from '@angular/router';
import { AuthenticationService } from './../services/index';
import constants from '../utility/constants';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad {
  // this guard only checks if loggedIn admin allowed to go to login page or not
  roles = constants.common.role;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  // this for admin-login route check
  canLoad(): boolean | UrlTree {
    let currentUser = this.authenticationService.currentUserValue();
    // #if admin already login then go to dashboard
    // #else not logged in so open the route
    if (currentUser && currentUser?.user?.role?.id !== this.roles.buyer) {
      this.router.navigateByUrl('/admin/dashboard');
      return false;
    }
    return true;
  }
}

@Injectable({ providedIn: 'root' })
export class AdminDashboardGuard implements CanActivate {
  // this guard only checks if loggedIn admin allowed to go to login page or not
  roles = constants.common.role;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  // this for admin-dashboard route check
  canActivate(
  ): boolean | UrlTree {
    let currentUser = this.authenticationService.currentUserValue();
    if (currentUser && currentUser?.user?.role?.id !== this.roles.buyer) {
      return true;
    }

    let returnUrl = this.router.url;

    returnUrl
      ? this.router.navigate(['/', 'admin', 'login'], {
          queryParams: { returnTo: returnUrl },
        })
      : this.router.navigateByUrl('/admin/login');
    return false;
  }
}
