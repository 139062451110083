import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthResponse, ApiBaseResponse } from '../models';
import * as CryptoJS from 'crypto-js';
import { WebStorageService } from './storage.service';
import constants from '../utility/constants';
import { AuctionTimeService } from './auction-time.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<AuthResponse> =
    new BehaviorSubject<AuthResponse>(null);
  private currentBuyerSubject: BehaviorSubject<AuthResponse> =
    new BehaviorSubject<AuthResponse>(null);

  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

  private readonly userTypes = constants.common.loginType;

  constructor(
    private router: Router,
    private http: HttpClient,
    private storage: WebStorageService,
    private autionTimer: AuctionTimeService
  ) {
    this.currentUserSubject = new BehaviorSubject<AuthResponse>(
      this.storage.getAdmin()?.currentUser
    );
    this.currentBuyerSubject = new BehaviorSubject<AuthResponse>(
      this.storage.getBuyer()?.currentUser
    );
  }

  public currentUserValue(): AuthResponse {
    return this.currentUserSubject.value;
  }

  public currentBuyerValue(): AuthResponse {
    return this.currentBuyerSubject.value;
  }

  public checkLoggedIn(): boolean {
    let usr = this.storage.getSessionUser();
    if (usr) {
      if (usr != this.userTypes.public)
        return this.storage.getAdmin()?.loggedIn;
      else return this.storage.getBuyer()?.loggedIn;
    } else return false;
  }

  login(
    email: string,
    password: string,
    loginType: number = this.userTypes.admin
  ) {
    let encryptPass = this.encryptData(password);

    return this.http
      .post<ApiBaseResponse<AuthResponse>>(
        `${environment.apiUrl}/Authentication/authenticate`,
        {
          loginType: loginType,
          email: email,
          password: encryptPass,
        }
      )
      .pipe(
        map((it) => {
          if (loginType == this.userTypes.admin) {
            this.storage.setAdmin(it.data);
            this.currentUserSubject.next(it.data);
          }
          if (loginType == this.userTypes.public) {
            this.storage.setBuyer(it.data);
            this.currentBuyerSubject.next(it.data);
          }
          if (it.data.accessToken) {
            this.startRefreshTokenTimer(loginType);
          }
          return it.data;
        })
      );
  }

  sendOtp(otpMode: string | null): Observable<any> {
    let eAddress = this.currentUserValue()?.mfa.email;
    return this.http.post<any>(
      `${environment.apiUrl}/authentication/generateotp`,
      {
        email: eAddress,
        mode: otpMode,
      }
    );
  }

  verifyOtp(otpMode: string | null, otp: string): Observable<any> {
    let eAddress = this.currentUserValue()?.mfa.email;
    return this.http
      .post<ApiBaseResponse<AuthResponse>>(
        `${environment.apiUrl}/authentication/verifyotp`,
        {
          email: eAddress,
          mode: otpMode,
          otp: otp,
        }
      )
      .pipe(
        map((it) => {
          this.storage.setAdmin(it.data);
          this.currentUserSubject.next(it.data);
          return it.data;
        })
      );
  }

  logout(userType: number) {
    this.revokeAccess().toPromise();
    if (userType == this.userTypes.admin) {
      this.storage.clearAdmin();
      this.currentUserSubject.next(null);
    }
    if (userType == this.userTypes.public) {
      this.storage.clearBuyer();
      this.currentBuyerSubject.next(null);
    }
    this.stopRefreshTokenTimer(userType);
    this.getLoggedInName.emit('Sign In');

    let returnUrl = this.router.url;

    if (userType == this.userTypes.admin) {
      returnUrl
        ? this.router.navigate(['/', 'admin', 'login'], {
            queryParams: { returnTo: returnUrl },
          })
        : this.router.navigateByUrl('/admin/login');
    }
    if (userType == this.userTypes.public) {
      returnUrl
        ? this.router.navigate(['/'], {
            queryParams: { returnTo: returnUrl },
          })
        : this.router.navigateByUrl('/');
    }
  }

  // APIs
  generatePasswordLink(emailAdd: string) {
    return this.http.post<any>(
      `${environment.apiUrl}/Authentication/generatePasswordResetLink`,
      {
        email: emailAdd,
      }
    );
  }

  validatePasswordLink(restToken: string) {
    return this.http.post<any>(
      `${environment.apiUrl}/Authentication/isPasswordResetLinkValid`,
      {
        encryptedPayload: restToken,
      }
    );
  }

  resetPassword(restToken: string, password: string): any {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Authentication/resetPassword`,
        {
          encryptedPayload: restToken,
          newPassword: password,
        }
      )
      .pipe(map((it) => it.data));
  }

  revokeAccess(): Observable<any> {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Authentication/revoke`,
        {}
      )
      .pipe(map((it) => it.data));
  }

  getRefreshToken(userType: number) {
    let user: AuthResponse;
    if (userType == this.userTypes.admin) {
      user = this.currentUserValue();
    }
    if (userType == this.userTypes.public) {
      user = this.currentBuyerValue();
    }

    this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/Authentication/refresh`,
        {
          accessToken: user.accessToken,
          accessTokenExpiry: user.accessTokenExpiry,
          refreshToken: user.refreshToken,
        }
      )
      .subscribe(
        (it) => {
          if (userType == this.userTypes.admin) {
            let user = this.currentUserValue();
            this.storage.setAdmin(user);
            this.currentUserSubject.next(user);
          }
          if (userType == this.userTypes.public) {
            let user = this.currentBuyerValue();
            this.storage.setBuyer(user);
            this.currentBuyerSubject.next(user);
          }
          user.accessToken = it.data.accessToken;
          user.accessTokenExpiry = it.data.accessTokenExpiry;
          user.refreshToken = it.data.refreshToken;
          this.startRefreshTokenTimer(userType);
        },
        () => {
          this.logout(userType);
        }
      );
  }

  // helper classes for refresh tokens
  private refreshTokenTimeoutForAdmin;
  private refreshTokenTimeoutForBuyer;

  public startRefreshTokenTimer(loginType: number) {
    let userRes;
    let currentTime = this.autionTimer.convertToUtc(new Date().toISOString());

    if (loginType == this.userTypes.public) {
      userRes = this.currentBuyerValue();
      let tokenExpiryTime = this.autionTimer.convertToUtc(
        userRes.accessTokenExpiry
      );
      const timeout = tokenExpiryTime - currentTime - 60 * 1000;
      this.refreshTokenTimeoutForBuyer = setTimeout(
        () => this.getRefreshToken(loginType),
        timeout
      );
    }

    if (loginType == this.userTypes.admin) {
      userRes = this.currentUserValue();
      if (!userRes) return;
      let tokenExpiryTime = this.autionTimer.convertToUtc(
        userRes.accessTokenExpiry
      );
      const timeout = tokenExpiryTime - currentTime - 60 * 1000;
      this.refreshTokenTimeoutForAdmin = setTimeout(
        () => this.getRefreshToken(loginType),
        timeout
      );
    }
  }

  public stopRefreshTokenTimer(loginType: number) {
    if (loginType == this.userTypes.public) {
      clearTimeout(this.refreshTokenTimeoutForBuyer);
    }
    if (loginType == this.userTypes.admin) {
      clearTimeout(this.refreshTokenTimeoutForAdmin);
    }
  }

  public encryptData(pass: string) {
    let encryptSecretKey = environment.encryptionKey;
    var keySize = 256;
    var salt = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.PBKDF2(encryptSecretKey, salt, {
      keySize: keySize / 32,
      iterations: 100,
    });
    var iv = CryptoJS.lib.WordArray.random(128 / 8);
    var encrypted = CryptoJS.AES.encrypt(pass, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    return CryptoJS.enc.Base64.stringify(
      salt.concat(iv).concat(encrypted.ciphertext)
    );
  }
}
