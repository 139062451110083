import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AuctionImageUploadService,
  ImageFile,
} from '../../../../services/auction-image-upload.service';
import { LoaderService } from '../../../../services';
import constants from '../../../../utility/constants';
import { ToastrService } from 'ngx-toastr';
import messages from '../../../../utility/messages';
import { Observable, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.css'],
})
export class ImageUploaderComponent implements OnInit, OnDestroy {
  // if auction specific assets then below is required
  @Input() auctionId: number = 0;
  @Input() assetType: number = 0;
  // if lot specfic, then lotId is required
  @Input() lotId: number = 0;
  // below are required in all
  @Input() uploadedImages: ImageFile[] = [];
  @Input() imageSize: number;
  @Input() imageFormat: string;
  // required if reset image is true
  @Input() assetName: string;
  // optional, if reset images button required
  @Input() resetImagesNeeded: boolean = false;
  // optional, if reordering required
  @Input() orderChangeNeeded: boolean = false;

  @Input() errorLogs: Array<string> = [];

  @Output() resultantImages: EventEmitter<ImageFile[]> = new EventEmitter<
    ImageFile[]
  >();

  // DnD configs
  validComboDrag: any;
  lastInvalids: any;
  fileDropDisabled: any;
  private subscriptions: Array<Subscription> = [];

  // images as blob i.e. new images
  imageBlobs: any[] = [];
  // initial blob array length
  blobArrayLength: number = 0;

  // whole set of images including new and old images
  images: ImageFile[] = [];

  // all assets
  assets = constants.admin.assetType;
  modalRef: BsModalRef;

  constructor(
    private auctionImageUploader: AuctionImageUploadService,
    private modalService: BsModalService,
    private loader: LoaderService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (this.uploadedImages && this.uploadedImages?.length) {
      this.images.push(...this.uploadedImages);
    }
    this.images = this.images.filter((el, i, a) => i === a.indexOf(el));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes?.uploadedImages?.currentValue) {
        this.images = changes?.uploadedImages?.currentValue;
      }
    }
  }

  // delete uploaded images
  private removeOldImage(imageIndex: number, image: ImageFile) {
    // for image remove
    let request: Observable<any>;

    if (this.lotId) {
      request = this.auctionImageUploader.uploadLotImage(
        this.lotId,
        image.id,
        image.name,
        image.order,
        true
      );
    } else {
      request = this.auctionImageUploader.uploadImage(
        this.auctionId,
        image.id,
        image.name,
        image.order,
        this.assetType,
        true
      );
    }

    this.loader.showLoader(true);
    const imageUpload = request.subscribe(
      (res) => {
        this.loader.showLoader(false);
        if (res) {
          this.removeImage(imageIndex, null);
          this.toastr.success(
            messages.addAuction2.image,
            constants.common.toast.successTitle,
            constants.common.toast.config
          );
        }
      },
      (err) => {
        this.loader.showLoader(false);
        this.toastr.success(
          err,
          constants.common.toast.errorTitle,
          constants.common.toast.config
        );
      }
    );
    this.subscriptions.push(imageUpload);
  }

  // soft delete from array
  removeImage(index: number, image: ImageFile) {
    if (image && image?.id) {
      this.removeOldImage(index, image);
    } else {
      this.images.splice(index, 1);
      this.sendResultantImages();
    }
  }

  // delete all uploaded images
  deleteAllUploaded() {
    this.loader.showLoader(true);
    let isPhotoGallery: boolean;
    if (this.assets.photoGallery == this.assetType) isPhotoGallery = true;
    if (this.assets.sliderImage == this.assetType) isPhotoGallery = false;

    const cleanAllPhotos = this.auctionImageUploader
      .cleanAllPhotos(this.auctionId, isPhotoGallery)
      .subscribe(
        (res) => {
          this.images = [];
          this.loader.showLoader(false);
          this.toastr.success(
            `Uploaded ${
              isPhotoGallery ? 'gallery' : 'slider'
            } images removed successfully`,
            constants.common.toast.successTitle,
            constants.common.toast.config
          );
          this.closeModal();
        },
        (err) => {
          this.loader.showLoader(false);
          this.toastr.error(
            err,
            constants.common.toast.errorTitle,
            constants.common.toast.config
          );
          this.closeModal();
        }
      );
    this.subscriptions.push(cleanAllPhotos);
  }

  // while uploading the file
  checkFilesChange(data: any[]) {
    let currentLength = data.length;
    let slicedImages: ImageFile[] = data
      .slice(this.blobArrayLength, currentLength)
      .map((fileBlob) => ({ id: 0, name: fileBlob, order: 0 }));
    this.images.push(...slicedImages);

    let flattenImages = this.images.map((image) => {
      if (typeof image.name !== 'string') {
        var ext = image?.name?.name.split('.').pop();
        if (
          ext == 'jpg' ||
          ext == 'png' ||
          ext == 'jpeg' ||
          ext == 'JPG' ||
          ext == 'PNG' ||
          ext == 'JPEG'
        ) {
          return { ...image, actualName: image.name?.name };
        } else {
          let index = data.findIndex((x) => x.name == image.name);
          this.images.splice(index, 1);
          data.splice(index, 1);
          this.toastr.error('Image extension must be one of jpeg, png, jpg');
          return;
        }
      } else {
        var ext = image?.name.split('.').pop();
        if (
          ext == 'jpg' ||
          ext == 'png' ||
          ext == 'jpeg' ||
          ext == 'JPG' ||
          ext == 'PNG' ||
          ext == 'JPEG'
        ) {
          return { ...image, actualName: this.getNameFromImageUrl(image.name) };
        } else {
          let index = data.findIndex((x) => x.name == image.name);
          this.images.splice(index, 1);
          data.splice(index, 1);
          this.toastr.error('Image extension must be one of jpeg, png, jpg');
          return;
        }
      }
    });

    flattenImages.sort((istImage, iindImage) =>
      istImage.actualName.toLowerCase() > iindImage.actualName.toLowerCase()
        ? 1
        : iindImage.actualName.toLowerCase() > istImage.actualName.toLowerCase()
        ? -1
        : 0
    );

    this.images = flattenImages;

    if (this.blobArrayLength !== data.length)
      this.blobArrayLength = data.length;
    this.images = this.images.filter(function (element) {
      return element !== undefined;
    });
    this.sendResultantImages();
  }

  // on drag image : event
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    this.sendResultantImages();
  }

  checkFileIsString(image: any): boolean {
    return typeof image === 'string';
  }

  sendResultantImages() {
    let resultantArray = this.images.map((image, index) => ({
      id: image.id,
      name: image.name,
      order: index + 1,
    }));
    this.resultantImages.emit(resultantArray);
  }

  getNameFromImageUrl(imageUrl: string): string {
    let splirArr = imageUrl.split('/');
    return splirArr[splirArr.length - 1];
  }

  openResetModal(template) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({
        backdrop: 'static',
        keyboard: false,
        class: 'custom-popup activate-modal modal-dialog modal-dialog-centered',
      })
    );
  }

  closeModal() {
    this.modalService.hide();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
