import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmpty',
})
export class IsEmptyPipe implements PipeTransform {
  transform(value: any, separator: string = '-'): string {
    if (value == '' || value == null || value == undefined) return separator;
    return value;
  }
}
