import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService, WebStorageService } from '../services/index';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: WebStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentRole = this.storage.getSessionUser();

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout(currentRole);
        }

        if (err.status === 403) {
          // auto logout if 403 [UnAuthorized] response returned from API
          this.authenticationService.logout(currentRole);
        }

        let errorMessage = '';

        if (err.error && err.error.message) {
          errorMessage = err.error.message;
        }

        return throwError(errorMessage);
      })
    );
  }
}
