import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import constants from '../../../../utility/constants';
import { of, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.css'],
})
export class SearchControlComponent implements OnInit {
  @Input('placeholder') placeholderText: string;
  @Output('value') value = new EventEmitter<Observable<string>>();
  @Input('mobileFilter') isForMobile: boolean = false;
  @Output('toogleButton') classToggled = new EventEmitter<boolean>();
  @Input('classes') classes: string = null;
  @Input() initialValue: string = null;

  searchControl: FormControl;
  searchMaxLength = constants.common.validations.searchMaxLength;
  searchValue: string | null;

  ngOnInit(): void {
    this.searchControl = new FormControl(this.initialValue);
    this.initializeSearch();
  }

  initializeSearch() {
    this.searchControl.valueChanges
      .pipe(debounceTime(1100))
      .subscribe((res) => {
        this.value.emit(of(res == '' ? null : res));
      });
  }

  resetSearch() {
    if (this.searchControl.value != null && this.searchControl.value != '') {
      this.searchControl.reset();
      this.toggleButton(false);
    }
  }

  toggleButton(value: boolean) {
    this.classToggled.emit(value);
  }
}
