import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, BehaviorSubject, interval } from 'rxjs';
import { ApiBaseResponse } from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuctionTimeService {
  private currentServerTime: BehaviorSubject<number>; // timestamp

  private apiUrl: string = environment.apiUrl;
  private serverDate: string; // in string

  constructor(private http: HttpClient) {
    this.currentServerTime = new BehaviorSubject<number>(null);
  }

  getLatestServerDateTime(): Observable<string> {
    return this.http
      .get<ApiBaseResponse<string>>(
        `${this.apiUrl}/ServerConfiguration/GetCurrentDateTime`
      )
      .pipe(
        map((it: any) => {
          this.serverDate = it.data;
          return it.data;
        })
      );
  }

  serverDateTime(): BehaviorSubject<number> {
    return this.currentServerTime;
  }

  serverDateTimeString() {
    return this.serverDate;
  }

  isAuctionClosingToday(lastLotClosingTimeUtc: string): boolean {
    let currentDate;
    if (this.serverDate)
      currentDate = new Date(this.convertToUtc(this.serverDate));
    else currentDate = new Date(this.convertToUtc(new Date()));
    let lotLastDate = new Date(this.convertToUtc(lastLotClosingTimeUtc));
    // closing going to be today
    if (currentDate.setHours(0, 0, 0, 0) === lotLastDate.setHours(0, 0, 0, 0)) {
      return true;
    }
    return false;
  }

  isLotClosing(
    endDateTimeFirstLotUtc: string,
    endDateTimeLastLotUtc: string
  ): boolean {
    let currentDate;
    if (this.serverDate)
      currentDate = new Date(this.convertToUtc(this.serverDate));
    else currentDate = new Date(this.convertToUtc(new Date()));
    let lotFirstDate = this.convertToUtc(endDateTimeFirstLotUtc);
    let lotLastDate = this.convertToUtc(endDateTimeLastLotUtc);
    if (currentDate >= lotFirstDate && currentDate <= lotLastDate) {
      return true;
    }
    return false;
  }

  isBiddingOpen(
    startDateTimeUtc: string | Date,
    lastLotCloseDateTimeUtc: string
  ): boolean {
    let currentDate;
    if (this.serverDate)
      currentDate = new Date(this.convertToUtc(this.serverDate));
    else currentDate = new Date(this.convertToUtc(new Date()));
    let startDateTime = this.convertToUtc(startDateTimeUtc);
    let lastLotDateTime = this.convertToUtc(lastLotCloseDateTimeUtc);
    if (currentDate >= startDateTime && currentDate <= lastLotDateTime) {
      return true;
    }
    return false;
  }

  isLotClosingBegun(auctionEndDateTimeUtc: string) {
    let currentDate;
    if (this.serverDate)
      currentDate = new Date(this.convertToUtc(this.serverDate));
    else currentDate = new Date(this.convertToUtc(new Date()));
    let auctionEndTimeUtc = this.convertToUtc(auctionEndDateTimeUtc);
    if (currentDate >= auctionEndTimeUtc) {
      return true;
    }
    return false;
  }

  convertToUtc(time: string | Date): number {
    if (time) {
      if (time instanceof Date) time = time.toString();
      time = time.split('.')[0];
      let date = time.split('T')[0];
      let timing = time.split('T')[1];
      let [hour, min, sec] = timing.split(':');
      let [year, month, day] = date.split('-');
      return Date.UTC(+year, +month - 1, +day, +hour, +min, +sec);
    }
  }
}
