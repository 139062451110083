<div ngfDrop multiple="1" selectable="1" [accept]="imageFormat" [maxSize]="imageSize"
  class="well my-drop-zone custom-drop-zone" [(validDrag)]="validComboDrag" [(lastInvalids)]="lastInvalids"
  [class.invalid-drag]="validComboDrag === false" [class.valid-drag]="validComboDrag"
  [fileDropDisabled]="fileDropDisabled" (filesChange)="checkFilesChange($event)">
  <img alt="thumb-image" src="../assets/images/drag-upload-thumb.png" />
  <p class="drag-name">
    Drag & Drop Your Images Here
    <br />
    <span>Or</span>
  </p>
  <div class="for-btn">
    <span>Upload Image</span>
  </div>
</div>

<ng-container *ngIf="orderChangeNeeded; else normalImageViewList">
  <!-- drag and drop to change the order -->
  <div class="for-preview-images">
    <div cdkDropList cdkDropListOrientation="horizontal" [class]="images && images?.length ? 'example-list' : ''"
      (cdkDropListDropped)="drop($event)">
      <span class="image-zone-outer example-box" *ngFor="let image of images; let imageIndex = index"
        cdkDragLockAxis="x" cdkDragBoundary=".example-list" cdkDrag>
        <div class="image-zone-inner">
          <ng-container *ngIf="checkFileIsString(image?.name); else fileBlob">
            <div [title]="getNameFromImageUrl(image?.name)" class="image-box">
              <img class="previewIcon" alt="image{{ image.order }}" [src]="image?.name" loading="lazy" />
              <span class="image-order">
                <small>order #{{ imageIndex + 1 }}</small>
              </span>
              <div class="image-text">
                {{ getNameFromImageUrl(image?.name) | characterLimit: 10 }}
              </div>
            </div>
          </ng-container>
          <ng-template #fileBlob>
            <div [title]="image?.name?.name" class="image-box">
              <img class="previewIcon" alt="image{{ image?.order }}" [ngfSrc]="image?.name" loading="lazy" />
              <span class="image-order">
                <small>order #{{ imageIndex + 1 }}</small>
              </span>
              <div class="image-text">
                {{ image?.name?.name | characterLimit: 10 }}
              </div>
            </div>
          </ng-template>
          <span class="cross-icon" role="button" (click)="removeImage(imageIndex, image)">
            <img alt="cross-icon" src="../assets/images/black-cross.svg" />
          </span>
        </div>

        <div *cdkDragPreview class="image-zone-inner">
          <ng-container *ngIf="checkFileIsString(image?.name); else fileBlob">
            <div class="image-box">
              <img class="previewIcon" alt="image{{ image?.order }}" [src]="image?.name" weight="85" height="85"
                loading="lazy" />
            </div>
          </ng-container>
          <ng-template #fileBlob>
            <div class="image-box">
              <img alt="image{{ image.order }}" class="previewIcon" [ngfSrc]="image?.name" weight="85" height="85"
                loading="lazy" />
            </div>
          </ng-template>
        </div>

        <div *cdkDragPlaceholder class="drop-placeholder"></div>
      </span>
    </div>
  </div>
</ng-container>

<ng-template #normalImageViewList>
  <div class="for-preview-images">
    <ng-container *ngIf="uploadedImages && uploadedImages?.length">
      <div class="image-zone-outer" *ngFor="let image of uploadedImages; let imageIndex = index">
        <div class="image-zone-inner">
          <div>
            <img alt="previewIcon" class="previewIcon" [src]="image?.name" />
          </div>
          <span class="cross-icon" role="button" (click)="removeImage(imageIndex, image)">
            <img alt="cross-icon" src="../assets/images/black-cross.svg" />
          </span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="images && images?.length">
      <div class="image-zone-outer" *ngFor="let image of images; let imageIndex = index">
        <div class="image-zone-inner">
          <div [title]="image?.name">
            <div class="previewIcon" [ngfBackground]="image"></div>
            <div class="image-text">{{ image?.name | characterLimit: 10 }}</div>
          </div>
          <span class="cross-icon" role="button" (click)="removeImage(imageIndex, image)">
            <img alt="cross-icon" src="../assets/images/black-cross.svg" />
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-container *ngIf="resetImagesNeeded">
  <div class="text-center mt-2">
    <button class="btn btn-sm btn-white-border half-mob" *ngIf="images && images.length" role="button"
      (click)="openResetModal(resetTemplate)">
      Reset {{ assetName }}
    </button>
  </div>
</ng-container>

<ng-template #resetTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left"></h4>
    <button type="button" class="close pull-right p-0 m-0" aria-label="Close" role="button" (click)="closeModal()">
      <img alt="cross-icon" src="../assets/images/cross.svg" />
    </button>
  </div>
  <div class="modal-body my-activate">
    <h1 class="modal-inner-hdg">Reset {{ assetName }}</h1>
    <p class="des">Are you sure you want to proceed with this?</p>
    <button class="btn btn-white-border mr-2" role="button" (click)="closeModal()">
      No
    </button>
    <button class="btn btn-colored" role="button" (click)="deleteAllUploaded()" type="button">
      Yes
    </button>
  </div>
</ng-template>

<ng-container *ngIf="errorLogs && errorLogs.length > 0">
  <div class="sync-logger" id="error_logs">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 error-logger-wrapper">
          <div class="my-3">
            <h6>Error log(s) :</h6>
            <ng-container *ngFor="let log of errorLogs">
              <hr class="my-2" />
              <p class="m-0">{{log}}</p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
