import { Injectable } from '@angular/core';
import { Module } from '../models/module';
import { WebStorageService } from './../services';

@Injectable()
export class ModuleService {

  private userModules: Module[];
  constructor(private storage: WebStorageService) { }

  userHas(requiredModule: string): boolean {
    if (requiredModule === 'none')
      return true;

    //lazily loading because there is no perfect place
    //to inject this, where modules have been initialized already
    //in local storage.
    if (this.userModules === undefined)
      this.initializeModules();

    var i = this.userModules.findIndex(it => it.name.toLowerCase() === requiredModule.toLowerCase());
    return i !== -1 ? true : false;
  }

  private initializeModules() {
    var storedModules = this.storage.getPermissions();
    if (storedModules == null)
      this.userModules = []
    else
      this.userModules = (storedModules);
  }

  //this is should be called on logout
  clearModules() {
    this.userModules = undefined;
  }
}
