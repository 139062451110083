import { AfterContentInit, Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[AuctionDefaultImage]',
})
export class AuctionDefaultImageDirective implements AfterContentInit {
  @Input('image') actualImage: string = null;

  @HostBinding('src') imageSrc: string;

  ngAfterContentInit() {
    this.imageSrc = this.actualImage
      ? this.actualImage
      : '/assets/images/default_bg_img.jpg';
  }
}
