import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiBaseResponse } from '../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ImageFile {
  id: number;
  name: string | File | null;
  order?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuctionImageUploadService {
  private serverUrl: string = environment.apiUrl + '/auctionAsset';

  constructor(private http: HttpClient) {}

  // for single image upload
  public uploadImage(
    auctionId: number,
    fileId: number,
    file: string | File | null,
    order: number | null,
    assetType: number,
    isDelete: boolean = false
  ): Observable<any> {
    let formData = new FormData();
    formData.append('auctionId', '' + auctionId);
    formData.append('fileId', '' + fileId);
    (typeof file === 'string')?
    formData.append('file', isDelete ? null : file):
    formData.append('file', isDelete ? null : file,file?.name);
    formData.append('assetType', '' + assetType);
    formData.append('order', isDelete ? '' + 0 : '' + order);
    return this.http
      .post<ApiBaseResponse<Observable<any>>>(this.serverUrl, formData)
      .pipe(map((it) => it.data));
  }

  // for cleaning all images
  cleanAllPhotos(
    auctionId: number,
    isPhotoGallery: boolean = false
  ): Observable<boolean> {
    let url = isPhotoGallery ? 'CleanPhotoGalleryImages' : 'CleanSliderImages';
    return this.http
      .post<ApiBaseResponse<boolean>>(`${environment.apiUrl}/Auction/${url}`, {
        auctionId: auctionId,
      })
      .pipe(map((it) => it.data));
  }

  /**
   * method to upload lot images respective of lotId
   * @param image : it can be FileBlob for new image and string for uploaded image
   */
  public uploadLotImage(
    lotId: number,
    fileId: number,
    image: File | string,
    order: number,
    isDelete: boolean = false,
    fileName?: string
  ): Observable<any> {
    let formData = new FormData();
    formData.append('lotId', '' + lotId);
    formData.append('imageId', '' + fileId);
    (typeof image === 'string') ?
    formData.append('image', isDelete ? null : image):
    formData.append('image', isDelete ? null : image, fileName || image?.name)
    formData.append('order', '' + (isDelete ? 0 : order));
    return this.http
      .post<ApiBaseResponse<any>>(`${environment.apiUrl}/lotImage`, formData)
      .pipe(map((it) => it.data));
  }

  generateThumbnails(): Observable<boolean> {
    let url = `${environment.apiUrl}/AzureBlobServices/GenerateThumbnails`;
    return this.http
      .get<ApiBaseResponse<boolean>>(url)
      .pipe(map((it) => it.data));
  }
}
