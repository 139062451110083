import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuctionTimeService } from '../../services/auction-time.service';

@Injectable({
  providedIn: 'root'
})
export class ServerTimeResolver implements Resolve<string> {

  constructor(private auctionTimeService: AuctionTimeService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<string> {
    return new Promise((resolve) => {
      this.auctionTimeService.getLatestServerDateTime().toPromise().then((data) => {
        resolve(data);
      }).catch((res) => {
        return new Error(res);
      })
    });
  }
}
