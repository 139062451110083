import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/component/not-found/not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthRoleGuard } from './helpers/auth-role.guard';
import { BackUrlResolver } from './shared/resolver/back-url.resolver';
import { ServerTimeResolver } from './shared/resolver/server-time.resolver';
import { AdminUserResolver } from './shared/resolver/admin-user.resolver';
import { BuyerUserResolver } from './shared/resolver/buyer-user.resolver';
import { EmailSettingComponent } from './shared/component/email-setting/email-setting.component';

const routes: Routes = [
  { path: 'not-found', component: NotFoundComponent },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./public/public.module').then((m) => m.PublicModule),
        resolve: {
          userType: BuyerUserResolver,
          returnTo: BackUrlResolver,
          serverTime: ServerTimeResolver,
        },
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
        canLoad: [AuthRoleGuard],
        canActivate: [AuthRoleGuard],
        resolve: {
          userType: AdminUserResolver,
          returnTo: BackUrlResolver,
          serverTime: ServerTimeResolver,
        },
      },
      {
        path: 'activate/:token',
        loadChildren: () =>
          import('./public/public.module').then((m) => m.PublicModule),
      },
      { path: 'reset-password/:token', component: ResetPasswordComponent },
      {
        path: 'user-reset-password/:token',
        loadChildren: () =>
          import('./public/public.module').then((m) => m.PublicModule),
      },
      {
        path: 'email-setting/:token',
        component: EmailSettingComponent,
      },
    ],
  },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
