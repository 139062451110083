import { Pipe, PipeTransform } from '@angular/core';
import constants from '../../utility/constants';
import { ToLocalDateTime } from './to-local-datetime.pipe';

@Pipe({
  name: 'auctionDateFormat'
})
export class AuctionDateFormatPipe implements PipeTransform {
  private auctionDate = constants.common.auctionDate;
  private auctionStatuses = constants.common.auctionStatus;
  private startString: string = "";
  private endString: string = "Closing begins ";
  private endedString: string = "Closing began ";

  private htmlString: string = "<span>#</span>";
  private separator: string = "<br>";

  transform(dateFormat: number, auctionStatus: number, timeZone: string, startDateTime: Date | string = null, endDateTime: Date | string = null, dateText: string, formatString: string = 'EEEE, MMMM d, h:mm a'): string {

    let dateString: string;

    if (auctionStatus == this.auctionStatuses.live || auctionStatus == this.auctionStatuses.upcoming) {
      (auctionStatus == this.auctionStatuses.live) ? this.startString = "Opened " : this.startString = "Opens ";

      switch (dateFormat) {
        case this.auctionDate.bothDates:
          dateString = this.startString + new ToLocalDateTime().transform(startDateTime, false, timeZone, formatString) + this.separator + this.endString + new ToLocalDateTime().transform(endDateTime, false, timeZone, formatString);
          break;

        case this.auctionDate.onlyStartDate:
          dateString = this.startString + new ToLocalDateTime().transform(startDateTime, false, timeZone, formatString);
          break;

        case this.auctionDate.onlyEndDate:
          dateString = this.endString + new ToLocalDateTime().transform(endDateTime, false, timeZone, formatString);
          break;

        case this.auctionDate.noDate:
          dateString = dateText || '';
          break;
      }
    }
    else if (auctionStatus == this.auctionStatuses.past) {
      dateString = this.endedString + new ToLocalDateTime().transform(endDateTime, false, timeZone, formatString);
    } else {
      dateString = '';
    }
    return this.htmlString.replace("#", dateString);
  }
}
