import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  StorageService,
} from 'ngx-webstorage-service';
import { AuthResponse, User } from '../models';
import constants from '../utility/constants';
import { EncrDecrService } from './encrDecrService.service';

export interface StorageUser {
  admin: StorageKey;
  buyer: StorageKey;
}

export interface StorageKey {
  rolePermission: any;
  currentUser: AuthResponse;
  loggedIn: boolean;
  lotLowConnectionMode: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class WebStorageService {
  private readonly storageKeys = {
    admin: 'admin',
    buyer: 'buyer',
    permission: 'rolePermission',
    logged: 'loggedIn',
    connectionMode: 'lotLowConnectionMode',
    currentUser: 'currentUser',
    rememberMe: 'rememberMe',
  };

  private readonly roles = constants.common.role;
  private readonly userTypes = constants.common.loginType;

  constructor(
    @Inject(LOCAL_STORAGE) public storage: StorageService,
    @Inject(SESSION_STORAGE) public sessionStorage: StorageService,
    private encrDecrService: EncrDecrService,
    private route: ActivatedRoute
  ) {}

  ////// admin section starts
  getAdmin() {
    let storedData = this.decryptStoredKey(this.storageKeys.admin);
    if (storedData) return storedData as StorageKey;
    return null;
  }

  setAdmin(
    data: any,
    loggedIn: boolean = false,
    lowConnectionMode: boolean = false
  ) {
    let keyData: StorageKey;
    let storedKeyData = this.getAdmin();
    keyData = {
      currentUser: data || storedKeyData?.currentUser,
      loggedIn:
        loggedIn != null && loggedIn != undefined
          ? loggedIn
          : storedKeyData?.loggedIn,
      lotLowConnectionMode:
        lowConnectionMode != null && lowConnectionMode != undefined
          ? lowConnectionMode
          : storedKeyData?.lotLowConnectionMode,
      rolePermission: data?.modules || storedKeyData?.rolePermission,
    };
    this.encryptAndStoreData(this.storageKeys.admin, keyData);
  }

  clearAdmin() {
    this.clearStoredKey(this.storageKeys.admin);
  }

  getPermissions() {
    if (this.getAdmin()) {
      return this.getAdmin().currentUser.modules;
    }
    return null;
  }
  ////// admin section ends

  ////// buyer section starts
  getBuyer() {
    let storedData = this.decryptStoredKey(this.storageKeys.buyer);
    if (storedData) return storedData as StorageKey;
    return null;
  }

  setBuyer(
    data: any,
    loggedIn: boolean = false,
    lowConnectionMode: boolean = false
  ) {
    let keyData: StorageKey;
    let storedKeyData = this.getBuyer();
    keyData = {
      currentUser: data || storedKeyData?.currentUser,
      loggedIn:
        loggedIn != null && loggedIn != undefined
          ? loggedIn
          : storedKeyData?.loggedIn,
      lotLowConnectionMode:
        lowConnectionMode != null && lowConnectionMode != undefined
          ? lowConnectionMode
          : storedKeyData?.lotLowConnectionMode,
      rolePermission: data?.modules || storedKeyData?.rolePermission,
    };
    this.encryptAndStoreData(this.storageKeys.buyer, keyData);
  }

  clearBuyer() {
    this.clearStoredKey(this.storageKeys.buyer);
  }

  getRememberMe(): boolean {
    let key = this.storageKeys.rememberMe;
    let keyData = this.storage.get(key);
    return keyData as boolean;
  }
  setRememberMe(data: boolean) {
    let key = this.storageKeys.rememberMe;
    this.storage.set(key, data);
  }
  clearRememberMe() {
    let key = this.storageKeys.rememberMe;
    this.storage.remove(key);
  }
  ////// buyer section ends

  ////// sessioned role start
  getSessionUser(): number {
    return this.getRoleKey() as number;
  }
  setSessionUser(role: number) {
    this.storeRoleKey(role);
  }
  clearSessionUser(userType: number) {
    this.clearRoleKey();
  }
  ////// sessioned role end

  // main functions for local storage
  private decryptStoredKey(key: string): any {
    let keyData = this.storage.get(key);
    return this.encrDecrService.decryptData(keyData);
  }
  private encryptAndStoreData(key: string, data: StorageKey) {
    this.storeRoleKey(data?.currentUser?.user?.role?.id);
    let keyValue = this.encrDecrService.encryptData(data);
    this.storage.set(key, keyValue);
  }
  private clearStoredKey(key: string) {
    this.clearRoleKey();
    this.storage.remove(key);
  }

  // main functions for session storage
  private storeRoleKey(role: number) {
    if (role) {
      let userType: number;
      if (role == this.roles.buyer) userType = this.userTypes.public;
      if (role != this.roles.buyer) userType = this.userTypes.admin;
      this.sessionStorage.set('type', userType);
    }
  }
  private getRoleKey() {
    return this.sessionStorage.get('type');
  }
  private clearRoleKey() {
    this.sessionStorage.remove('type');
  }
}
