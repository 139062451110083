import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Cms, ApiBaseResponse } from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  constructor(private http: HttpClient) { }

  getAllCmsPages(): Observable<Cms[]> {
    return this.http
      .get<ApiBaseResponse<Cms[]>>(`${environment.apiUrl}/cms`)
      .pipe(map((it) => it.data));
  }

  getCmsPage(pageId: number): Observable<Cms> {
    return this.http
      .get<ApiBaseResponse<Cms>>(`${environment.apiUrl}/cms/${pageId}`)
      .pipe(map((it) => it.data));
  }

  updateCmsPage(pageId: number, content: string): Observable<Cms> {
    return this.http
      .post<ApiBaseResponse<Cms>>(`${environment.apiUrl}/cms`, {
        id: pageId,
        description: content,
      })
      .pipe(map((it) => it.data));
  }

  GetCmsPublic(cmsId: number): Observable<string> {
    return this.http
      .get<ApiBaseResponse<string>>(`${environment.apiUrl}/cms/GetCmsPublic?CmsId=${cmsId}`)
      .pipe(map((it) => it.data));
  }
}
