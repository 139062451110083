import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  constructor() { }
  //The set method is use for encrypt the value.

  encrypt(value: any): any {
    return CryptoJS.AES.encrypt(value, environment.encryptionKey.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    let st = CryptoJS.AES.decrypt(textToDecrypt, environment.encryptionKey.trim()).toString(CryptoJS.enc.Utf8);
    return st;
  }

  public encryptObject(textToEncrypt: any) {

    let data = JSON.stringify(textToEncrypt);

    if (typeof (textToEncrypt) === 'string')
      data = textToEncrypt;

    let encryptSecretKey = environment.encryptionKey;
    var keySize = 256;
    var salt = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.PBKDF2(encryptSecretKey, salt, {
      keySize: keySize / 32,
      iterations: 100,
    });
    var iv = CryptoJS.lib.WordArray.random(128 / 8);
    var encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    var result = CryptoJS.enc.Base64.stringify(
      salt.concat(iv).concat(encrypted.ciphertext)
    );
    return result;
  }

  decrypt1(ciphertextB64) {
    var key = CryptoJS.enc.Utf8.parse(environment.encryptionKey);
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);

    var decrypted = CryptoJS.AES.decrypt(ciphertextB64, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), environment.encryptionKey).toString();
    } catch (e) {
    }
  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, environment.encryptionKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
    }
  }
}
