<div class="all-notifications">
  <div class="container">
    <div class="row top-header">
      <div class="col-auto">
        <h1 class="all-noti-hdg">Notifications</h1>
      </div>

      <div class="col-auto ml-auto">
        <button
          type="button"
          class="btn btn-white-border"
          role="button"
          (click)="markAllAsRead()"
        >
          Mark All As Read
        </button>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div
          class="empty-state-container"
          *ngIf="totalCount <= 0; else showList"
        >
          <div class="empty-state-content">
            <img
              alt="empty-state"
              src="../../../../assets/images/empty-state-icon.svg"
            />
            <h3 class="_heading">No Notifications yet</h3>
          </div>
        </div>

        <ng-template #showList>
          <app-paginator
            [paginationId]="'server'"
            [currentPage]="pageNum"
            [pageSize]="pageSize"
            (page)="onPageChange($event)"
            *ngIf="totalCount > pageSize"
          >
          </app-paginator>

          <ul class="all-notifications-list">
            <ng-container
              *ngFor="
                let notify of allNotify
                  | paginate
                    : {
                        id: 'server',
                        itemsPerPage: pageSize,
                        currentPage: pageNum,
                        totalItems: totalCount
                      }
              "
            >
              <a
                *ngIf="notify.link; else withoutLink"
                target="_blank"
                [href]="notify.link"
              >
                <li
                  [ngClass]="{ unread: !notify.isRead }"
                  role="button"
                  (click)="markAsRead(notify)"
                >
                  <span class="icon-wrap">
                    <img
                      alt="noti-icon"
                      src="../assets/images/notification.svg"
                      class="noti-icon"
                    />
                  </span>
                  <span class="dtl-wrap">
                    <p class="main-dtl">
                      {{
                        (notify.entityTitle | titlecase) +
                          " : " +
                          notify.notificationTitle
                      }}
                    </p>
                    <p class="small-dtl">
                      {{
                        notify.createdOnUserTime | date: "d MMM yyyy, h:mm a"
                      }}
                    </p>
                  </span>
                </li>
              </a>

              <ng-template #withoutLink>
                <a>
                  <li
                    [ngClass]="{ unread: !notify.isRead }"
                    role="button"
                    (click)="markAsRead(notify)"
                  >
                    <span class="icon-wrap">
                      <img
                        alt="noti-icon"
                        src="../assets/images/notification.svg"
                        class="noti-icon"
                      />
                    </span>
                    <span class="dtl-wrap">
                      <p class="main-dtl">
                        {{
                          (notify.entityTitle | titlecase) +
                            " : " +
                            notify.notificationTitle
                        }}
                      </p>
                      <p class="small-dtl">
                        {{
                          notify.createdOnUserTime | date: "d MMM yyyy, h:mm a"
                        }}
                      </p>
                    </span>
                  </li>
                </a>
              </ng-template>
            </ng-container>
          </ul>

          <app-paginator
            [paginationId]="'server'"
            [currentPage]="pageNum"
            [pageSize]="pageSize"
            (page)="onPageChange($event)"
            *ngIf="totalCount > pageSize"
          >
          </app-paginator>
        </ng-template>
      </div>
    </div>
  </div>
</div>
