import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HeaderComponent } from './component/layout/header/header.component';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BlogAuthGaurd } from './guards/blog-auth.guard';
import { ContentAuthGuard } from './guards/content-auth.guard';
import { SaleTypeGuard } from './guards/saletype-auth.guard';
import { AuctionCategoryGuard } from './guards/auction-category.guard';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ManageAdminGuard } from './guards/manage-admin.guard';
import { SearchControlComponent } from './component/search-control/search-control.component';
import { FilterControlComponent } from './component/filter-control/filter-control.component';
import { EditorComponent } from './component/editor/editor.component';
import { CommonSharedModule } from '../../shared/shared.module';
import { BackComponent } from './component/back/back.component';
import { ImageUploaderComponent } from './component/image-uploader/image-uploader.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ngfModule } from 'angular-file';
import { CharCounterComponent } from './component/char-counter/char-counter.component';
import { AuctionManagementGuard } from './guards/auction-management.guard';
import { AuctionUsersGuard } from './guards/auction-users.guard';
import { BidsGuard } from './guards/bids.guard';
import { BulkEmailGuard } from './guards/bulkEmail.guard';
import { IncrementGuard } from './guards/Increment.guard';
import { MasterCatalogGuard } from './guards/master-catalog.guard';
import { ReportsGuard } from './guards/reports.guard';
import { LoggedInGuard } from './guards/logged-in.guard';

const components = [
  HeaderComponent,
  SearchControlComponent,
  FilterControlComponent,
  EditorComponent,
  ImageUploaderComponent,
  BackComponent,
  CharCounterComponent,
];

const guards = [
  BlogAuthGaurd,
  ContentAuthGuard,
  SaleTypeGuard,
  AuctionCategoryGuard,
  ManageAdminGuard,
  AuctionManagementGuard,
  AuctionUsersGuard,
  BidsGuard,
  BulkEmailGuard,
  IncrementGuard,
  MasterCatalogGuard,
  ReportsGuard,
  LoggedInGuard,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    CommonSharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    DragDropModule,
    ngfModule,
  ],
  exports: [
    ...components,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
  ],
  providers: [...guards],
})
export class AdminSharedModule {}
