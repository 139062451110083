import { NgModule } from '@angular/core';
import { ToLocalDateTime } from './pipes/to-local-datetime.pipe';
import { IsEmptyPipe } from './pipes/is-empty.pipe';
import { CharacterLimitPipe } from './pipes/character-limit.pipe';
import { FileSizeStringPipe } from './pipes/file-size-string.pipe';
import { AuctionDateFormatPipe } from './pipes/auction-date-format.pipe';
import { LocationFormatPipe } from './pipes/location-format.pipe';
import { AuctionCurrencyPipe } from './pipes/auction-currency.pipe';
import { SanitizedHtmlPipe } from './pipes/sanitized-html.pipe';
import { BulkLotsPipe } from './pipes/bulk-lots.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';

const pipes: Array<any> = [ToLocalDateTime, IsEmptyPipe, CharacterLimitPipe, FileSizeStringPipe, AuctionDateFormatPipe, LocationFormatPipe, AuctionCurrencyPipe, SanitizedHtmlPipe, BulkLotsPipe, StripHtmlPipe];

@NgModule({
  declarations: [...pipes, BulkLotsPipe],
  exports: [...pipes],
})
export class CoreModule { }
