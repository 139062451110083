import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationSettingService } from '../../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {  Subscription } from 'rxjs';

@Component({
  selector: 'app-email-setting',
  templateUrl: './email-setting.component.html',
  styleUrls: ['./email-setting.component.css'],
})
export class EmailSettingComponent implements OnInit {
  isUserSubmittedPassword: boolean = false;
  paramToken: string = '';
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  hide = true;
  private subscriptions: Array<Subscription> = [];
  notificationList = [];
  isEmailNotification: boolean = false;
  userId: number = 0;
  showHide: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private emailService: NotificationSettingService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params && params['token']) {
        let token = params['token'];
        this.paramToken = token;
        this.isUserSubmittedPassword = false;
      } else {
        this.router.navigate(['/']);
      }
    });

    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required]],
    });
  }

  f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.error = '';
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    const verifyToken = this.emailService
      .getVerifyTokenAndPreferences(this.paramToken, this.f().password.value)
      .subscribe(
        (response) => {
          this.userId = response.userId;
          this.isEmailNotification = response.isEmailNotification;
          this.showHide = response.isEmailNotification;
          this.notificationList = response.userRoleEmails;
          this.error = '';
          this.loading = false;
          this.isUserSubmittedPassword = true;
        },
        (error) => {
          this.error = error;
          this.loading = false;
        }
      );
      this.subscriptions.push(verifyToken)
  }

  saveSpecificEmailSetting(event: any, notify): void {
    let checked = event.target.checked;
    const enableDisableSpecificEmail = this.emailService
      .enableDisableSpecificEmail({
        userId: +this.userId,
        emailId: notify.emailId,
        isActive: checked,
      })
      .subscribe();
      this.subscriptions.push(enableDisableSpecificEmail)
  }

  saveEmailSetting(event: any): void {
    this.showHide = !this.showHide;
    let checked = event.target.checked;
    const enableDisableEmail = this.emailService
      .enableDisableEmail({
        userId: +this.userId,
        isEmailNotificationOn: checked,
      })
      .subscribe(() =>
        this.toastr.success(
          `All email notifications turned ${
            checked ? 'on' : 'off'
          } successfully`,
          'Success'
        )
      );
      this.subscriptions.push(enableDisableEmail)
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
