import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-back',
  template: `<a class="back-link"
            [routerLink]="[returnUrl]">
            <img alt="back-icon"
                src="../assets/images/back.svg" /> back
          </a>`
})
export class BackComponent implements OnInit {
  returnUrl: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe((res) => {
      this.returnUrl = res['returnTo'];
    })
  }

}
