import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Pagination } from '../models/paging';
import { PageData, ApiBaseResponse } from '../models';
import { Blog } from '../models/blog';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  constructor(private http: HttpClient) {}

  getBlogs(paging: Pagination): Observable<PageData<Blog[]>> {
    let param = new HttpParams();

    if (paging.onlyActive)
      param = param.append('onlyActive', '' + paging.onlyActive);

    if (paging.searchTerm != '')
      param = param.append('searchTerm', paging.searchTerm);

    if (paging.sortBy != '') param = param.append('sortBy', paging.sortBy);

    if (paging.sortOrder != '')
      param = param.append('sortOrder', paging.sortOrder);

    if (paging.isPaginated) {
      param = param.append('pageSize', '' + paging.pageSize);
      param = param.append('pageNumber', '' + paging.pageNumber);
      param = param.append('isPaginated', '' + paging.isPaginated);
    }

    return this.http
      .get<ApiBaseResponse<Blog[]>>(`${environment.apiUrl}/news`, {
        params: param,
      })
      .pipe(
        map((it) => {
          if (paging.isPaginated) {
            return {
              data: it.data,
              totalCount: it.pagingInfo.totalCount,
            };
          }
          return {
            data: it.data,
            totalCount: null,
          };
        })
      );
  }

  getBlog(id: number): Observable<Blog> {
    return this.http
      .get<ApiBaseResponse<Blog>>(`${environment.apiUrl}/news/${id}`)
      .pipe(map((it) => it.data));
  }

  addBlog(ro: FormData): Observable<Blog> {
    return this.http
      .post<ApiBaseResponse<Blog>>(`${environment.apiUrl}/news`, ro)
      .pipe(map((it) => it.data));
  }

  updateBlog(model: FormData): Observable<Blog> {
    return this.http
      .post<ApiBaseResponse<Blog>>(`${environment.apiUrl}/news/Update`, model)
      .pipe(map((it) => it.data));
  }

  deleteBlog(id: number): Observable<Blog> {
    return this.http.post<ApiBaseResponse<Blog>>(`${environment.apiUrl}/news/${id}`, {
      Id: id
    })
    .pipe(map((it) => it.data));
  }

  getBlogsForPublic(): Observable<Blog[]> {
    return this.http
      .get<ApiBaseResponse<Blog[]>>(
        `${environment.apiUrl}/news/GetnewsForPublic`
      )
      .pipe(map((it) => it.data));
  }
}
