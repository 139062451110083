<span class="no-bg-btn hover-icon" mat-icon-button [matMenuTriggerFor]="menu" #notifyMenu="matMenuTrigger"
  (onMenuOpen)="seeLatestNotifications()">
  <img alt="icon" src="../assets/images/notifications-icon.svg" />
  <span class="noti-number" *ngIf="unreadNotify">{{ unreadNotify }}</span>
</span>
<mat-menu #menu="matMenu" xPosition="before" backdropClass="public-notifications-list">
  <div class="noti-box-wrap public-noti-inner">
    <div class="noNotificationYet" *ngIf="topNotifications.length === 0">
      <img alt="icon" class="mb-2" src="../assets/images/no-notifications-icon.svg" />
      <p>No new notification yet.</p>
    </div>

    <ng-container *ngIf="topNotifications.length > 0">
      <div class="public-noti-list" [ngClass]="{ 'admin-noti-list': isAdminBar }">
        <ul>
          <ng-container *ngFor="let notify of topNotifications">
            <a *ngIf="notify.link; else withoutLink" target="_blank" [href]="notify.link">
              <li [ngClass]="{ 'unseen-notification': !notify.isRead }" role="button" (click)="markAsRead(notify)">
                <span class="icon-wrap">
                  <img alt="noti-icon" src="../assets/images/notification.svg" class="noti-icon" />
                </span>
                <span class="dtl-wrap">
                  <p class="main-dtl">
                    {{ notify.entityTitle + " : " + notify.notificationTitle }}
                  </p>
                  <p class="small-dtl">
                    {{ notify.createdOnUserTime | date: "d MMM yyyy, h:mm a" }}
                  </p>
                </span>
              </li>
            </a>
            <ng-template #withoutLink>
              <a>
                <li [ngClass]="{ unread: !notify.isRead }" role="button" (click)="markAsRead(notify)">
                  <span class="icon-wrap">
                    <img alt="noti-icon" src="../assets/images/notification.svg" class="noti-icon" />
                  </span>
                  <span class="dtl-wrap">
                    <p class="main-dtl">
                      {{
                      (notify.entityTitle | titlecase) +
                      " : " +
                      notify.notificationTitle
                      }}
                    </p>
                    <p class="small-dtl">
                      {{
                      notify.createdOnUserTime | date: "d MMM yyyy, h:mm a"
                      }}
                    </p>
                  </span>
                </li>
              </a>
            </ng-template>
          </ng-container>
        </ul>
      </div>

      <div class="public-noti-footer">
        <a class="view-all" role="button" (click)="markAllAsRead()">Mark All as Read</a>
      </div>

      <div class="public-noti-footer">
        <a class="view-all" [routerLink]="[
            routes.profile,
            routes.profileSection.allNotifications
          ]">View All</a>
      </div>

      <div class="public-noti-footer">
        <a class="view-all" [routerLink]="[routes.profile, 'setting']">Manage Preferences</a>
      </div>
    </ng-container>
  </div>
</mat-menu>
