import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '../../services';
import { WebStorageService } from '../../services/storage.service';
import constants from '../../utility/constants';

@Injectable({ providedIn: 'root' })
export class BuyerUserResolver implements Resolve<number> {
  private roles = constants.common.role;
  private readonly userTypes = constants.common.loginType;

  constructor(
    private storage: WebStorageService,
    private authService: AuthenticationService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): number {
    if (this.storage.getBuyer()) {
      this.storage.setSessionUser(this.roles.buyer);
      this.authService.startRefreshTokenTimer(this.userTypes.public);
      return this.roles.buyer;
    }
    return null;
  }
}
