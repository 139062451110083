
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiBaseResponse, CommonDropdown, PageData, LotFromBlob } from '../models';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WatchListService {

  constructor(private http: HttpClient) { }

  addToWatchList(model) {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/WatchList/AddToWatchList`, model
      )
      .pipe(map((it) => it.data));
  }

  removeFromWatchList(model) {
    return this.http
      .post<ApiBaseResponse<any>>(
        `${environment.apiUrl}/WatchList/RemoveFromWatchList`, model
      )
      .pipe(map((it) => it.data));
  }

  getAllMyWatchList(
    model: any
  ): Observable<PageData<any>> {
    let param = new HttpParams();
    if (model.isPaginated) {
      param = param.append('pageNumber', '' + model.pageNumber);
      param = param.append('pageSize', '' + model.pageSize);
      param = param.append('isPaginated', '' + model.isPaginated);
    }
    return this.http
      .get<ApiBaseResponse<any>>(
        `${environment.apiUrl}/WatchList/GetUserWatchList`,
        { params: param }
      )
      .pipe(
        map((it) => {
          return {
            data: it.data,
            totalCount: it.pagingInfo.totalCount,
          };
        })
      );
  }
}
