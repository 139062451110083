import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from './../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MustMatch } from '../../core/validators/must-match.validator';
import { Router } from '@angular/router';
import constants from '../../utility/constants';
import messages from '../../utility/messages';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  hidereset: boolean = true;
  hidecp: boolean = true;
  resetForm: FormGroup;
  loading: Boolean = false;
  private subscriptions: Array<Subscription> = [];
  //Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
  patternHign: any = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d@$!%*?&]{8,}$";

  constructor(private authenticationService: AuthenticationService,
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      password: new FormControl('', Validators.compose([Validators.required, Validators.pattern(this.patternHign)])),
      confirmPassword: new FormControl('', Validators.required),
    }, {
      validators: MustMatch('password', 'confirmPassword')
    })
  }
  onResetPassword() {
    this.loading = true;
    const resetPwd = this.authenticationService
      .resetPassword(this.modalRef.content, this.resetForm.get('password').value)
      .subscribe(
        () => {
          this.loading = false;
          this.toastr.success(messages.resetPassword.status, constants.common.toast.successTitle, constants.common.toast.config);
          this.hideAndReset();
          this.router.navigate(['']);
        },
        (err) => {
          this.toastr.error(err, constants.common.toast.errorTitle, constants.common.toast.config);
        }
      );
      this.subscriptions.push(resetPwd)
  }
  hideAndReset() {
    this.resetForm.reset();
    this.modalRef.hide();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
