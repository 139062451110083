export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      required: 'This field is required',
      email: 'Please enter a valid email address',
      invalidPassword:
        'Your password must be 8 characters or longer and should contain atleast one upper case letter, a number and no whitespace',
      invalidTitle: 'Title is already taken',
      invalidAuctionCode: 'Auction/Sale Code is already taken',
      invalidEndDate:
        'End date should be greater than and equals to start date but end time cannot be same as start time',
      minlength: 'This field should be minimum length of',
      min: 'This field should be minimum value of ',
      max: 'This field should be maximum value of ',
      maxlength: 'This field should be maximum length of',
      invalidFileType: 'Please upload a valid file with format ',
      invalidFileSize: 'Please upload a file less than or equal to',
      noWhitespace: 'Whitespaces are not allowed',
      onlyNumericCharacters: 'Only numeric characters are allowed',
      onlyNumeric: 'Only numerics are allowed',
      passwordMismatch: "Password didn't match",
      invalidWebAddress: 'Invalid web address, must starts with http:// or https://',
      invalidKeywordLength: 'keywords should be maximum of',
      invalidEmail: 'Invalid email address',
      onlyPercentage: 'Invalid percentage format',
      invalidFileExtension: 'Please upload a valid file with extension ',
      invalidlotNumber: 'Lot number must be started with a number',
      invalidBulkLotNumber: 'Bulk lot number must begin with an alphabetic character and can end with any letter or number',
      onlyAlphaNumeric: 'This field must be alphanumeric',
      invalidCardType: 'This card is not acceptable',
      notCheckTerms: 'Please check all terms and conditions',
      onlyNumericSpecialCharacters: 'Phone Number will accept numeric and special characters like.,-_() only',
    };
    return config[validatorName];
  }
}
