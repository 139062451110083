import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { SaleType } from '../models';
import { PageData } from '../models/pageData';
import { ApiBaseResponse } from '../models/ApiBaseResponse';
import { map } from 'rxjs/operators';
import { Pagination } from '../models/paging';
import { RegistrationFormat } from '../models/registrationFormat';
import { DateFormat } from '../models/dateFormat';
import { DisplaySection } from '../models/displaySection';

@Injectable({
  providedIn: 'root',
})
export class SaleTypeService {
  constructor(private http: HttpClient) {}

  getAllSaleTypes(
    paging: Pagination,
    status: boolean,
    registrationFormat: number
  ): Observable<PageData<SaleType[]>> {
    let param = new HttpParams();

    if (paging.onlyActive)
      param = param.append('onlyActive', '' + paging.onlyActive);

    if (paging.searchTerm != '')
      param = param.append('searchTerm', paging.searchTerm);

    if (paging.sortBy != '') param = param.append('sortBy', paging.sortBy);

    if (paging.sortOrder != '')
      param = param.append('sortOrder', paging.sortOrder);

    if (status != null) param = param.append('status', '' + status);

    if (registrationFormat > 0)
      param = param.append('registrationFormat', '' + registrationFormat);

    if (paging.isPaginated) {
      param = param.append('pageSize', '' + paging.pageSize);
      param = param.append('pageNumber', '' + paging.pageNumber);
      param = param.append('isPaginated', '' + paging.isPaginated);
    }

    return this.http
      .get<ApiBaseResponse<SaleType[]>>(
        `${environment.apiUrl}/saleType/GetAll`,
        {
          params: param,
        }
      )
      .pipe(
        map((it) => {
          if (paging.isPaginated) {
            return {
              data: it.data,
              totalCount: it.pagingInfo.totalCount,
            };
          }
          return {
            data: it.data,
            totalCount: null,
          };
        })
      );
  }

  addSaleType(saleType: any): Observable<SaleType> {
    return this.http
      .post<ApiBaseResponse<SaleType>>(
        `${environment.apiUrl}/SaleType/AddSaleType`,
        saleType
      )
      .pipe(map((it) => it.data));
  }

  getSaleType(id: number): Observable<SaleType> {
    return this.http
      .get<ApiBaseResponse<SaleType>>(
        `${environment.apiUrl}/saleType/GetById/${id}`
      )
      .pipe(map((it) => it.data));
  }

  updateSaleType(saleType: any): Observable<SaleType> {
    return this.http
      .post<ApiBaseResponse<SaleType>>(
        `${environment.apiUrl}/saletype`,
        saleType
      )
      .pipe(map((it) => it.data));
  }

  changeStatus(id: number): Observable<SaleType> {
    return this.http
      .post<ApiBaseResponse<SaleType>>(
        `${environment.apiUrl}/saleType/ChangeStatus`,
        {
          id: id,
        }
      )
      .pipe(map((it) => it.data));
  }

  isPrivateNameUnique(privateName: string): Observable<SaleType> {
    return this.http
      .patch<ApiBaseResponse<SaleType>>(
        `${environment.apiUrl}/saletype/IsPrivateNameUnique`,
        {
          privateName: privateName,
        }
      )
      .pipe(map((it) => it.data));
  }

  getRegistrationFormat(): Observable<RegistrationFormat[]> {
    return this.http
      .get<ApiBaseResponse<RegistrationFormat[]>>(
        `${environment.apiUrl}/saleType/GetRegistrationFormats`
      )
      .pipe(map((it) => it.data));
  }

  getDateFormat(): Observable<DateFormat[]> {
    return this.http
      .get<ApiBaseResponse<DateFormat[]>>(
        `${environment.apiUrl}/saleType/GetAuctionDates`
      )
      .pipe(map((it) => it.data));
  }

  getDisplaySection(): Observable<DisplaySection[]> {
    return this.http
      .get<ApiBaseResponse<DisplaySection[]>>(
        `${environment.apiUrl}/SaleType/GetDisplaySection`
      )
      .pipe(map((it) => it.data));
  }

  getSaletypeForDropDown(): Observable<DisplaySection[]> {
    return this.http
      .get<ApiBaseResponse<DisplaySection[]>>(
        `${environment.apiUrl}/SaleType/GetActiveDropDownList`
      )
      .pipe(map((it) => it.data));
  }

  getSaletypeForDropDownPublic(
    auctionStatus: number,
    displaySection: number[]
  ): Observable<DisplaySection[]> {
    let param = new HttpParams();
    if (auctionStatus)
      param = param.append('auctionStatus', auctionStatus.toString());
    if (displaySection && displaySection.length > 0)
      displaySection.forEach((section) => {
        param = param.append('displaySection', section.toString());
      });

    return this.http
      .get<ApiBaseResponse<DisplaySection[]>>(
        `${environment.apiUrl}/SaleType/GetActivePublicDropdownList`,
        {
          params: param,
        }
      )
      .pipe(map((it) => it.data));
  }

  getRegistrationAndDepositeRules(): Observable<string> {
    return this.http
      .get<ApiBaseResponse<string>>(
        `${environment.apiUrl}/SaleType/GetRegistrationAndDepositeRules`
      )
      .pipe(map((it) => it.data));
  }
}
