<div class="form-group" *ngIf="!isForMobile">
  <div
    class="custom-search-with-icon filter-with-clear"
    class="{{ classes ? classes : '' }}"
  >
    <input
      class="form-control"
      [placeholder]="placeholderText"
      [formControl]="searchControl"
      [maxlength]="searchMaxLength"
    />
    <span
      class="filter-icon"
      role="button"
      (click)="resetSearch()"
      *ngIf="searchControl.value != '' && searchControl.value != null"
    >
      <img
        src="../assets/images/cross.svg"
        title="filter-search-icon"
        alt="filter-search-icon"
      />
    </span>
  </div>
</div>

<div class="form-group m-0" *ngIf="isForMobile">
  <div class="custom-search-with-icon mob-search-input">
    <input
      class="form-control"
      [placeholder]="placeholderText"
      [formControl]="searchControl"
      [maxlength]="searchMaxLength"
    />
    <div
      class="for-open-search"
      role="button"
      (click)="toggleButton(true)"
    ></div>
    <div class="for-input-close" role="button" (click)="resetSearch()">
      <span class="icon" *ngIf="searchControl.value">
        <img
          src="../assets/images/cross.svg"
          title="mobile-search-icon"
          alt="mobile-search-icon"
        />
      </span>
      <span class="text" role="button" (click)="toggleButton(false)"
        >Cancel</span
      >
    </div>
  </div>
</div>
