import { ApiBaseResponse } from './../models/apiBaseResponse';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ContactUs } from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private http: HttpClient) { }

  addContactUs(contactUs: ContactUs): Observable<string> {
    return this.http
      .post<ApiBaseResponse<string>>(`${environment.apiUrl}/ContactUs`, contactUs)
      .pipe(map((it) => it.data));
  }
}
