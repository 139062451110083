<div class="modal-header">
  <h4 class="modal-title pull-left">
    Reset Password
    <span class="sb-title">Enter new password for your account</span>
  </h4>
  <button
    type="button"
    class="close pull-right circle-icon circle-icon2"
    aria-label="Close"
    role="button"
    (click)="hideAndReset()"
  >
    <img src="../assets/images/cross.svg" />
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="resetForm">
    <div class="form-group mb-4 no-float-fields">
      <label class="form-label">New Password *</label>
      <span class="for-pass-show">
        <mat-form-field appearance="outline">
          <input
            matInput
            [type]="hidereset ? 'password' : 'text'"
            placeholder="Enter New Password"
            formControlName="password"
          />
          <mat-icon
            class="cursor-pointer"
            matSuffix
            role="button"
            (click)="hidereset = !hidereset"
          >
            {{ hidereset ? "visibility_off" : "visibility" }}</mat-icon
          >
        </mat-form-field>
        <p
          class="pass-note m-0 text-danger"
          *ngIf="
            resetForm.get('password').hasError('required') &&
            (resetForm.get('password').dirty ||
              resetForm.get('password').touched)
          "
        >
          Password is required
        </p>
        <p
          class="pass-note m-0 text-danger"
          *ngIf="
            resetForm.get('password').hasError('pattern') &&
            (resetForm.get('password').dirty ||
              resetForm.get('password').touched)
          "
        >
          Your password can be eight characters or more and should contain
          atleast one capital letter and a number.
        </p>
      </span>
    </div>
    <div class="form-group mb-4 no-float-fields">
      <label class="form-label">Confirm Password *</label>
      <span class="for-pass-show">
        <mat-form-field appearance="outline">
          <input
            matInput
            [type]="hidecp ? 'password' : 'text'"
            placeholder="Enter New Password"
            formControlName="confirmPassword"
          />
          <mat-icon
            class="cursor-pointer"
            matSuffix
            role="button"
            (click)="hidecp = !hidecp"
          >
            {{ hidecp ? "visibility_off" : "visibility" }}</mat-icon
          >
        </mat-form-field>
        <p
          class="pass-note m-0 text-danger"
          *ngIf="
            resetForm.get('confirmPassword').hasError('required') &&
            (resetForm.get('confirmPassword').dirty ||
              resetForm.get('confirmPassword').touched)
          "
        >
          Confirm Password is required
        </p>
        <p
          class="pass-note m-0 text-danger"
          *ngIf="
            resetForm.get('confirmPassword').hasError('mustMatch') &&
            (resetForm.get('confirmPassword').dirty ||
              resetForm.get('confirmPassword').touched)
          "
        >
          Confirm Password must match Password
        </p>
      </span>
    </div>
    <button
      type="button"
      class="btn btn-colored btn-login m-0"
      [disabled]="loading || resetForm.invalid"
      role="button"
      (click)="onResetPassword()"
      [disabled]="resetForm.invalid"
    >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      Reset My Password
    </button>
  </form>
</div>
