import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService, WebStorageService } from '../services/index';
import { AuthResponse } from '../models';
import constants from '../utility/constants';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private readonly userTypes = constants.common.loginType;

  constructor(
    private authenticationService: AuthenticationService,
    private xsrfTokenExtractor: HttpXsrfTokenExtractor,
    private storage: WebStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let currentUser: AuthResponse;

    if (this.storage.getSessionUser() == this.userTypes.admin) {
      currentUser = this.authenticationService.currentUserValue();
    } else {
      currentUser = this.authenticationService.currentBuyerValue();
    }

    const isLoggedIn = currentUser && currentUser?.accessToken;

    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser?.accessToken}`,
        },
      });
    }

    return next.handle(request);
  }
}
